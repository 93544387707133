@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600&display=swap");

html,
body {
    padding: 0;
    margin: 0;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

a {
    color: #26aebc;
    text-decoration: none;
}

a:hover {
    color: #1d8a96;
    text-decoration: none;
}

.p-menuitem > ul.p-submenu-list {
    right: 16px;
}

.p-menuitem-link {
    font-size: 14px;
}

.text-lg {
    color: #404040;
}

img {
    font-size: 14px;
}

@media (max-width: 768px) {
    .flex.align-items-center.justify-content-center.col-6.h-screen.login-right-half {
        display: none !important;
    }

    .login-form-container {
        width: 100% !important;
        height: 60% !important;
        position: absolute;
        bottom: 0;
        border-radius: 24px 24px 0 0;
        -webkit-box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.05);
                box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.05);
    }

    .login-mobile-graphic {
        height: 40%;
        width: 100%;
        padding: 16px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .login-mobile-graphic img {
        height: 100%;
    }
}

@media (min-width: 769px) {
    .login-form-container {
        height: 100vh;
        width: calc(50% + 24px) !important;
        border-radius: 0 64px 64px 0;
        -webkit-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1);
    }

    .login-mobile-graphic {
        display: none;
    }
}

.login-form-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px;
    background: #ffffff;
}

.login-logo {
    width: 343px;
    text-align: center;
    margin: 0 0 40px 0;
}

.login-heading {
    width: 343px;
    font-family: Inter, serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: center;
    color: #404040;
    margin-bottom: 4px;
}

.login-subheading {
    width: 343px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: #404040;
    margin: 0 0 40px 0;
}

.login-email {
    border-radius: 24px !important;
    height: 40px;
}

.google-btn {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.sign-in-with-google {
    width: 343px;
    object-fit: contain;
}

.login-blue-background {
    background: #e3efee !important;
    height: 100vh;
}

.login-right-half {
    padding: 16px;
}

.login-right-half img {
    width: 85%;
    max-width: 500px;
}

.text-xl,
.text-base,
.intake-form-response,
.patient-identity h5 {
    color: #404040;
}

.consult-information-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.consult-information-header .mr-2,
.p-card .mr-2 {
    margin-right: 1rem !important;
}

.consult-information-header .divider {
    padding: 0 16px 0 16px;
    color: #a1a1a1;
}

.consult-btn-complete {
    margin-left: 8px !important;
}
.consult-btn-complete.disabled {
    opacity: 0.6;
    cursor: pointer;
}

.manual-prescription-modal,
.pharmacy-modal {
    width: 50vw;
    min-width: 327px;
    max-width: 800px;
    display: inline-block !important;
    position: fixed !important;
    right: 30px;
    -webkit-box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
            box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #404040;
    background: white;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
    background: #e9f7f8;
}

.prescription-dropdown, .invite-patient-dropdown {
    background-color: #26aebc !important;
    border: 1px solid #26aebc !important;
}

.prescription-dropdown .p-dropdown-trigger,
.prescription-dropdown .p-placeholder, .invite-patient-dropdown:not(.p-disabled) .p-dropdown-trigger, .invite-patient-dropdown .p-placeholder {
    color: #ffffff !important;
}

.prescription-dropdown:hover, .invite-patient-dropdown:hover {
    border: 1px solid #1d8a96 !important;
    background-color: #1d8a96 !important;
}

.prescription-dropdown:disabled, .invite-patient-dropdown:disabled {
    border: 1px solid #8ecbd6 !important;
    background-color: #cdcdcd !important;
}

.p-dropdown.p-disabled, .patient-reported-button.p-disabled {
    background: #cdcdcd !important;
    border: 1px solid #cdcdcd !important;
}

.invite-patient-dropdown.p-disabled .p-dropdown-trigger {
    display: none;
}

.edit-prescription-button,
.edit-pharmacy-button,
.delete-prescription-button {
    padding: 0.75rem !important;
}

.edit-pharmacy-button {
    height: 50px !important;
}

.p-dialog-draggable .p-dialog-header:hover {
    cursor: -webkit-grab;
    cursor: grab;
}

.p-dialog-draggable .p-dialog-header:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.manual-prescription-modal .field {
    padding: 10px;
}

.manual-prescription-modal label,
.pharmacy-modal label, .add-observation-modal label, .add-allergy-modal label, .add-medication-modal label {
    font-size: 14px;
}

.manual-prescription-modal .p-calendar .p-component {
    font-size: 14px !important;
}

@media (max-width: 1289px) {
    .dosespot-trashcan {
        display: none !important;
    }
}

@media (min-width: 1290px) {
    .dosespot-trashcan {
        visibility: hidden;
    }
}

@media (max-width: 1164px) {
    .manual-prescription-modal .field {
        width: 100%;
        max-width: none !important;
    }
    .manual-prescription-modal .field.col-3 {
        min-width: 100% !important;
    }
}

@media (min-width: 1165px) {
    .manual-prescription-modal .field.col-3 {
        width: 25%;
        max-width: 25%;
    }
}

.HeaderBar__Container-sc-1y91751-0 .consult-btn-complete {
    min-width: 174px;
    font-size: 14px;
}
.HeaderBar__Container-sc-1y91751-0 .consult-btn-complete svg {
    min-width: 10px;
}

.p-menuitem-link {
    font-size: 14px;
}

.intake-form-response p {
    margin-bottom: 0.5em;
}

.intake-form-response p.font-semibold {
    margin-top: 0;
}

.intake-form-response {
    margin-top: 2rem !important;
    margin-bottom: 0 !important;
}

.intake-form-response .answer {
    margin-top: 0;
}

.intake-form {
    margin-bottom: 2rem !important;
}

.intake-form .intake-form-response:first-child {
    margin-top: 0 !important;
}

.intake-form .intake-form-response:last-child,
.intake-form .intake-form-response .field-checkbox:last-child {
    margin-bottom: 0 !important;
}

.identity-title {
    color: #969696;
    margin-bottom: 0.25rem;
}

.identity-item {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc((100% - 2 * 16px) / 3);
            flex-basis: calc((100% - 2 * 16px) / 3);
    margin-right: 4px;
}

.patient-identity .p-accordion-content {
    padding-bottom: 0.5rem !important;
}

.patient-header-info {
    font-size: 14px;
}

/* CONSULT QUEUE PAGE LAYOUT ADJUSTMENTS START */

.return-to-queue-toast-button {
    background-color: #FFFFFF !important;
    color: #F44949 !important;
    margin-top: 8px;
    border: 1px solid #F44949 !important;
}

.return-to-queue-toast-button:hover {
    background-color: #EFEFEF !important;
    color: #F44949 !important;
}

.p-tooltip-text {
    font-size: 14px;
}

.clinician-headshot {
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    outline: 1px solid #e1e1e1;
}

.clinician-headshot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.clinician-headshot:hover {
    cursor: pointer;
}

.priority-consult-badge {
    background: #F44949 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 32px !important;
    width: 68px;
    line-height: 2rem !important;
    border-radius: 40px;
    margin: -16px 8px -16px 0;
}

.consult-queue-elements .p-dropdown .p-dropdown-label, .consult-queue-elements .p-multiselect .p-multiselect-label, .p-multiselect-panel .p-multiselect-item, .p-multiselect-panel .p-multiselect-filter, .p-multiselect-panel .p-multiselect-empty-message, .single-queue-elements .p-dropdown .p-dropdown-label, .single-queue-elements .p-multiselect .p-multiselect-label {
    font-size: 14px;
    padding-right:0;
    color: #404040;
}

.consult-queue-elements .p-dropdown, .consult-queue-elements .p-multiselect, .single-queue-elements .p-dropdown, .single-queue-elements .p-multiselect {
    height: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.clear-queue-filter-button {
    color: #26aebc;
    cursor: pointer;
}

.clear-queue-filter-button:hover {
    color: #1d8a96;
}

.visit-reasons-filter .p-placeholder, .visit-reasons-filter .p-multiselect-trigger, .programs-filter .p-placeholder, .programs-filter .p-multiselect-trigger, .partners-filter .p-placeholder, .partners-filter .p-multiselect-trigger {
    color: #404040 !important;
}

.async-consults-switch, .side-effects-switch {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 8px;
}

.filter-notification-text p, .async-consults-switch p, .side-effects-switch p {
    color: #404040 !important;
    margin: 0;
}

.filter-notification {
    margin-top: 8px;
}

.single-queue-elements .consult-card-button .p-disabled {
    background: #cdcdcd;
    border: 1px solid #cdcdcd;
}

.single-queue-elements .partners-filter, .single-queue-elements .programs-filter, .single-queue-elements .visit-reasons-filter, .single-queue-elements .side-effects-switch {
    display: none !important;
}

.async-consults-countdown-timer {
    color: #888888;
    font-size: 14px;
}

/* Mobile Start */
.p-menubar {
    border-radius: 0 0 4px 4px !important;
    width: 100vw !important;
}

.consult-queue-navigation,
.consult-queue-schedule,
.single-queue-navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.consult-queue-elements .col-3 {
    width: 100% !important;
    padding-top: 84px;
}

.consult-queue-elements .col-12,
.single-queue-elements .col-12 {
    width: 100% !important;
}

.single-queue-cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.queue-cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.queue-cards .patient,
.single-queue-cards .patient {
    width: 20%;
}

.queue-cards .consult-card-button,
.single-queue-cards .consult-card-button {
    width: 20%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.queue-cards .patient .patient-name,
.single-queue-cards .patient .patient-name {
    margin-top: 0;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.queue-cards .patient .patient-info,
.single-queue-cards .patient .patient-info {
    margin-top: 0;
    margin-bottom: 0;
}

.single-queue-top-section {
    height: 300px;
}

.consult-queue-filters, .single-queue-filters {
    -webkit-order: 1;
            order: 1;
    padding-top: 0 !important;
}

.queue-cards, .single-queue-cards {
    -webkit-order: 2;
            order: 2;
}

.consult-queue-filters, .single-queue-filters {
    display: -webkit-flex;
    display: flex;
    grid-gap: 8px !important;
    gap: 8px !important;
}

.single-queue-cards .consult-card:first-child {
    margin-bottom: 100px !important;
}

.single-queue-cards .consult-card:first-child:has(.ma-task-indicator) {
    margin-top: -16px;
}

.consult-card {
    width: 100%;
    max-width: 728px;
    margin-bottom: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ma-task-indicator {
    background: #E9F7F8;
    border-radius: 6px 6px 0 0;
    border-top: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-left: 1px solid #E1E1E1;
    padding: 8px;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.no-mas-available {
    background: #F8E9E9;
}

.ma-task-text {
    margin: 0;
    text-align: center;
    color: #404040;
}

@media only screen and (max-width: 767px) {
    .single-queue-cards .p-card {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

    .single-queue-top-section {
        height: 300px !important;
        margin-left: -16px;
        margin-right: -16px;
    }

    .single-queue-elements {
        padding-top: 24px;
        margin-top: -235px !important;
    }

    .consult-queue-filters, .single-queue-filters {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .single-queue-elements .col-6 {
        width: 100%;
    }
    
    .single-queue-schedule, .single-queue-filters {
        display: none;
    }

    .filtered-programs-list, .filtered-reasons-list, .filtered-partners-list {
        display: none;
    }

    .filter-notification-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 8px;
        gap: 8px;
    }
}

/* Mobile End */

/* Tablet Start */
@media only screen and (min-width: 768px) {
    .consult-queue-navigation,
    .single-queue-schedule,
    .single-queue-navigation {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .single-queue-top-section {
        margin-left: -24px;
        margin-right: -24px;
    }

    .consult-queue-elements .col-3 {
        width: 100% !important;
        padding-top: 84px;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .single-queue-elements .col-3 {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .queue-cards,
    .single-queue-cards {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .consult-queue-filters, .single-queue-filters {
        -webkit-order: 1;
                order: 1;
        padding-top: 0 !important;
    }

    .queue-cards, .single-queue-cards {
        -webkit-order: 2;
                order: 2;
    }

    .queue-cards .p-card,
    .single-queue-cards .p-card {
        width: 100%;
        max-width: 728px;
    }

    .single-queue-elements {
        padding-top: 24px;
        margin-top: -235px !important;
    }

    .single-queue-elements .col-6 {
        width: 100%;
    }

    .single-queue-schedule, .single-queue-filters {
        display: none;
    }

    .filtered-programs-list, .filtered-reasons-list, .filtered-partners-list {
        display: none;
    }

    .filter-notification-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 8px;
        gap: 8px;
    }
}
/* Tablet End */

/* Desktop Start */
@media only screen and (min-width: 992px) {
    .consult-queue-navigation,
    .consult-queue-filters, .single-queue-filters {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        grid-gap: 0;
        gap: 0;
        max-width: 328px;
    }

    .single-queue-schedule {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        grid-gap: 0;
        gap: 0;
    }

    .consult-queue-elements {
        padding-top: 120px;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .single-queue-elements {
        padding-top: 120px;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .single-queue-top-section {
        margin-left: -32px;
        margin-right: -32px;
    }

    .consult-queue-elements .col-3 {
        width: 25% !important;
        padding-top: 8px !important;
    }

    .consult-queue-elements .col-12, .single-queue-elements .col-6 {
        width: 50% !important;
    }

    .single-queue-elements .col-3 {
        max-width: 400px !important;
    }

    .queue-cards,
    .single-queue-cards {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .single-queue-elements {
        padding-top: 24px;
        margin-top: -235px !important;
    }

    .consult-queue-filters {
        -webkit-order: 0 !important;
                order: 0 !important;
        padding-top: 0 !important;
    }

    .queue-cards, .single-queue-cards {
        -webkit-order: 0 !important;
                order: 0 !important;
    }

    .filtered-programs-list, .filtered-reasons-list, .filtered-partners-list {
        display: block;
        max-width: 259px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 24px;
        color: #404040;
    }

    .filtered-item {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        max-width: 100%;
    }

    .filtered-label {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .filtered-icon {
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        margin-right: 8px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        width: 18px;
        color: #404040;
        cursor: pointer;
    }

    .filtered-icon:hover {
        color: #f44949;
    }

    .filter-notification-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        grid-gap: 4px;
        gap: 4px;
    }

    .filter-notification-text p {
        margin: 0;
    }
}

@media only screen and (min-width: 1440px) {
    .single-queue-top-section {
        margin-left: -48px;
        margin-right: -48px;
    }
}

/* Desktop End */

.todays-schedule {
    background: #ffffff;
    min-height: 80px;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    padding: 16px;
    color: #404040;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* CONSULT QUEUE PAGE LAYOUT ADJUSTMENTS END */

.inline-pharmacy-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pharmacy-modal {
    max-width: 800px;
}

.pharmacy-modal .p-dropdown-label.p-inputtext, .add-observation-modal .p-dropdown-label.p-inputtext {
    font-size: 14px;
}

.pharmacy-modal .p-dialog-content,
.manual-prescription-modal .p-dialog-content {
    padding-bottom: 0.5rem !important;
}

.pharmacy-modal .p-dialog-header .p-dialog-title,
.manual-prescription-modal .p-dialog-header .p-dialog-title {
    font-size: 16px !important;
}

/* CONSULT DETAIL LAYOUT START */
@media only screen and (max-width: 1151px) {
    .consult-detail-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .left-hand-column,
    .right-hand-column {
        width: 100%;
    }

    .right-hand-column {
        margin-left: 0 !important;
    }
}

@media only screen and (min-width: 1152px) {
    .consult-detail-container {
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }
}

/* CONSULT DETAIL LAYOUT END */

.inline-prescription-table .p-datatable-wrapper {
    overflow: visible !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px !important;
}

.p-dialog .p-dialog-header {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

/* MESSAGE MODAL START */
.messages .p-accordion-content,
.communication-panel .p-accordion-content {
    padding: 0 !important;
}

.message-markdown p {
    margin-top: 0;
}

.patient-connect-dock,
.patient-message-dock {
    border: 1px solid #e1e1e1;
    border-radius: 0 20px 20px 0;
    padding: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    grid-gap: 8px;
    gap: 8px;
    width: 48px;
    background: #e9f7f8;
    position: fixed;
    left: 0;
}

.patient-connect-dock {
    top: 160px;
}

.patient-message-dock {
    top: 100px;
}

.call-buttons-container {
    grid-gap: 8px;
    gap: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.communication-panel {
    position: fixed !important;
    top: 100px;
    z-index: 1;
    -webkit-box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
            box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
    border: 1px solid #e1e1e1 !important;
}

.communication-panel .p-dialog {
    border-radius: 24px !important;
    border: 1px solid #e1e1e1 !important;
}

.communication-panel .p-dialog-content {
    padding: 0 !important;
    border-radius: 0 0 4px 4px;
}

.communication-panel .p-accordion-content {
    -webkit-justify-content: center;
            justify-content: center;
    border-top: 1px solid #e1e1e1;
}

.communication-panel .phone-call-participants {
    padding: 8px 24px 8px 24px;
    width: 100%;
}

.communication-panel .phone-component.patientpanel.active {
    width: 100%
}

.communication-panel:has(.phone-component.active) .p-resizable-handle, .communication-panel:has(.video-component.active .patient-connect-empty-state) > .p-resizable-handle {
    display: none !important;
}

.communication-panel .phone-call-participants span {
    display: inline-block;
    height: 48px;
}

.communication-panel .phone-component .room-container, .communication-panel .phone-component.patientpanel.active .blur-button {
    display: none !important;
}

.communication-panel .phone-component.patientpanel.active .video-visit, .communication-panel .phone-component.patientpanel.active .video-call-background {
    height: auto !important;
}

.communication-panel .phone-component .footer-container {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.communication-panel .spinner-gif-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.communication-panel .p-accordion-header-text {
    width: 100% !important;
}

.communication-panel .patient-connect-buttons {
    display: -webkit-flex;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.communication-panel .p-accordion-toggle-icon {
    margin-left: 1.5rem !important;
}

.communication-panel .p-dialog-header {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.communication-panel .consult-btn-external-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 24px;
}

.send-message-button {
    padding: 10px 16px !important;
    min-width: 54px;
}

.send-message-button:disabled {
    background-color: #e6e6e6 !important;
    border: 1px solid #e6e6e6 !important;
    opacity: 1 !important;
}

.communication-toggle-button:focus,
.communication-toggle-button:active,
.p-togglebutton.p-button.p-highlight {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    border: none !important;
}

.communication-toggle-button:not(.p-disabled):not(.p-highlight):hover {
    background: #cdcdcd !important;
}

.patient-connect-empty-state {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 0 8px 0;
}

.patient-connect-empty-state p {
    text-align: center;
}

.patient-connect-empty-state .text-sm {
    margin-top: 0;
}

/* MESSAGE MODAL END */

/* VIDEO CALL MODAL START */

.video-component .patientpanel .p-panel-content {
    padding: 0 !important;
}

.video-component .patientpanel .p-panel-content p {
    margin-top: 0;
    padding-top: 16px !important;
    padding-left: 20px;
}

.video-component .patientpanel .consult-btn-complete {
    margin: 0.25rem 1.25rem 1.25rem 1.25rem;
}

.communication-panel .call-timer, .communication-panel .patient-timer {
    font-size: 14px;
    min-width: 44px;
    color: #ffffff;
    text-align: right;
}

.communication-panel .mic-and-cam-buttons {
    margin-right: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.communication-panel .end-call-button-x {
    background-color: #f44949;
    border: none;
    border-radius: 24px;
    width: 3rem;
    height: 3rem;
    padding: 0 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 16px;
}

.communication-panel .end-call-button-x:hover {
    background-color: #cd2020 !important;
}

.communication-panel .mic-button,
.communication-panel .cam-button,
.communication-panel .blur-button,
.communication-panel .end-call-button {
    border-radius: 24px;
}

.communication-panel .timer-end-call-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.communication-panel .end-call-button,
.communication-panel .consult-btn-complete {
    height: 48px;
    margin-left: 16px;
    margin-right: 0 !important;
}

.communication-panel .room-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.communication-panel .video-visit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #373630;
    height: 100%;
    width: 100%;
}

.communication-panel .video-call-background {
    height: 100%;
    width: 100%;
}

.communication-panel .footer-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #373630;
    margin: 1rem;
}

.communication-panel .local-participant {
    height: 25%;
    -webkit-align-self: center;
            align-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.communication-panel .local-participant:has(.camera-off), .communication-panel .main-participant:has(.camera-off) {
    height: 100% !important;
    width: 100% !important;
}

.communication-panel .main-participant {
    height: 75%;
}

.communication-panel .camera-off {
    text-wrap: balance;
    text-align: center;
    border-bottom: 1px solid gray;
}

.p-dialog-content:has(.video-component) {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.communication-panel .video-component.patientpanel.active, .communication-panel .video-component .main-participant video {
    width: 100%;
}

.communication-panel .footer-div {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 !important;
}

.communication-panel .footer-div .all-items {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.communication-panel .invite-patient-container {
    margin-top: 16px;
    margin-bottom: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.communication-panel .p-resizable-handle {
    background-image: url(/_next/static/media/resize-handle.b4d09dc5.svg) !important;
}

.communication-panel:has(.video-component) .p-resizable-handle {
    background-image: url(/_next/static/media/resize-handle-white.33bf1521.svg) !important;
}

.p-dialog .p-resizable-handle {
    background-image: url(/_next/static/media/resize-handle.b4d09dc5.svg);
    background-size: contain;
    margin: 2px;
}

/* VIDEO CALL MODAL END */

/* PAST CONSULTS DIAGNOSIS START */
.readonly-diagnosis-text {
    margin-top: 0 !important;
    font-size: 14px;
}

.readonly-diagnosis-text-container-col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
    width: 33.3333%;
    min-height: 40px !important;
    max-height: 400px;
    overflow-y: auto;
}

.readonly-diagnosis-text-container-col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
    width: 25%;
    height: 40px !important;
}

.readonly-diagnosis-text-container-col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
    width: 8.3333%;
    height: 40px !important;
}
/* PAST CONSULTS DIAGNOSIS END */

/* PAST CONSULTS TOAST START */
.info-toast {
    border-left: 6px solid #26aebc !important;
    color: #ffffff !important;
    background: #26aebc !important;
}
.comments-toast {
    border-left: 6px solid #f7931e !important;
    color: #ffffff !important;
    background: #f7931e !important;
}

.p-toast:has(.info-toast), .p-toast:has(.return-to-queue-toast) {
    z-index: 100000 !important;
}

.return-to-queue-toast .p-toast-icon-close {
    display: none;
}

.info-toast .p-toast-message-icon.pi.pi-info-circle,
.info-toast .p-toast-icon-close,
.error-toast .p-toast-message-icon.pi.pi-info-circle,
.error-toast .p-toast-icon-close,
.error-toast .pi-times {
    color: #ffffff !important;
}

.p-toast-message-text {
    font-size: 14px;
    margin: 0 !important;
}

.error-toast {
    border-left: 6px solid #f44949 !important;
    color: #ffffff !important;
    background: #f44949 !important;
}

.error-toast .p-toast-message-icon.pi.pi-times, .p-icon.p-toast-message-icon {
    display: none !important;
}

.comments-toast .p-toast-icon-close {
    color: #ffffff !important;
}
/* PAST CONSULTS TOAST END */

.add-diagnosis-button, .add-gender-button {
    color: var(--primary-color) !important;
}

.add-diagnosis-button:hover, .add-gender-button:hover {
    color: #1d8a96 !important;
    cursor: pointer !important;
}

.cancel-add-gender-button {
    color: #f44949;
    cursor: pointer;
}

.cancel-add-gender-button:hover {
    color: #A91E1E;
}

.disabled-diagnosis-button {
    color: #969696 !important;
}

/* HEALTH GORILLA / DOSESPOT / LAB RESULTS OBSERVATION MODALS START */
.healthgorilla-modal .p-dialog-content {
    padding: 0 !important;
    border-bottom: 1px solid rgba(64, 64, 64, 0.2) !important;
    overflow-y: visible !important;
}

.dosespot-modal .p-dialog-content {
    padding: 0 !important;
    overflow-y: visible !important;
}

.p-dialog-maximized #healthgorilla,
.p-dialog-maximized #dosespot {
    height: 100% !important;
}

.lab-modal-link:hover {
    color: #1d8a96 !important;
}

.lab-results-modal .p-dialog-header {
    padding: 1.5rem 1rem !important;
}

.lab-results-modal .p-dialog-content {
    padding: 0 !important;
    border-bottom: 1px solid rgba(64, 64, 64, 0.2) !important;
}

.lab-results-modal .lab-results-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 16px;
}

.lab-results-modal .lab-results-chart .lab-results-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 8px;
    margin-bottom: 16px;
}

.lab-results-modal .lab-results-chart .lab-results-dates {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 24px;
}

.lab-results-modal .observation-titles {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.lab-results-modal .observation-titles .text-sm {
    margin-bottom: -4px;
}

.lab-results-modal .lab-results-chart:last-child {
    padding-bottom: 24px !important;
}

.lab-results-modal .lab-results-chart:not(:last-child) {
    margin-bottom: 24px;
}

.lab-results-modal .lab-results-conclusion {
    padding-bottom: 0;
}

.lab-results-modal .lab-results-info:not(.places-of-service):not(.patient-info) {
    margin-bottom: 24px;
}

.lab-results-modal .lab-results-conclusion p,
.lab-results-modal .info-text p {
    margin-top: 0 !important;
}

.lab-results-modal .lab-results-info .info-text,
.lab-results-modal .lab-results-chart .lab-results-dates .info-text,
.lab-results-modal .lab-results-chart .lab-results-row p {
    margin-top: 0;
    margin-bottom: 0;
}

.lab-results-modal h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 18px;
}

.lab-results-modal .p-resizable-handle {
    cursor: s-resize;
    width: 100%;
}

.lab-results-modal .identity-title {
    text-transform: uppercase;
    margin-top: 0;
}

.lab-results-modal .interpretation-text strong {
    font-weight: 600;
}

.lab-result-file-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 16px;
}

.lab-result-file {
    color: var(--primary-color);
    cursor: pointer;
    width: 124px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.lab-result-file:hover, .final-lab-results-link:hover {
    color: #1D8A96;
}

.final-lab-results-link-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
}

.final-lab-results-link {
    color: var(--primary-color);
    cursor: pointer;
}

/* HEALTH GORILLA / DOSESPOT / LAB RESULTS OBSERVATION MODALS END */

/* LAB TESTS START */

.lab-test-accordion.p-accordion-header {
    border-radius: 6px !important;
    border: 1px solid #e6e6e6;
}

.lab-test-accordion.p-accordion-header.p-highlight {
    border-radius: 6px 6px 0 0 !important;
}

.lab-test-accordion.p-toggleable-content {
    border: 1px solid #e6e6e6;
    border-top: none !important;
    border-radius: 0 0 6px 6px !important;
}

.lab-test-accordion.p-toggleable-content .p-accordion-content {
    padding-bottom: 0 !important;
    max-height: 286px;
    overflow-y: auto;
}

.lab-test-accordion.p-accordion-header {
    padding-left: 16px !important;
    background-color: #f9f9f9;
}

.lab-test-accordion.p-accordion-header a {
    padding: 4px 16px 4px 0 !important;
}

/* LAB TESTS END */

.image-gallery {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-row-gap: 16px;
    row-gap: 16px;
}

.image-gallery .image-container {
    max-width: 133px;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.image-gallery .image-container:not(:last-child) {
    margin-right: 16px;
}

.image-gallery .p-image-preview-indicator {
    height: 100px !important;
}

.p-image-toolbar {
    z-index: 1;
}

.document-button a {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center !important;
            align-items: center !important;
}

.document-button span {
    max-height: 20px;
}

/* MULTIPLE ENCOUNTERS START */
.new-encounter .p-accordion-header {
    border-radius: 6px 6px 0 0;
}

.new-encounter .p-accordion-header-text .text-sm.font-normal,
.encounter-accordion .text-sm {
    margin: 0;
}

.new-encounter .p-accordion-content {
    padding-top: 0 !important;
}

.new-encounter .p-toggleable-content .assessment .text-base:first-child {
    margin-top: 0.5rem !important;
}

.new-encounter {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    overflow-y: auto;
    max-height: calc(100vh - 120px);
}

.cancel-encounter-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.cancel-encounter-button {
    color: #f44949 !important;
    font-size: 14px !important;
}

.cancel-encounter-button:hover,
.cancel-encounter-button:active {
    background-color: #f78080 !important;
    color: #ffffff !important;
    border: 1px solid #f44949 !important;
}

.cancel-encounter-button:disabled {
    color: #969696 !important;
    border: 1px solid #969696 !important;
    opacity: 1 !important;
}

.new-intake-badge {
    background: #409bcd !important;
    font-size: 16px !important;
    height: 32px !important;
    width: 63px;
    line-height: 2rem !important;
    border-radius: 40px;
}

.new-comment-badge {
    background: #f7931e !important;
    font-size: 14px !important;
    width: 75px;
    border-radius: 40px;
}

/* MULTIPLE ENCOUNTERS END */

/* TOGGLEABLE CONSULT DETAIL START */
.p-accordion .p-accordion-content {
    border: none !important;
    border-radius: 0 0 24px 24px !important;
    color: #404040 !important;
}

.p-accordion:not(.consult-instructions):not(.communication-panel) .p-accordion-content {
    display: -webkit-flex;
    display: flex;
}

.p-accordion .p-accordion-header-link {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
    padding-left: 0 !important;
}

.p-accordion .p-accordion-header-text {
    font-weight: 600;
    width: 85%;
}

.p-accordion .p-accordion-header-text .identity-title {
    font-weight: 400 !important;
}

.p-accordion-header .p-accordion-toggle-icon {
    margin: 8px 0 8px auto;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-header .p-accordion-header-link {
    background: transparent !important;
    border: 0 !important;
    color: #404040 !important;
    border-radius: 0 !important;
}

.consult-instructions .p-accordion-content, .tinkerbell-ai-summary .p-accordion-content {
    padding-top: 0 !important;
}

.consult-instructions .react-markdown ol {
    padding-left: 16px;
}

.treatment-activity .p-accordion .p-accordion-header .p-accordion-header-link {
    border: 0 !important;
}

.treatment-activity .p-accordion .p-accordion-tab-active .p-accordion-header a {
    border-bottom: 0 !important;
}

.treatment-activity .p-accordion-content {
    border-radius: 0 0 24px 24px !important;
    padding-left: 24px !important;
}

.treatment-activity .p-toggleable-content-enter-done {
    border-radius: 24px;
}

.treatment-activity .p-toggleable-content {
    background: none !important;
}

.treatment-activity .p-accordion .p-accordion-header-text {
    width: 100%;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.p-accordion .p-accordion-header:hover,
.p-accordion .p-accordion-header a:hover {
    cursor: pointer;
}

.intake-accordion,
.medops-comments-accordion,
.care-team-task-notes-accordion,
.encounter-accordion, .external-medops-comments {
    border-radius: 24px;
    border: 1px solid #e6e6e6;
    margin-bottom: 16px;
}

.intake-accordion .p-accordion-header,
.medops-comments-accordion .p-accordion-header, .care-team-task-notes-accordion .p-accordion-header,
.encounter-accordion .p-accordion-header, .external-medops-comments .p-accordion-header {
    border-radius: 24px;
}

.external-medops-comments {
    margin-top: 3rem !important;
}

.intake-accordion .p-accordion-header .text-sm, .medops-comments-accordion .p-accordion-header .text-sm {
    margin: 0;
}

.medops-comments-accordion .p-accordion-content {
    padding-bottom: 4px !important;
}

.medops-comments .message-content {
    margin-left: 45px !important;
}

.interaction-accordion {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    -webkit-box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
            box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
    margin-bottom: 4px;
}

.interaction-accordion .p-accordion-header {
    padding-left: 24px;
}

.interaction-accordion .p-accordion-header-link {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
}

.intake-accordion .p-accordion-tab,
.medops-comments-accordion .p-accordion-tab,
.care-team-task-notes-accordion .p-accordion-tab,
.encounter-accordion .p-accordion-tab,
.interaction-accordion .p-accordion-tab, .external-medops-comments .p-accordion-tab {
    margin-bottom: 0 !important;
}

.intake-accordion .p-accordion-header-link,
.medops-comments-accordion .p-accordion-header-link,
.care-team-task-notes-accordion .p-accordion-header-link,
.encounter-accordion .p-accordion-header-link, .external-medops-comments .p-accordion-header-link {
    padding: 16px 20px 16px 24px !important;
}

.intake-accordion .p-accordion-tab-active .p-accordion-header,
.medops-comments-accordion .p-accordion-tab-active .p-accordion-header,
.care-team-task-notes-accordion .p-accordion-tab-active .p-accordion-header,
.encounter-accordion .p-accordion-tab-active .p-accordion-header, .external-medops-comments .p-accordion-tab-active .p-accordion-header {
    border-radius: 24px 24px 0 0 !important;
    border-bottom: 1px solid #e6e6e6;
}

.create-encounter {
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    background: #ffffff;
    padding: 24px;
    -webkit-box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
            box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
    margin-bottom: 16px;
}

.create-encounter {
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    background: #ffffff;
    padding: 24px;
    -webkit-box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
            box-shadow: 0 24px 40px 0 rgba(26, 26, 26, 0.16);
    margin-bottom: 16px;
}

/* TOGGLEABLE CONSULT DETAIL END */

/* OTHER CARE ACTIVITIES START */
.other-care-activity-link {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 8px;
}

.other-care-activity-link:last-child {
    margin-bottom: 0;
}
/* OTHER CARE ACTIVITIES END */

.patientpanel {
    display: none;
}

.patientpanel.active {
    display: block;
}

/* REVIEW PATIENT ALLERGIES SECTION START */

.patient-allergies {
    padding: 0.5em 0.5em 0 0.5em;
}

.patient-allergies .p-accordion-content {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.highlighted-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 1rem;
}

.highlighted-group .individual-item {
    color: #F44949;
    margin: 0;
}

.highlighted-empty-state {
    color: #F44949;
    margin-top: 0;
    margin-bottom: 24px;
}

.allergies-and-medications {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.verified-allergies-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 1rem;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-row-gap: 8px;
    row-gap: 8px;
}

.verified-allergies-list .individual-allergy {
    margin: 0 !important;
}

.verified-allergies-table .allergy-name-column, .verified-medications-table .medication-name-column {
    padding-left: 0 !important;
}

.allergies-and-medications .patient-reported-display {
    margin-top: 0;
}

.allergies-and-medications .highlighted-group {
    margin-bottom: 24px;
}

.allergies-and-medications .patient-reported-display:last-child .highlighted-group {
    margin-bottom: 16px;
}

/* REVIEW PATIENT ALLERGIES SECTION END */

/* ENCOUNTER ALLERGIES SECTION START */
.allergies-section {
    background: #feecec;
    margin: 3rem -1.25rem 0 -1.25rem;
    padding: 1.5rem 1.25rem 1.5rem 1.5rem;
}

.allergies-section .text-base {
    margin-top: 0 !important;
}

.allergies-section label {
    padding-left: 1rem;
}

.allergies-section label p {
    margin: 0 auto;
    line-height: 24px;
}

.allergies-section label p strong {
    font-weight: 600;
}

/* ENCOUNTER ALLERGIES SECTION END */

/* SESSION TIMEOUT MODAL START */
.expiring-session-modal .p-dialog-header-icons {
    display: none !important;
}
/* SESSION TIMEOUT MODAL END */
@media only screen and (max-width: 560px) {
    .p-dialog .p-dialog-footer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 8px;
        gap: 8px;
    }

    .p-dialog-footer .p-button {
        width: 100% !important;
        -webkit-justify-content: center;
                justify-content: center;
        /*margin-right: 0 !important;*/
    }
}

@media only screen and (min-width: 561px) {
    .p-dialog .p-dialog-footer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        grid-gap: 8px;
        gap: 8px;
    }

    .p-dialog-footer .p-button {
        -webkit-justify-content: center;
                justify-content: center;
        margin-right: 0 !important;
        height: 40px;
    }

    .p-dialog-footer .take-break-button {
        margin-right: auto !important;
        float: left !important;
    }
}

/* PROGRAM DETAIL MODAL START */
.program-detail-button-row {
    padding-top: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.debug-button {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-left: auto;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.debug-button:hover {
    cursor: pointer;
    background: #e9f7f8;
}

.program-detail-button {
    padding: 10px 24px;
}

.program-detail-button a {
    color: #ffffff;
}

.program-detail-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 14px;
    padding-top: 8px;
    line-height: 1.5;
    font-size: 14px;
}

.program-detail-description {
    margin: 0;
    line-height: 1.5;
}
/* PROGRAM DETAIL MODAL END */

/* ANNOTATIONS START */
.annotation:hover {
    cursor: default;
}
/* ANNOTATIONS END */

/* DATA TABLE START */
.message-history .is-new {
    background: rgb(236, 245, 250) !important;
}

.p-datatable .p-datatable-header, .p-datatable .p-datatable-thead > tr > th {
    color: #404040 !important;
}

.p-datatable .p-datatable-header, .p-datatable-wrapper th {
    padding: 0.5rem !important;
}

.p-datatable .p-column-filter-menu {
    margin: 0 0 0 8px;
}

.p-datatable .p-datatable-tbody td {
    font-size: 14px;
}

.p-datatable .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    font-size: 14px;
}

.p-datatable .p-checkbox.p-component, .p-datatable .p-checkbox-box {
    height: 18px !important;
    width: 18px !important;
}

.p-datatable .p-inputtext.p-component {
    font-size: 14px;
}
/* DATA TABLE END */

/* OBSERVATIONS START */
.patient-observation-data {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    width: 100%;
}

.patient-observation {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc((100% - 2 * 16px) / 3);
            flex-basis: calc((100% - 2 * 16px) / 3);
    margin-bottom: 1rem;
}

.see-more-observations-button {
    color: var(--primary-color) !important;
    cursor: pointer;
}

.see-more-observations-button:hover, .show-all-observations-button:hover {
    color: #1D8A96 !important;
}

.add-observation-modal {
    width: 327px !important;
    min-width: 327px !important;
    min-height: 380px !important;
    position: fixed !important;
    right: 30px;
    -webkit-box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
            box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
}

.add-allergy-modal, .add-medication-modal {
    width: 327px !important;
    min-width: 327px !important;
    position: fixed !important;
    right: 30px;
    -webkit-box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
            box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
}

.remove-observation-modal, .remove-allergy-modal, .remove-medication-modal {
    max-width: 355px !important;
    min-width: 327px !important;
    height: 100px !important;
    position: fixed !important;
    right: 30px;
    -webkit-box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
            box-shadow: 0 8px 32px rgba(26, 26, 26, 0.24) !important;
}

.add-observation-modal .observation-today-button {
    padding: 0;
    margin-left: 8px;
}

.add-observation-modal .observation-today-button:hover {
    background: none !important;
    color: #1D8A96 !important;
}

.add-observation-modal .date-and-button, .add-observation-modal .value-and-label, .add-allergy-modal .value-and-label {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.add-observation-modal .radio-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.add-observation-modal .radio-buttons label {
    margin-left: 4px;
}

.add-observation-modal .observation-type-container, .add-allergy-modal .allergy-name-container, .add-medication-modal .medication-name-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.add-observation-modal .observation-fields, .add-allergy-modal .allergy-fields, .add-medication-modal .medication-fields {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.add-observation-modal #observation-type, .add-allergy-modal #allergy-name, .add-medication-modal #medication-name {
    width: 100%;
}

.add-observation-modal #observation-value, .add-observation-modal #observation-date {
    width: 75%;
}

.add-allergy-modal .allergy-name-container, .add-medication-modal .medication-name-container {
    margin-bottom: 0 !important;
}

.inline-observations-info, .inline-allergies-info, .inline-medications-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.inline-observations-info .manual-observation, .inline-allergies-info .manual-allergy, .inline-medications-info .manual-medication {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    margin-bottom: 16px;
}

.inline-observations-info .edit-observation-button, .inline-observations-info .delete-observation-button, .inline-allergies-info .edit-allergy-button, .inline-allergies-info .delete-allergy-button, .inline-medications-info .edit-medication-button, .inline-medications-info .delete-medication-button {
    padding: 0;
    margin-left: 8px;
}

.patient-observations .p-accordion-content {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.show-all-observations-button {
    color: var(--primary-color) !important;
    cursor: pointer;
}

/* OBSERVATIONS END */

/* COMPLETED CONSULTS START */

.p-paginator-first, .p-paginator-prev, .p-paginator-page, .p-paginator-next, .p-paginator-last {
    font-size: 14px !important;
    min-width: 2rem !important;
    height: 2rem !important;
    width: 2rem !important;
}

.p-paginator-current {
    font-size: 14px;
}

.completed-consults-table .p-datatable-wrapper {
    overflow-x: auto;
}

.p-datatable.p-component.p-datatable-responsive-stack.p-datatable-striped.completed-consults-table {
    width: 100%;
}

.completed-consults-table .p-datatable-emptymessage td {
    text-align: center !important;
    padding: 20px 0 !important;
}

.view-consult-guid, .chart-clone-consult {
    color: #26aebc;
    cursor: pointer;
}

.view-consult-guid:hover, .chart-clone-consult:hover {
    color: #1d8a96;
}

.completed-consults-stats {
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
    border-radius: 16px;
}

.completed-consults-stats .p-card-content {
    padding-top: 16px;
    padding-bottom: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.completed-consults-stats .p-card-content p {
    margin: 0;
}

.completed-consults-stats .stats-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    grid-gap: 16px;
    gap: 16px;
}

.completed-consults-header {
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.completed-consults-table .p-datatable-header {
    border-top: none;
}

/* COMPLETED CONSULTS END */

/* TINKERBELL AI SUMMARY START */

.edit-again-button {
    color: #26aebc;
    cursor: pointer;
    margin-top: 8px;
}

.edit-again-button:hover {
    color: #1d8a96 !important;
}

/* TINKERBELL AI SUMMARY END */

.consult-btn-external-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 24px;
}

/* GRAPHS START */

.observation-graph {
    width: 100%;
    min-width: 327px;
    max-width: 90vw;
    min-height: 225px;
    background: #ffffff;
    padding: 0 24px 24px 24px;
}

.heart-rate-graph .p-chart {
    max-height: 500px;
}

.observation-graph .p-dialog-header {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
}

.show-graph-data {
    color: #26aebc;
    cursor: pointer;
}

.show-graph-data:hover {
    color: #1d8a96;
    background: #efefef;
    border-radius: 100%;
}

/* GRAPHS END */

/* CLINICIAN INBOX START */

.clinician-inbox {
    width: 100%;
    border-radius: 8px;
    outline: 1px solid #cdcdcd;
    height: 496px;
}

.clinician-inbox .p-card-header {
    text-align: center;
    background: #f1f1f1;
    padding: 8px;
    border-bottom: 1px solid #cdcdcd;
    height: 40px;
    border-radius: 8px 8px 0 0;
}

.clinician-inbox .inbox-navigation-tabs {
    border-bottom: 1px solid #cdcdcd;
    padding: 0 8px 8px 8px;
}

.clinician-inbox .p-card-body {
    padding: 0;
}

.clinician-inbox .p-card-content {
    padding: 8px 0 0 0;
}

.clinician-inbox .inbox-navigation-tabs .p-selectbutton {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.clinician-inbox .inbox-navigation-tabs .p-button-group .p-button:first-of-type {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.clinician-inbox .inbox-navigation-tabs .p-button-group .p-button:last-of-type {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.clinician-inbox .inbox-navigation-tabs .p-button {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
}

.clinician-inbox .message-preview {
    height: 88px;
    border-bottom: 1px solid #cdcdcd;
    padding: 8px 16px 8px 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer;
}

.clinician-inbox .message-preview .top-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 24px;
}

.clinician-inbox .message-preview .top-row .left-side {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-width: 0;
}

.clinician-inbox .message-preview .top-row .right-side {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 4px;
    gap: 4px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.clinician-inbox .new-indicator {
    background: #26AEBC;
    border-radius: 100px;
    height: 8px;
    width: 8px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.clinician-inbox .message-preview .top-row .sender {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.clinician-inbox .timestamp {
    color: #757575;
    text-align: right;
}

.clinician-inbox .message-preview .subject-line, .clinician-inbox .message-preview .body-text-preview {
    padding-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
}

.clinician-inbox .message-preview .body-text-preview {
    color: #757575;
}

.clinician-inbox-toggle-button {
    width: 135px;
    color: #26aebc;
    background: #ffffff;
    border: 1px solid #26aebc;
    padding: 14px 16px;
    border-radius: 24px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
}

.clinician-inbox-with-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
    position: fixed;
    bottom: 24px;
    z-index: 50;
    padding: 0 24px;
}

.clinician-inbox-toggle-button .unread-indicator {
    background: #f44949;
    outline: 2px solid #ffffff;
    color: #ffffff;
    border-radius: 1000px;
    padding: 4px;
    font-size: 14px;
    min-width: 28px;
    min-height: 28px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    right: 0;
    top: -16px;
    text-align: center;
}

@media only screen and (min-width: 992px) {
    .clinician-inbox-with-button {
        -webkit-align-self: center;
                align-self: center;
        padding: 0 !important
    }

    .consult-queue-navigation .clinician-inbox-with-button, .single-queue-schedule .clinician-inbox-with-button {
        width: 25%;
        max-width: 328px;
    }

    .consult-queue-navigation, .single-queue-schedule {
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding-bottom: 0;
        position: relative;
    }
}

.clinician-inbox .full-message-view .navigation-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 16px 16px 16px;
    border-bottom: 1px solid #cdcdcd;
}

.clinician-inbox .back-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 4px;
    gap: 4px;
    color: #26aebc;
    cursor: pointer;
}

.clinician-inbox .back-button:hover {
    color: #1d8a96;
}

.clinician-inbox .hamburger-menu-button {
    cursor: pointer;
}

.clinician-inbox .full-message-view .message-content .top-row {
    padding: 12px 16px 8px 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.clinician-inbox .full-message-view .message-content .top-row .left-side {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-width: 0;
}

.clinician-inbox .full-message-view .message-content .top-row .right-side {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 4px;
    gap: 4px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    align-self: flex-start;
}

.clinician-inbox .full-message-view .message-content .subject {
    padding: 0 16px 8px 24px;
}

.clinician-inbox .full-message-view .message-content .body {
    padding: 0 16px 16px 24px;
}

.clinician-inbox .inbox-container, .clinician-inbox .full-message-view .message-content {
    max-height: 400px;
    overflow-y: auto;
}

.clinician-inbox .body p:first-child, .clinician-inbox .body-text-preview p {
    margin-top: 0;
}

.clinician-inbox .body-text-preview p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clinician-inbox .inbox-empty-state {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 16px 16px 0 16px;
    overflow-x: clip;
}

.clinician-inbox .inbox-empty-state img {
    max-height: 320px;
    width: auto;
    object-fit: contain;
}

.inbox-empty-state .text, .inbox-loading-state .text {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.inbox-empty-state .text p {
    margin: 0;
}

.inbox-loading-state .text p {
    color: #757575;
}

/* CLINICIAN INBOX END */
.grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.grid > .col,
.grid > [class*=col] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0;
}

.col {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .sm\:col {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    padding: 0.5rem;
  }
  .sm\:col-fixed {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
  }
  .sm\:col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .sm\:col-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .sm\:col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .sm\:col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .sm\:col-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .sm\:col-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .sm\:col-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .sm\:col-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .sm\:col-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .sm\:col-10 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .sm\:col-11 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .sm\:col-12 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .md\:col {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    padding: 0.5rem;
  }
  .md\:col-fixed {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
  }
  .md\:col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .md\:col-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .md\:col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .md\:col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .md\:col-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .md\:col-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .md\:col-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .md\:col-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .md\:col-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .md\:col-10 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .md\:col-11 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .md\:col-12 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    padding: 0.5rem;
  }
  .lg\:col-fixed {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
  }
  .lg\:col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .lg\:col-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .lg\:col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .lg\:col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .lg\:col-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .lg\:col-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .lg\:col-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .lg\:col-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .lg\:col-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .lg\:col-10 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .lg\:col-11 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .lg\:col-12 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    padding: 0.5rem;
  }
  .xl\:col-fixed {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xl\:col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xl\:col-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xl\:col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xl\:col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xl\:col-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xl\:col-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xl\:col-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xl\:col-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xl\:col-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xl\:col-10 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xl\:col-11 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xl\:col-12 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }
  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }
  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }
  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }
  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }
  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .md\:col-offset-3 {
    margin-left: 25% !important;
  }
  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .md\:col-offset-6 {
    margin-left: 50% !important;
  }
  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .md\:col-offset-9 {
    margin-left: 75% !important;
  }
  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }
  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }
  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }
  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }
  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus {
  color: var(--surface-0) !important;
}

.hover\:text-0:hover {
  color: var(--surface-0) !important;
}

.active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus {
  color: var(--surface-50) !important;
}

.hover\:text-50:hover {
  color: var(--surface-50) !important;
}

.active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus {
  color: var(--surface-100) !important;
}

.hover\:text-100:hover {
  color: var(--surface-100) !important;
}

.active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus {
  color: var(--surface-200) !important;
}

.hover\:text-200:hover {
  color: var(--surface-200) !important;
}

.active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus {
  color: var(--surface-300) !important;
}

.hover\:text-300:hover {
  color: var(--surface-300) !important;
}

.active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus {
  color: var(--surface-400) !important;
}

.hover\:text-400:hover {
  color: var(--surface-400) !important;
}

.active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus {
  color: var(--surface-500) !important;
}

.hover\:text-500:hover {
  color: var(--surface-500) !important;
}

.active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus {
  color: var(--surface-600) !important;
}

.hover\:text-600:hover {
  color: var(--surface-600) !important;
}

.active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus {
  color: var(--surface-700) !important;
}

.hover\:text-700:hover {
  color: var(--surface-700) !important;
}

.active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus {
  color: var(--surface-800) !important;
}

.hover\:text-800:hover {
  color: var(--surface-800) !important;
}

.active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus {
  color: var(--surface-900) !important;
}

.hover\:text-900:hover {
  color: var(--surface-900) !important;
}

.active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus {
  background-color: var(--surface-0) !important;
}

.hover\:surface-0:hover {
  background-color: var(--surface-0) !important;
}

.active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus {
  background-color: var(--surface-50) !important;
}

.hover\:surface-50:hover {
  background-color: var(--surface-50) !important;
}

.active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus {
  background-color: var(--surface-100) !important;
}

.hover\:surface-100:hover {
  background-color: var(--surface-100) !important;
}

.active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus {
  background-color: var(--surface-200) !important;
}

.hover\:surface-200:hover {
  background-color: var(--surface-200) !important;
}

.active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus {
  background-color: var(--surface-300) !important;
}

.hover\:surface-300:hover {
  background-color: var(--surface-300) !important;
}

.active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus {
  background-color: var(--surface-400) !important;
}

.hover\:surface-400:hover {
  background-color: var(--surface-400) !important;
}

.active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus {
  background-color: var(--surface-500) !important;
}

.hover\:surface-500:hover {
  background-color: var(--surface-500) !important;
}

.active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus {
  background-color: var(--surface-600) !important;
}

.hover\:surface-600:hover {
  background-color: var(--surface-600) !important;
}

.active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus {
  background-color: var(--surface-700) !important;
}

.hover\:surface-700:hover {
  background-color: var(--surface-700) !important;
}

.active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus {
  background-color: var(--surface-800) !important;
}

.hover\:surface-800:hover {
  background-color: var(--surface-800) !important;
}

.active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus {
  background-color: var(--surface-900) !important;
}

.hover\:surface-900:hover {
  background-color: var(--surface-900) !important;
}

.active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus {
  border-color: var(--surface-0) !important;
}

.hover\:border-0:hover {
  border-color: var(--surface-0) !important;
}

.active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus {
  border-color: var(--surface-50) !important;
}

.hover\:border-50:hover {
  border-color: var(--surface-50) !important;
}

.active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus {
  border-color: var(--surface-100) !important;
}

.hover\:border-100:hover {
  border-color: var(--surface-100) !important;
}

.active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus {
  border-color: var(--surface-200) !important;
}

.hover\:border-200:hover {
  border-color: var(--surface-200) !important;
}

.active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus {
  border-color: var(--surface-300) !important;
}

.hover\:border-300:hover {
  border-color: var(--surface-300) !important;
}

.active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus {
  border-color: var(--surface-400) !important;
}

.hover\:border-400:hover {
  border-color: var(--surface-400) !important;
}

.active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus {
  border-color: var(--surface-500) !important;
}

.hover\:border-500:hover {
  border-color: var(--surface-500) !important;
}

.active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus {
  border-color: var(--surface-600) !important;
}

.hover\:border-600:hover {
  border-color: var(--surface-600) !important;
}

.active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus {
  border-color: var(--surface-700) !important;
}

.hover\:border-700:hover {
  border-color: var(--surface-700) !important;
}

.active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus {
  border-color: var(--surface-800) !important;
}

.hover\:border-800:hover {
  border-color: var(--surface-800) !important;
}

.active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus {
  border-color: var(--surface-900) !important;
}

.hover\:border-900:hover {
  border-color: var(--surface-900) !important;
}

.active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
.border-transparent {
  border-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-transparent {
    border-color: transparent !important;
  }
}
.text-blue-50 {
  color: var(--blue-50) !important;
}
.text-blue-100 {
  color: var(--blue-100) !important;
}
.text-blue-200 {
  color: var(--blue-200) !important;
}
.text-blue-300 {
  color: var(--blue-300) !important;
}
.text-blue-400 {
  color: var(--blue-400) !important;
}
.text-blue-500 {
  color: var(--blue-500) !important;
}
.text-blue-600 {
  color: var(--blue-600) !important;
}
.text-blue-700 {
  color: var(--blue-700) !important;
}
.text-blue-800 {
  color: var(--blue-800) !important;
}
.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}
.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}
.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}
.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}
.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}
.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}
.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}
.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}
.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}
.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}
.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}
.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}
.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}
.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}
.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}
.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}
.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}
.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}
.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}
.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}
.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}
.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}
.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}
.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}
.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}
.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}
.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}
.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}
.text-green-100 {
  color: var(--green-100) !important;
}
.text-green-200 {
  color: var(--green-200) !important;
}
.text-green-300 {
  color: var(--green-300) !important;
}
.text-green-400 {
  color: var(--green-400) !important;
}
.text-green-500 {
  color: var(--green-500) !important;
}
.text-green-600 {
  color: var(--green-600) !important;
}
.text-green-700 {
  color: var(--green-700) !important;
}
.text-green-800 {
  color: var(--green-800) !important;
}
.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}
.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}
.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}
.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}
.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}
.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}
.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}
.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}
.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}
.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}
.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}
.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}
.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}
.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}
.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}
.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}
.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}
.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}
.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}
.active\:text-green-100:active {
  color: var(--green-100) !important;
}
.active\:text-green-200:active {
  color: var(--green-200) !important;
}
.active\:text-green-300:active {
  color: var(--green-300) !important;
}
.active\:text-green-400:active {
  color: var(--green-400) !important;
}
.active\:text-green-500:active {
  color: var(--green-500) !important;
}
.active\:text-green-600:active {
  color: var(--green-600) !important;
}
.active\:text-green-700:active {
  color: var(--green-700) !important;
}
.active\:text-green-800:active {
  color: var(--green-800) !important;
}
.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}
.text-yellow-100 {
  color: var(--yellow-100) !important;
}
.text-yellow-200 {
  color: var(--yellow-200) !important;
}
.text-yellow-300 {
  color: var(--yellow-300) !important;
}
.text-yellow-400 {
  color: var(--yellow-400) !important;
}
.text-yellow-500 {
  color: var(--yellow-500) !important;
}
.text-yellow-600 {
  color: var(--yellow-600) !important;
}
.text-yellow-700 {
  color: var(--yellow-700) !important;
}
.text-yellow-800 {
  color: var(--yellow-800) !important;
}
.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}
.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}
.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}
.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}
.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}
.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}
.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}
.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}
.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}
.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}
.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}
.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}
.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}
.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}
.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}
.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}
.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}
.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}
.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}
.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}
.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}
.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}
.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}
.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}
.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}
.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}
.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}
.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}
.text-cyan-100 {
  color: var(--cyan-100) !important;
}
.text-cyan-200 {
  color: var(--cyan-200) !important;
}
.text-cyan-300 {
  color: var(--cyan-300) !important;
}
.text-cyan-400 {
  color: var(--cyan-400) !important;
}
.text-cyan-500 {
  color: var(--cyan-500) !important;
}
.text-cyan-600 {
  color: var(--cyan-600) !important;
}
.text-cyan-700 {
  color: var(--cyan-700) !important;
}
.text-cyan-800 {
  color: var(--cyan-800) !important;
}
.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}
.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}
.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}
.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}
.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}
.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}
.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}
.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}
.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}
.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}
.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}
.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}
.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}
.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}
.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}
.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}
.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}
.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}
.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}
.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}
.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}
.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}
.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}
.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}
.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}
.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}
.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}
.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}
.text-pink-100 {
  color: var(--pink-100) !important;
}
.text-pink-200 {
  color: var(--pink-200) !important;
}
.text-pink-300 {
  color: var(--pink-300) !important;
}
.text-pink-400 {
  color: var(--pink-400) !important;
}
.text-pink-500 {
  color: var(--pink-500) !important;
}
.text-pink-600 {
  color: var(--pink-600) !important;
}
.text-pink-700 {
  color: var(--pink-700) !important;
}
.text-pink-800 {
  color: var(--pink-800) !important;
}
.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}
.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}
.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}
.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}
.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}
.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}
.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}
.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}
.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}
.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}
.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}
.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}
.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}
.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}
.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}
.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}
.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}
.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}
.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}
.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}
.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}
.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}
.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}
.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}
.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}
.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}
.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}
.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}
.text-indigo-100 {
  color: var(--indigo-100) !important;
}
.text-indigo-200 {
  color: var(--indigo-200) !important;
}
.text-indigo-300 {
  color: var(--indigo-300) !important;
}
.text-indigo-400 {
  color: var(--indigo-400) !important;
}
.text-indigo-500 {
  color: var(--indigo-500) !important;
}
.text-indigo-600 {
  color: var(--indigo-600) !important;
}
.text-indigo-700 {
  color: var(--indigo-700) !important;
}
.text-indigo-800 {
  color: var(--indigo-800) !important;
}
.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}
.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}
.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}
.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}
.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}
.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}
.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}
.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}
.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}
.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}
.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}
.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}
.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}
.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}
.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}
.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}
.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}
.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}
.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}
.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}
.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}
.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}
.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}
.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}
.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}
.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}
.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}
.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}
.text-teal-100 {
  color: var(--teal-100) !important;
}
.text-teal-200 {
  color: var(--teal-200) !important;
}
.text-teal-300 {
  color: var(--teal-300) !important;
}
.text-teal-400 {
  color: var(--teal-400) !important;
}
.text-teal-500 {
  color: var(--teal-500) !important;
}
.text-teal-600 {
  color: var(--teal-600) !important;
}
.text-teal-700 {
  color: var(--teal-700) !important;
}
.text-teal-800 {
  color: var(--teal-800) !important;
}
.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}
.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}
.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}
.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}
.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}
.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}
.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}
.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}
.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}
.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}
.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}
.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}
.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}
.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}
.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}
.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}
.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}
.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}
.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}
.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}
.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}
.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}
.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}
.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}
.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}
.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}
.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}
.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}
.text-orange-100 {
  color: var(--orange-100) !important;
}
.text-orange-200 {
  color: var(--orange-200) !important;
}
.text-orange-300 {
  color: var(--orange-300) !important;
}
.text-orange-400 {
  color: var(--orange-400) !important;
}
.text-orange-500 {
  color: var(--orange-500) !important;
}
.text-orange-600 {
  color: var(--orange-600) !important;
}
.text-orange-700 {
  color: var(--orange-700) !important;
}
.text-orange-800 {
  color: var(--orange-800) !important;
}
.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}
.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}
.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}
.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}
.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}
.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}
.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}
.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}
.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}
.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}
.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}
.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}
.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}
.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}
.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}
.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}
.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}
.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}
.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}
.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}
.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}
.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}
.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}
.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}
.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}
.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}
.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}
.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}
.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}
.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}
.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}
.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}
.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}
.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}
.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}
.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}
.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}
.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}
.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}
.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}
.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}
.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}
.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}
.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}
.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}
.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}
.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}
.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}
.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}
.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}
.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}
.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}
.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}
.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}
.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}
.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}
.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}
.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}
.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}
.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}
.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}
.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}
.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}
.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}
.text-purple-100 {
  color: var(--purple-100) !important;
}
.text-purple-200 {
  color: var(--purple-200) !important;
}
.text-purple-300 {
  color: var(--purple-300) !important;
}
.text-purple-400 {
  color: var(--purple-400) !important;
}
.text-purple-500 {
  color: var(--purple-500) !important;
}
.text-purple-600 {
  color: var(--purple-600) !important;
}
.text-purple-700 {
  color: var(--purple-700) !important;
}
.text-purple-800 {
  color: var(--purple-800) !important;
}
.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}
.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}
.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}
.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}
.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}
.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}
.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}
.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}
.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}
.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}
.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}
.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}
.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}
.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}
.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}
.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}
.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}
.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}
.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}
.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}
.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}
.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}
.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}
.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}
.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}
.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}
.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}
.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}
.text-gray-100 {
  color: var(--gray-100) !important;
}
.text-gray-200 {
  color: var(--gray-200) !important;
}
.text-gray-300 {
  color: var(--gray-300) !important;
}
.text-gray-400 {
  color: var(--gray-400) !important;
}
.text-gray-500 {
  color: var(--gray-500) !important;
}
.text-gray-600 {
  color: var(--gray-600) !important;
}
.text-gray-700 {
  color: var(--gray-700) !important;
}
.text-gray-800 {
  color: var(--gray-800) !important;
}
.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}
.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}
.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}
.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}
.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}
.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}
.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}
.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}
.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}
.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}
.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}
.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}
.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}
.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}
.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}
.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}
.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}
.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}
.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}
.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}
.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}
.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}
.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}
.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}
.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}
.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}
.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}
.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}
.text-red-100 {
  color: var(--red-100) !important;
}
.text-red-200 {
  color: var(--red-200) !important;
}
.text-red-300 {
  color: var(--red-300) !important;
}
.text-red-400 {
  color: var(--red-400) !important;
}
.text-red-500 {
  color: var(--red-500) !important;
}
.text-red-600 {
  color: var(--red-600) !important;
}
.text-red-700 {
  color: var(--red-700) !important;
}
.text-red-800 {
  color: var(--red-800) !important;
}
.text-red-900 {
  color: var(--red-900) !important;
}

.focus\:text-red-50:focus {
  color: var(--red-50) !important;
}
.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}
.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}
.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}
.focus\:text-red-400:focus {
  color: var(--red-400) !important;
}
.focus\:text-red-500:focus {
  color: var(--red-500) !important;
}
.focus\:text-red-600:focus {
  color: var(--red-600) !important;
}
.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}
.focus\:text-red-800:focus {
  color: var(--red-800) !important;
}
.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-50:hover {
  color: var(--red-50) !important;
}
.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}
.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}
.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}
.hover\:text-red-400:hover {
  color: var(--red-400) !important;
}
.hover\:text-red-500:hover {
  color: var(--red-500) !important;
}
.hover\:text-red-600:hover {
  color: var(--red-600) !important;
}
.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}
.hover\:text-red-800:hover {
  color: var(--red-800) !important;
}
.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-50:active {
  color: var(--red-50) !important;
}
.active\:text-red-100:active {
  color: var(--red-100) !important;
}
.active\:text-red-200:active {
  color: var(--red-200) !important;
}
.active\:text-red-300:active {
  color: var(--red-300) !important;
}
.active\:text-red-400:active {
  color: var(--red-400) !important;
}
.active\:text-red-500:active {
  color: var(--red-500) !important;
}
.active\:text-red-600:active {
  color: var(--red-600) !important;
}
.active\:text-red-700:active {
  color: var(--red-700) !important;
}
.active\:text-red-800:active {
  color: var(--red-800) !important;
}
.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}
.text-primary-100 {
  color: var(--primary-100) !important;
}
.text-primary-200 {
  color: var(--primary-200) !important;
}
.text-primary-300 {
  color: var(--primary-300) !important;
}
.text-primary-400 {
  color: var(--primary-400) !important;
}
.text-primary-500 {
  color: var(--primary-500) !important;
}
.text-primary-600 {
  color: var(--primary-600) !important;
}
.text-primary-700 {
  color: var(--primary-700) !important;
}
.text-primary-800 {
  color: var(--primary-800) !important;
}
.text-primary-900 {
  color: var(--primary-900) !important;
}

.focus\:text-primary-50:focus {
  color: var(--primary-50) !important;
}
.focus\:text-primary-100:focus {
  color: var(--primary-100) !important;
}
.focus\:text-primary-200:focus {
  color: var(--primary-200) !important;
}
.focus\:text-primary-300:focus {
  color: var(--primary-300) !important;
}
.focus\:text-primary-400:focus {
  color: var(--primary-400) !important;
}
.focus\:text-primary-500:focus {
  color: var(--primary-500) !important;
}
.focus\:text-primary-600:focus {
  color: var(--primary-600) !important;
}
.focus\:text-primary-700:focus {
  color: var(--primary-700) !important;
}
.focus\:text-primary-800:focus {
  color: var(--primary-800) !important;
}
.focus\:text-primary-900:focus {
  color: var(--primary-900) !important;
}

.hover\:text-primary-50:hover {
  color: var(--primary-50) !important;
}
.hover\:text-primary-100:hover {
  color: var(--primary-100) !important;
}
.hover\:text-primary-200:hover {
  color: var(--primary-200) !important;
}
.hover\:text-primary-300:hover {
  color: var(--primary-300) !important;
}
.hover\:text-primary-400:hover {
  color: var(--primary-400) !important;
}
.hover\:text-primary-500:hover {
  color: var(--primary-500) !important;
}
.hover\:text-primary-600:hover {
  color: var(--primary-600) !important;
}
.hover\:text-primary-700:hover {
  color: var(--primary-700) !important;
}
.hover\:text-primary-800:hover {
  color: var(--primary-800) !important;
}
.hover\:text-primary-900:hover {
  color: var(--primary-900) !important;
}

.active\:text-primary-50:active {
  color: var(--primary-50) !important;
}
.active\:text-primary-100:active {
  color: var(--primary-100) !important;
}
.active\:text-primary-200:active {
  color: var(--primary-200) !important;
}
.active\:text-primary-300:active {
  color: var(--primary-300) !important;
}
.active\:text-primary-400:active {
  color: var(--primary-400) !important;
}
.active\:text-primary-500:active {
  color: var(--primary-500) !important;
}
.active\:text-primary-600:active {
  color: var(--primary-600) !important;
}
.active\:text-primary-700:active {
  color: var(--primary-700) !important;
}
.active\:text-primary-800:active {
  color: var(--primary-800) !important;
}
.active\:text-primary-900:active {
  color: var(--primary-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.bg-blue-500 {
  background-color: var(--blue-500) !important;
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.bg-blue-800 {
  background-color: var(--blue-800) !important;
}
.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}
.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}
.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}
.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}
.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}
.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}
.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}
.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}
.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}
.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}
.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}
.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}
.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}
.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}
.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}
.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}
.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}
.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}
.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}
.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}
.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}
.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}
.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}
.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}
.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}
.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}
.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}
.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}
.bg-green-100 {
  background-color: var(--green-100) !important;
}
.bg-green-200 {
  background-color: var(--green-200) !important;
}
.bg-green-300 {
  background-color: var(--green-300) !important;
}
.bg-green-400 {
  background-color: var(--green-400) !important;
}
.bg-green-500 {
  background-color: var(--green-500) !important;
}
.bg-green-600 {
  background-color: var(--green-600) !important;
}
.bg-green-700 {
  background-color: var(--green-700) !important;
}
.bg-green-800 {
  background-color: var(--green-800) !important;
}
.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}
.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}
.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}
.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}
.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}
.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}
.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}
.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}
.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}
.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}
.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}
.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}
.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}
.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}
.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}
.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}
.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}
.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}
.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}
.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}
.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}
.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}
.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}
.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}
.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}
.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}
.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}
.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}
.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}
.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}
.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}
.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}
.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}
.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}
.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}
.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}
.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}
.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}
.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}
.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}
.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}
.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}
.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}
.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}
.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}
.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}
.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}
.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}
.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}
.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}
.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}
.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}
.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}
.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}
.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}
.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}
.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}
.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}
.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}
.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}
.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}
.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}
.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}
.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}
.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}
.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}
.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}
.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}
.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}
.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}
.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}
.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}
.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}
.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}
.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}
.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}
.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}
.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}
.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}
.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}
.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}
.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}
.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}
.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}
.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}
.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}
.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}
.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}
.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}
.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}
.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}
.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}
.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}
.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}
.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}
.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}
.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}
.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}
.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}
.bg-pink-100 {
  background-color: var(--pink-100) !important;
}
.bg-pink-200 {
  background-color: var(--pink-200) !important;
}
.bg-pink-300 {
  background-color: var(--pink-300) !important;
}
.bg-pink-400 {
  background-color: var(--pink-400) !important;
}
.bg-pink-500 {
  background-color: var(--pink-500) !important;
}
.bg-pink-600 {
  background-color: var(--pink-600) !important;
}
.bg-pink-700 {
  background-color: var(--pink-700) !important;
}
.bg-pink-800 {
  background-color: var(--pink-800) !important;
}
.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}
.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}
.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}
.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}
.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}
.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}
.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}
.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}
.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}
.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}
.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}
.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}
.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}
.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}
.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}
.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}
.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}
.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}
.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}
.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}
.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}
.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}
.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}
.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}
.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}
.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}
.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}
.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}
.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}
.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}
.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}
.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}
.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}
.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}
.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}
.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}
.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}
.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}
.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}
.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}
.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}
.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}
.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}
.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}
.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}
.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}
.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}
.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}
.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}
.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}
.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}
.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}
.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}
.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}
.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}
.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}
.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}
.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}
.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}
.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}
.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}
.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}
.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}
.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}
.bg-teal-100 {
  background-color: var(--teal-100) !important;
}
.bg-teal-200 {
  background-color: var(--teal-200) !important;
}
.bg-teal-300 {
  background-color: var(--teal-300) !important;
}
.bg-teal-400 {
  background-color: var(--teal-400) !important;
}
.bg-teal-500 {
  background-color: var(--teal-500) !important;
}
.bg-teal-600 {
  background-color: var(--teal-600) !important;
}
.bg-teal-700 {
  background-color: var(--teal-700) !important;
}
.bg-teal-800 {
  background-color: var(--teal-800) !important;
}
.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}
.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}
.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}
.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}
.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}
.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}
.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}
.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}
.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}
.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}
.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}
.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}
.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}
.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}
.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}
.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}
.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}
.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}
.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}
.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}
.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}
.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}
.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}
.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}
.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}
.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}
.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}
.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}
.bg-orange-100 {
  background-color: var(--orange-100) !important;
}
.bg-orange-200 {
  background-color: var(--orange-200) !important;
}
.bg-orange-300 {
  background-color: var(--orange-300) !important;
}
.bg-orange-400 {
  background-color: var(--orange-400) !important;
}
.bg-orange-500 {
  background-color: var(--orange-500) !important;
}
.bg-orange-600 {
  background-color: var(--orange-600) !important;
}
.bg-orange-700 {
  background-color: var(--orange-700) !important;
}
.bg-orange-800 {
  background-color: var(--orange-800) !important;
}
.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}
.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}
.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}
.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}
.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}
.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}
.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}
.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}
.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}
.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}
.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}
.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}
.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}
.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}
.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}
.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}
.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}
.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}
.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}
.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}
.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}
.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}
.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}
.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}
.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}
.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}
.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}
.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}
.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}
.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}
.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}
.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}
.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}
.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}
.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}
.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}
.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}
.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}
.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}
.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}
.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}
.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}
.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}
.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}
.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}
.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}
.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}
.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}
.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}
.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}
.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}
.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}
.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}
.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}
.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}
.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}
.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}
.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}
.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}
.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}
.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}
.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}
.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}
.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}
.bg-purple-100 {
  background-color: var(--purple-100) !important;
}
.bg-purple-200 {
  background-color: var(--purple-200) !important;
}
.bg-purple-300 {
  background-color: var(--purple-300) !important;
}
.bg-purple-400 {
  background-color: var(--purple-400) !important;
}
.bg-purple-500 {
  background-color: var(--purple-500) !important;
}
.bg-purple-600 {
  background-color: var(--purple-600) !important;
}
.bg-purple-700 {
  background-color: var(--purple-700) !important;
}
.bg-purple-800 {
  background-color: var(--purple-800) !important;
}
.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}
.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}
.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}
.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}
.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}
.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}
.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}
.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}
.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}
.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}
.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}
.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}
.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}
.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}
.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}
.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}
.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}
.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}
.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}
.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}
.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}
.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}
.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}
.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}
.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}
.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}
.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}
.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}
.bg-gray-100 {
  background-color: var(--gray-100) !important;
}
.bg-gray-200 {
  background-color: var(--gray-200) !important;
}
.bg-gray-300 {
  background-color: var(--gray-300) !important;
}
.bg-gray-400 {
  background-color: var(--gray-400) !important;
}
.bg-gray-500 {
  background-color: var(--gray-500) !important;
}
.bg-gray-600 {
  background-color: var(--gray-600) !important;
}
.bg-gray-700 {
  background-color: var(--gray-700) !important;
}
.bg-gray-800 {
  background-color: var(--gray-800) !important;
}
.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}
.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}
.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}
.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}
.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}
.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}
.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}
.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}
.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}
.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}
.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}
.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}
.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}
.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}
.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}
.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}
.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}
.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}
.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}
.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}
.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}
.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}
.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}
.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}
.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}
.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}
.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}
.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}
.bg-red-100 {
  background-color: var(--red-100) !important;
}
.bg-red-200 {
  background-color: var(--red-200) !important;
}
.bg-red-300 {
  background-color: var(--red-300) !important;
}
.bg-red-400 {
  background-color: var(--red-400) !important;
}
.bg-red-500 {
  background-color: var(--red-500) !important;
}
.bg-red-600 {
  background-color: var(--red-600) !important;
}
.bg-red-700 {
  background-color: var(--red-700) !important;
}
.bg-red-800 {
  background-color: var(--red-800) !important;
}
.bg-red-900 {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-50:focus {
  background-color: var(--red-50) !important;
}
.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}
.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}
.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}
.focus\:bg-red-400:focus {
  background-color: var(--red-400) !important;
}
.focus\:bg-red-500:focus {
  background-color: var(--red-500) !important;
}
.focus\:bg-red-600:focus {
  background-color: var(--red-600) !important;
}
.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}
.focus\:bg-red-800:focus {
  background-color: var(--red-800) !important;
}
.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-50:hover {
  background-color: var(--red-50) !important;
}
.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}
.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}
.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}
.hover\:bg-red-400:hover {
  background-color: var(--red-400) !important;
}
.hover\:bg-red-500:hover {
  background-color: var(--red-500) !important;
}
.hover\:bg-red-600:hover {
  background-color: var(--red-600) !important;
}
.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}
.hover\:bg-red-800:hover {
  background-color: var(--red-800) !important;
}
.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-50:active {
  background-color: var(--red-50) !important;
}
.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}
.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}
.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}
.active\:bg-red-400:active {
  background-color: var(--red-400) !important;
}
.active\:bg-red-500:active {
  background-color: var(--red-500) !important;
}
.active\:bg-red-600:active {
  background-color: var(--red-600) !important;
}
.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}
.active\:bg-red-800:active {
  background-color: var(--red-800) !important;
}
.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.bg-primary-50 {
  background-color: var(--primary-50) !important;
}
.bg-primary-100 {
  background-color: var(--primary-100) !important;
}
.bg-primary-200 {
  background-color: var(--primary-200) !important;
}
.bg-primary-300 {
  background-color: var(--primary-300) !important;
}
.bg-primary-400 {
  background-color: var(--primary-400) !important;
}
.bg-primary-500 {
  background-color: var(--primary-500) !important;
}
.bg-primary-600 {
  background-color: var(--primary-600) !important;
}
.bg-primary-700 {
  background-color: var(--primary-700) !important;
}
.bg-primary-800 {
  background-color: var(--primary-800) !important;
}
.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.focus\:bg-primary-50:focus {
  background-color: var(--primary-50) !important;
}
.focus\:bg-primary-100:focus {
  background-color: var(--primary-100) !important;
}
.focus\:bg-primary-200:focus {
  background-color: var(--primary-200) !important;
}
.focus\:bg-primary-300:focus {
  background-color: var(--primary-300) !important;
}
.focus\:bg-primary-400:focus {
  background-color: var(--primary-400) !important;
}
.focus\:bg-primary-500:focus {
  background-color: var(--primary-500) !important;
}
.focus\:bg-primary-600:focus {
  background-color: var(--primary-600) !important;
}
.focus\:bg-primary-700:focus {
  background-color: var(--primary-700) !important;
}
.focus\:bg-primary-800:focus {
  background-color: var(--primary-800) !important;
}
.focus\:bg-primary-900:focus {
  background-color: var(--primary-900) !important;
}

.hover\:bg-primary-50:hover {
  background-color: var(--primary-50) !important;
}
.hover\:bg-primary-100:hover {
  background-color: var(--primary-100) !important;
}
.hover\:bg-primary-200:hover {
  background-color: var(--primary-200) !important;
}
.hover\:bg-primary-300:hover {
  background-color: var(--primary-300) !important;
}
.hover\:bg-primary-400:hover {
  background-color: var(--primary-400) !important;
}
.hover\:bg-primary-500:hover {
  background-color: var(--primary-500) !important;
}
.hover\:bg-primary-600:hover {
  background-color: var(--primary-600) !important;
}
.hover\:bg-primary-700:hover {
  background-color: var(--primary-700) !important;
}
.hover\:bg-primary-800:hover {
  background-color: var(--primary-800) !important;
}
.hover\:bg-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.active\:bg-primary-50:active {
  background-color: var(--primary-50) !important;
}
.active\:bg-primary-100:active {
  background-color: var(--primary-100) !important;
}
.active\:bg-primary-200:active {
  background-color: var(--primary-200) !important;
}
.active\:bg-primary-300:active {
  background-color: var(--primary-300) !important;
}
.active\:bg-primary-400:active {
  background-color: var(--primary-400) !important;
}
.active\:bg-primary-500:active {
  background-color: var(--primary-500) !important;
}
.active\:bg-primary-600:active {
  background-color: var(--primary-600) !important;
}
.active\:bg-primary-700:active {
  background-color: var(--primary-700) !important;
}
.active\:bg-primary-800:active {
  background-color: var(--primary-800) !important;
}
.active\:bg-primary-900:active {
  background-color: var(--primary-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}
.border-blue-100 {
  border-color: var(--blue-100) !important;
}
.border-blue-200 {
  border-color: var(--blue-200) !important;
}
.border-blue-300 {
  border-color: var(--blue-300) !important;
}
.border-blue-400 {
  border-color: var(--blue-400) !important;
}
.border-blue-500 {
  border-color: var(--blue-500) !important;
}
.border-blue-600 {
  border-color: var(--blue-600) !important;
}
.border-blue-700 {
  border-color: var(--blue-700) !important;
}
.border-blue-800 {
  border-color: var(--blue-800) !important;
}
.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}
.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}
.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}
.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}
.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}
.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}
.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}
.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}
.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}
.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}
.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}
.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}
.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}
.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}
.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}
.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}
.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}
.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}
.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}
.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}
.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}
.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}
.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}
.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}
.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}
.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}
.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}
.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}
.border-green-100 {
  border-color: var(--green-100) !important;
}
.border-green-200 {
  border-color: var(--green-200) !important;
}
.border-green-300 {
  border-color: var(--green-300) !important;
}
.border-green-400 {
  border-color: var(--green-400) !important;
}
.border-green-500 {
  border-color: var(--green-500) !important;
}
.border-green-600 {
  border-color: var(--green-600) !important;
}
.border-green-700 {
  border-color: var(--green-700) !important;
}
.border-green-800 {
  border-color: var(--green-800) !important;
}
.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}
.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}
.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}
.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}
.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}
.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}
.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}
.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}
.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}
.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}
.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}
.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}
.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}
.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}
.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}
.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}
.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}
.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}
.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}
.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}
.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}
.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}
.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}
.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}
.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}
.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}
.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}
.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}
.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}
.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}
.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}
.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}
.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}
.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}
.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}
.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}
.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}
.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}
.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}
.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}
.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}
.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}
.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}
.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}
.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}
.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}
.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}
.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}
.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}
.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}
.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}
.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}
.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}
.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}
.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}
.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}
.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}
.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}
.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}
.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}
.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}
.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}
.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}
.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}
.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}
.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}
.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}
.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}
.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}
.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}
.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}
.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}
.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}
.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}
.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}
.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}
.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}
.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}
.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}
.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}
.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}
.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}
.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}
.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}
.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}
.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}
.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}
.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}
.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}
.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}
.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}
.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}
.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}
.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}
.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}
.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}
.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}
.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}
.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}
.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}
.border-pink-100 {
  border-color: var(--pink-100) !important;
}
.border-pink-200 {
  border-color: var(--pink-200) !important;
}
.border-pink-300 {
  border-color: var(--pink-300) !important;
}
.border-pink-400 {
  border-color: var(--pink-400) !important;
}
.border-pink-500 {
  border-color: var(--pink-500) !important;
}
.border-pink-600 {
  border-color: var(--pink-600) !important;
}
.border-pink-700 {
  border-color: var(--pink-700) !important;
}
.border-pink-800 {
  border-color: var(--pink-800) !important;
}
.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}
.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}
.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}
.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}
.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}
.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}
.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}
.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}
.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}
.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}
.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}
.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}
.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}
.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}
.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}
.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}
.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}
.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}
.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}
.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}
.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}
.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}
.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}
.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}
.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}
.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}
.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}
.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}
.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}
.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}
.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}
.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}
.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}
.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}
.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}
.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}
.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}
.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}
.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}
.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}
.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}
.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}
.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}
.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}
.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}
.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}
.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}
.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}
.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}
.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}
.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}
.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}
.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}
.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}
.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}
.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}
.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}
.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}
.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}
.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}
.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}
.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}
.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}
.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}
.border-teal-100 {
  border-color: var(--teal-100) !important;
}
.border-teal-200 {
  border-color: var(--teal-200) !important;
}
.border-teal-300 {
  border-color: var(--teal-300) !important;
}
.border-teal-400 {
  border-color: var(--teal-400) !important;
}
.border-teal-500 {
  border-color: var(--teal-500) !important;
}
.border-teal-600 {
  border-color: var(--teal-600) !important;
}
.border-teal-700 {
  border-color: var(--teal-700) !important;
}
.border-teal-800 {
  border-color: var(--teal-800) !important;
}
.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}
.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}
.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}
.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}
.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}
.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}
.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}
.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}
.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}
.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}
.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}
.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}
.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}
.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}
.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}
.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}
.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}
.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}
.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}
.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}
.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}
.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}
.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}
.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}
.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}
.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}
.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}
.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}
.border-orange-100 {
  border-color: var(--orange-100) !important;
}
.border-orange-200 {
  border-color: var(--orange-200) !important;
}
.border-orange-300 {
  border-color: var(--orange-300) !important;
}
.border-orange-400 {
  border-color: var(--orange-400) !important;
}
.border-orange-500 {
  border-color: var(--orange-500) !important;
}
.border-orange-600 {
  border-color: var(--orange-600) !important;
}
.border-orange-700 {
  border-color: var(--orange-700) !important;
}
.border-orange-800 {
  border-color: var(--orange-800) !important;
}
.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}
.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}
.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}
.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}
.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}
.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}
.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}
.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}
.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}
.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}
.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}
.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}
.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}
.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}
.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}
.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}
.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}
.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}
.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}
.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}
.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}
.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}
.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}
.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}
.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}
.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}
.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}
.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}
.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}
.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}
.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}
.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}
.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}
.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}
.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}
.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}
.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}
.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}
.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}
.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}
.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}
.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}
.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}
.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}
.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}
.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}
.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}
.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}
.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}
.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}
.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}
.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}
.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}
.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}
.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}
.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}
.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}
.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}
.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}
.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}
.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}
.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}
.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}
.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}
.border-purple-100 {
  border-color: var(--purple-100) !important;
}
.border-purple-200 {
  border-color: var(--purple-200) !important;
}
.border-purple-300 {
  border-color: var(--purple-300) !important;
}
.border-purple-400 {
  border-color: var(--purple-400) !important;
}
.border-purple-500 {
  border-color: var(--purple-500) !important;
}
.border-purple-600 {
  border-color: var(--purple-600) !important;
}
.border-purple-700 {
  border-color: var(--purple-700) !important;
}
.border-purple-800 {
  border-color: var(--purple-800) !important;
}
.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}
.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}
.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}
.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}
.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}
.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}
.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}
.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}
.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}
.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}
.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}
.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}
.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}
.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}
.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}
.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}
.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}
.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}
.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}
.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}
.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}
.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}
.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}
.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}
.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}
.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}
.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}
.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}
.border-gray-100 {
  border-color: var(--gray-100) !important;
}
.border-gray-200 {
  border-color: var(--gray-200) !important;
}
.border-gray-300 {
  border-color: var(--gray-300) !important;
}
.border-gray-400 {
  border-color: var(--gray-400) !important;
}
.border-gray-500 {
  border-color: var(--gray-500) !important;
}
.border-gray-600 {
  border-color: var(--gray-600) !important;
}
.border-gray-700 {
  border-color: var(--gray-700) !important;
}
.border-gray-800 {
  border-color: var(--gray-800) !important;
}
.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}
.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}
.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}
.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}
.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}
.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}
.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}
.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}
.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}
.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}
.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}
.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}
.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}
.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}
.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}
.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}
.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}
.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}
.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}
.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}
.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}
.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}
.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}
.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}
.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}
.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}
.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}
.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.border-red-50 {
  border-color: var(--red-50) !important;
}
.border-red-100 {
  border-color: var(--red-100) !important;
}
.border-red-200 {
  border-color: var(--red-200) !important;
}
.border-red-300 {
  border-color: var(--red-300) !important;
}
.border-red-400 {
  border-color: var(--red-400) !important;
}
.border-red-500 {
  border-color: var(--red-500) !important;
}
.border-red-600 {
  border-color: var(--red-600) !important;
}
.border-red-700 {
  border-color: var(--red-700) !important;
}
.border-red-800 {
  border-color: var(--red-800) !important;
}
.border-red-900 {
  border-color: var(--red-900) !important;
}

.focus\:border-red-50:focus {
  border-color: var(--red-50) !important;
}
.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}
.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}
.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}
.focus\:border-red-400:focus {
  border-color: var(--red-400) !important;
}
.focus\:border-red-500:focus {
  border-color: var(--red-500) !important;
}
.focus\:border-red-600:focus {
  border-color: var(--red-600) !important;
}
.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}
.focus\:border-red-800:focus {
  border-color: var(--red-800) !important;
}
.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-50:hover {
  border-color: var(--red-50) !important;
}
.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}
.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}
.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}
.hover\:border-red-400:hover {
  border-color: var(--red-400) !important;
}
.hover\:border-red-500:hover {
  border-color: var(--red-500) !important;
}
.hover\:border-red-600:hover {
  border-color: var(--red-600) !important;
}
.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}
.hover\:border-red-800:hover {
  border-color: var(--red-800) !important;
}
.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-50:active {
  border-color: var(--red-50) !important;
}
.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}
.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}
.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}
.active\:border-red-400:active {
  border-color: var(--red-400) !important;
}
.active\:border-red-500:active {
  border-color: var(--red-500) !important;
}
.active\:border-red-600:active {
  border-color: var(--red-600) !important;
}
.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}
.active\:border-red-800:active {
  border-color: var(--red-800) !important;
}
.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}
.border-primary-100 {
  border-color: var(--primary-100) !important;
}
.border-primary-200 {
  border-color: var(--primary-200) !important;
}
.border-primary-300 {
  border-color: var(--primary-300) !important;
}
.border-primary-400 {
  border-color: var(--primary-400) !important;
}
.border-primary-500 {
  border-color: var(--primary-500) !important;
}
.border-primary-600 {
  border-color: var(--primary-600) !important;
}
.border-primary-700 {
  border-color: var(--primary-700) !important;
}
.border-primary-800 {
  border-color: var(--primary-800) !important;
}
.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.focus\:border-primary-50:focus {
  border-color: var(--primary-50) !important;
}
.focus\:border-primary-100:focus {
  border-color: var(--primary-100) !important;
}
.focus\:border-primary-200:focus {
  border-color: var(--primary-200) !important;
}
.focus\:border-primary-300:focus {
  border-color: var(--primary-300) !important;
}
.focus\:border-primary-400:focus {
  border-color: var(--primary-400) !important;
}
.focus\:border-primary-500:focus {
  border-color: var(--primary-500) !important;
}
.focus\:border-primary-600:focus {
  border-color: var(--primary-600) !important;
}
.focus\:border-primary-700:focus {
  border-color: var(--primary-700) !important;
}
.focus\:border-primary-800:focus {
  border-color: var(--primary-800) !important;
}
.focus\:border-primary-900:focus {
  border-color: var(--primary-900) !important;
}

.hover\:border-primary-50:hover {
  border-color: var(--primary-50) !important;
}
.hover\:border-primary-100:hover {
  border-color: var(--primary-100) !important;
}
.hover\:border-primary-200:hover {
  border-color: var(--primary-200) !important;
}
.hover\:border-primary-300:hover {
  border-color: var(--primary-300) !important;
}
.hover\:border-primary-400:hover {
  border-color: var(--primary-400) !important;
}
.hover\:border-primary-500:hover {
  border-color: var(--primary-500) !important;
}
.hover\:border-primary-600:hover {
  border-color: var(--primary-600) !important;
}
.hover\:border-primary-700:hover {
  border-color: var(--primary-700) !important;
}
.hover\:border-primary-800:hover {
  border-color: var(--primary-800) !important;
}
.hover\:border-primary-900:hover {
  border-color: var(--primary-900) !important;
}

.active\:border-primary-50:active {
  border-color: var(--primary-50) !important;
}
.active\:border-primary-100:active {
  border-color: var(--primary-100) !important;
}
.active\:border-primary-200:active {
  border-color: var(--primary-200) !important;
}
.active\:border-primary-300:active {
  border-color: var(--primary-300) !important;
}
.active\:border-primary-400:active {
  border-color: var(--primary-400) !important;
}
.active\:border-primary-500:active {
  border-color: var(--primary-500) !important;
}
.active\:border-primary-600:active {
  border-color: var(--primary-600) !important;
}
.active\:border-primary-700:active {
  border-color: var(--primary-700) !important;
}
.active\:border-primary-800:active {
  border-color: var(--primary-800) !important;
}
.active\:border-primary-900:active {
  border-color: var(--primary-900) !important;
}

.bg-white-alpha-10 {
  background-color: rgba(255,255,255,0.1) !important;
}
.bg-white-alpha-20 {
  background-color: rgba(255,255,255,0.2) !important;
}
.bg-white-alpha-30 {
  background-color: rgba(255,255,255,0.3) !important;
}
.bg-white-alpha-40 {
  background-color: rgba(255,255,255,0.4) !important;
}
.bg-white-alpha-50 {
  background-color: rgba(255,255,255,0.5) !important;
}
.bg-white-alpha-60 {
  background-color: rgba(255,255,255,0.6) !important;
}
.bg-white-alpha-70 {
  background-color: rgba(255,255,255,0.7) !important;
}
.bg-white-alpha-80 {
  background-color: rgba(255,255,255,0.8) !important;
}
.bg-white-alpha-90 {
  background-color: rgba(255,255,255,0.9) !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: rgba(255,255,255,0.1) !important;
}
.hover\:bg-white-alpha-20:hover {
  background-color: rgba(255,255,255,0.2) !important;
}
.hover\:bg-white-alpha-30:hover {
  background-color: rgba(255,255,255,0.3) !important;
}
.hover\:bg-white-alpha-40:hover {
  background-color: rgba(255,255,255,0.4) !important;
}
.hover\:bg-white-alpha-50:hover {
  background-color: rgba(255,255,255,0.5) !important;
}
.hover\:bg-white-alpha-60:hover {
  background-color: rgba(255,255,255,0.6) !important;
}
.hover\:bg-white-alpha-70:hover {
  background-color: rgba(255,255,255,0.7) !important;
}
.hover\:bg-white-alpha-80:hover {
  background-color: rgba(255,255,255,0.8) !important;
}
.hover\:bg-white-alpha-90:hover {
  background-color: rgba(255,255,255,0.9) !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: rgba(255,255,255,0.1) !important;
}
.focus\:bg-white-alpha-20:focus {
  background-color: rgba(255,255,255,0.2) !important;
}
.focus\:bg-white-alpha-30:focus {
  background-color: rgba(255,255,255,0.3) !important;
}
.focus\:bg-white-alpha-40:focus {
  background-color: rgba(255,255,255,0.4) !important;
}
.focus\:bg-white-alpha-50:focus {
  background-color: rgba(255,255,255,0.5) !important;
}
.focus\:bg-white-alpha-60:focus {
  background-color: rgba(255,255,255,0.6) !important;
}
.focus\:bg-white-alpha-70:focus {
  background-color: rgba(255,255,255,0.7) !important;
}
.focus\:bg-white-alpha-80:focus {
  background-color: rgba(255,255,255,0.8) !important;
}
.focus\:bg-white-alpha-90:focus {
  background-color: rgba(255,255,255,0.9) !important;
}

.active\:bg-white-alpha-10:active {
  background-color: rgba(255,255,255,0.1) !important;
}
.active\:bg-white-alpha-20:active {
  background-color: rgba(255,255,255,0.2) !important;
}
.active\:bg-white-alpha-30:active {
  background-color: rgba(255,255,255,0.3) !important;
}
.active\:bg-white-alpha-40:active {
  background-color: rgba(255,255,255,0.4) !important;
}
.active\:bg-white-alpha-50:active {
  background-color: rgba(255,255,255,0.5) !important;
}
.active\:bg-white-alpha-60:active {
  background-color: rgba(255,255,255,0.6) !important;
}
.active\:bg-white-alpha-70:active {
  background-color: rgba(255,255,255,0.7) !important;
}
.active\:bg-white-alpha-80:active {
  background-color: rgba(255,255,255,0.8) !important;
}
.active\:bg-white-alpha-90:active {
  background-color: rgba(255,255,255,0.9) !important;
}

.bg-black-alpha-10 {
  background-color: rgba(0,0,0,0.1) !important;
}
.bg-black-alpha-20 {
  background-color: rgba(0,0,0,0.2) !important;
}
.bg-black-alpha-30 {
  background-color: rgba(0,0,0,0.3) !important;
}
.bg-black-alpha-40 {
  background-color: rgba(0,0,0,0.4) !important;
}
.bg-black-alpha-50 {
  background-color: rgba(0,0,0,0.5) !important;
}
.bg-black-alpha-60 {
  background-color: rgba(0,0,0,0.6) !important;
}
.bg-black-alpha-70 {
  background-color: rgba(0,0,0,0.7) !important;
}
.bg-black-alpha-80 {
  background-color: rgba(0,0,0,0.8) !important;
}
.bg-black-alpha-90 {
  background-color: rgba(0,0,0,0.9) !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: rgba(0,0,0,0.1) !important;
}
.hover\:bg-black-alpha-20:hover {
  background-color: rgba(0,0,0,0.2) !important;
}
.hover\:bg-black-alpha-30:hover {
  background-color: rgba(0,0,0,0.3) !important;
}
.hover\:bg-black-alpha-40:hover {
  background-color: rgba(0,0,0,0.4) !important;
}
.hover\:bg-black-alpha-50:hover {
  background-color: rgba(0,0,0,0.5) !important;
}
.hover\:bg-black-alpha-60:hover {
  background-color: rgba(0,0,0,0.6) !important;
}
.hover\:bg-black-alpha-70:hover {
  background-color: rgba(0,0,0,0.7) !important;
}
.hover\:bg-black-alpha-80:hover {
  background-color: rgba(0,0,0,0.8) !important;
}
.hover\:bg-black-alpha-90:hover {
  background-color: rgba(0,0,0,0.9) !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: rgba(0,0,0,0.1) !important;
}
.focus\:bg-black-alpha-20:focus {
  background-color: rgba(0,0,0,0.2) !important;
}
.focus\:bg-black-alpha-30:focus {
  background-color: rgba(0,0,0,0.3) !important;
}
.focus\:bg-black-alpha-40:focus {
  background-color: rgba(0,0,0,0.4) !important;
}
.focus\:bg-black-alpha-50:focus {
  background-color: rgba(0,0,0,0.5) !important;
}
.focus\:bg-black-alpha-60:focus {
  background-color: rgba(0,0,0,0.6) !important;
}
.focus\:bg-black-alpha-70:focus {
  background-color: rgba(0,0,0,0.7) !important;
}
.focus\:bg-black-alpha-80:focus {
  background-color: rgba(0,0,0,0.8) !important;
}
.focus\:bg-black-alpha-90:focus {
  background-color: rgba(0,0,0,0.9) !important;
}

.active\:bg-black-alpha-10:active {
  background-color: rgba(0,0,0,0.1) !important;
}
.active\:bg-black-alpha-20:active {
  background-color: rgba(0,0,0,0.2) !important;
}
.active\:bg-black-alpha-30:active {
  background-color: rgba(0,0,0,0.3) !important;
}
.active\:bg-black-alpha-40:active {
  background-color: rgba(0,0,0,0.4) !important;
}
.active\:bg-black-alpha-50:active {
  background-color: rgba(0,0,0,0.5) !important;
}
.active\:bg-black-alpha-60:active {
  background-color: rgba(0,0,0,0.6) !important;
}
.active\:bg-black-alpha-70:active {
  background-color: rgba(0,0,0,0.7) !important;
}
.active\:bg-black-alpha-80:active {
  background-color: rgba(0,0,0,0.8) !important;
}
.active\:bg-black-alpha-90:active {
  background-color: rgba(0,0,0,0.9) !important;
}

.border-white-alpha-10 {
  border-color: rgba(255,255,255,0.1) !important;
}
.border-white-alpha-20 {
  border-color: rgba(255,255,255,0.2) !important;
}
.border-white-alpha-30 {
  border-color: rgba(255,255,255,0.3) !important;
}
.border-white-alpha-40 {
  border-color: rgba(255,255,255,0.4) !important;
}
.border-white-alpha-50 {
  border-color: rgba(255,255,255,0.5) !important;
}
.border-white-alpha-60 {
  border-color: rgba(255,255,255,0.6) !important;
}
.border-white-alpha-70 {
  border-color: rgba(255,255,255,0.7) !important;
}
.border-white-alpha-80 {
  border-color: rgba(255,255,255,0.8) !important;
}
.border-white-alpha-90 {
  border-color: rgba(255,255,255,0.9) !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: rgba(255,255,255,0.1) !important;
}
.hover\:border-white-alpha-20:hover {
  border-color: rgba(255,255,255,0.2) !important;
}
.hover\:border-white-alpha-30:hover {
  border-color: rgba(255,255,255,0.3) !important;
}
.hover\:border-white-alpha-40:hover {
  border-color: rgba(255,255,255,0.4) !important;
}
.hover\:border-white-alpha-50:hover {
  border-color: rgba(255,255,255,0.5) !important;
}
.hover\:border-white-alpha-60:hover {
  border-color: rgba(255,255,255,0.6) !important;
}
.hover\:border-white-alpha-70:hover {
  border-color: rgba(255,255,255,0.7) !important;
}
.hover\:border-white-alpha-80:hover {
  border-color: rgba(255,255,255,0.8) !important;
}
.hover\:border-white-alpha-90:hover {
  border-color: rgba(255,255,255,0.9) !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: rgba(255,255,255,0.1) !important;
}
.focus\:border-white-alpha-20:focus {
  border-color: rgba(255,255,255,0.2) !important;
}
.focus\:border-white-alpha-30:focus {
  border-color: rgba(255,255,255,0.3) !important;
}
.focus\:border-white-alpha-40:focus {
  border-color: rgba(255,255,255,0.4) !important;
}
.focus\:border-white-alpha-50:focus {
  border-color: rgba(255,255,255,0.5) !important;
}
.focus\:border-white-alpha-60:focus {
  border-color: rgba(255,255,255,0.6) !important;
}
.focus\:border-white-alpha-70:focus {
  border-color: rgba(255,255,255,0.7) !important;
}
.focus\:border-white-alpha-80:focus {
  border-color: rgba(255,255,255,0.8) !important;
}
.focus\:border-white-alpha-90:focus {
  border-color: rgba(255,255,255,0.9) !important;
}

.active\:border-white-alpha-10:active {
  border-color: rgba(255,255,255,0.1) !important;
}
.active\:border-white-alpha-20:active {
  border-color: rgba(255,255,255,0.2) !important;
}
.active\:border-white-alpha-30:active {
  border-color: rgba(255,255,255,0.3) !important;
}
.active\:border-white-alpha-40:active {
  border-color: rgba(255,255,255,0.4) !important;
}
.active\:border-white-alpha-50:active {
  border-color: rgba(255,255,255,0.5) !important;
}
.active\:border-white-alpha-60:active {
  border-color: rgba(255,255,255,0.6) !important;
}
.active\:border-white-alpha-70:active {
  border-color: rgba(255,255,255,0.7) !important;
}
.active\:border-white-alpha-80:active {
  border-color: rgba(255,255,255,0.8) !important;
}
.active\:border-white-alpha-90:active {
  border-color: rgba(255,255,255,0.9) !important;
}

.border-black-alpha-10 {
  border-color: rgba(0,0,0,0.1) !important;
}
.border-black-alpha-20 {
  border-color: rgba(0,0,0,0.2) !important;
}
.border-black-alpha-30 {
  border-color: rgba(0,0,0,0.3) !important;
}
.border-black-alpha-40 {
  border-color: rgba(0,0,0,0.4) !important;
}
.border-black-alpha-50 {
  border-color: rgba(0,0,0,0.5) !important;
}
.border-black-alpha-60 {
  border-color: rgba(0,0,0,0.6) !important;
}
.border-black-alpha-70 {
  border-color: rgba(0,0,0,0.7) !important;
}
.border-black-alpha-80 {
  border-color: rgba(0,0,0,0.8) !important;
}
.border-black-alpha-90 {
  border-color: rgba(0,0,0,0.9) !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: rgba(0,0,0,0.1) !important;
}
.hover\:border-black-alpha-20:hover {
  border-color: rgba(0,0,0,0.2) !important;
}
.hover\:border-black-alpha-30:hover {
  border-color: rgba(0,0,0,0.3) !important;
}
.hover\:border-black-alpha-40:hover {
  border-color: rgba(0,0,0,0.4) !important;
}
.hover\:border-black-alpha-50:hover {
  border-color: rgba(0,0,0,0.5) !important;
}
.hover\:border-black-alpha-60:hover {
  border-color: rgba(0,0,0,0.6) !important;
}
.hover\:border-black-alpha-70:hover {
  border-color: rgba(0,0,0,0.7) !important;
}
.hover\:border-black-alpha-80:hover {
  border-color: rgba(0,0,0,0.8) !important;
}
.hover\:border-black-alpha-90:hover {
  border-color: rgba(0,0,0,0.9) !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: rgba(0,0,0,0.1) !important;
}
.focus\:border-black-alpha-20:focus {
  border-color: rgba(0,0,0,0.2) !important;
}
.focus\:border-black-alpha-30:focus {
  border-color: rgba(0,0,0,0.3) !important;
}
.focus\:border-black-alpha-40:focus {
  border-color: rgba(0,0,0,0.4) !important;
}
.focus\:border-black-alpha-50:focus {
  border-color: rgba(0,0,0,0.5) !important;
}
.focus\:border-black-alpha-60:focus {
  border-color: rgba(0,0,0,0.6) !important;
}
.focus\:border-black-alpha-70:focus {
  border-color: rgba(0,0,0,0.7) !important;
}
.focus\:border-black-alpha-80:focus {
  border-color: rgba(0,0,0,0.8) !important;
}
.focus\:border-black-alpha-90:focus {
  border-color: rgba(0,0,0,0.9) !important;
}

.active\:border-black-alpha-10:active {
  border-color: rgba(0,0,0,0.1) !important;
}
.active\:border-black-alpha-20:active {
  border-color: rgba(0,0,0,0.2) !important;
}
.active\:border-black-alpha-30:active {
  border-color: rgba(0,0,0,0.3) !important;
}
.active\:border-black-alpha-40:active {
  border-color: rgba(0,0,0,0.4) !important;
}
.active\:border-black-alpha-50:active {
  border-color: rgba(0,0,0,0.5) !important;
}
.active\:border-black-alpha-60:active {
  border-color: rgba(0,0,0,0.6) !important;
}
.active\:border-black-alpha-70:active {
  border-color: rgba(0,0,0,0.7) !important;
}
.active\:border-black-alpha-80:active {
  border-color: rgba(0,0,0,0.8) !important;
}
.active\:border-black-alpha-90:active {
  border-color: rgba(0,0,0,0.9) !important;
}

.text-white-alpha-10 {
  color: rgba(255,255,255,0.1) !important;
}
.text-white-alpha-20 {
  color: rgba(255,255,255,0.2) !important;
}
.text-white-alpha-30 {
  color: rgba(255,255,255,0.3) !important;
}
.text-white-alpha-40 {
  color: rgba(255,255,255,0.4) !important;
}
.text-white-alpha-50 {
  color: rgba(255,255,255,0.5) !important;
}
.text-white-alpha-60 {
  color: rgba(255,255,255,0.6) !important;
}
.text-white-alpha-70 {
  color: rgba(255,255,255,0.7) !important;
}
.text-white-alpha-80 {
  color: rgba(255,255,255,0.8) !important;
}
.text-white-alpha-90 {
  color: rgba(255,255,255,0.9) !important;
}

.hover\:text-white-alpha-10:hover {
  color: rgba(255,255,255,0.1) !important;
}
.hover\:text-white-alpha-20:hover {
  color: rgba(255,255,255,0.2) !important;
}
.hover\:text-white-alpha-30:hover {
  color: rgba(255,255,255,0.3) !important;
}
.hover\:text-white-alpha-40:hover {
  color: rgba(255,255,255,0.4) !important;
}
.hover\:text-white-alpha-50:hover {
  color: rgba(255,255,255,0.5) !important;
}
.hover\:text-white-alpha-60:hover {
  color: rgba(255,255,255,0.6) !important;
}
.hover\:text-white-alpha-70:hover {
  color: rgba(255,255,255,0.7) !important;
}
.hover\:text-white-alpha-80:hover {
  color: rgba(255,255,255,0.8) !important;
}
.hover\:text-white-alpha-90:hover {
  color: rgba(255,255,255,0.9) !important;
}

.focus\:text-white-alpha-10:focus {
  color: rgba(255,255,255,0.1) !important;
}
.focus\:text-white-alpha-20:focus {
  color: rgba(255,255,255,0.2) !important;
}
.focus\:text-white-alpha-30:focus {
  color: rgba(255,255,255,0.3) !important;
}
.focus\:text-white-alpha-40:focus {
  color: rgba(255,255,255,0.4) !important;
}
.focus\:text-white-alpha-50:focus {
  color: rgba(255,255,255,0.5) !important;
}
.focus\:text-white-alpha-60:focus {
  color: rgba(255,255,255,0.6) !important;
}
.focus\:text-white-alpha-70:focus {
  color: rgba(255,255,255,0.7) !important;
}
.focus\:text-white-alpha-80:focus {
  color: rgba(255,255,255,0.8) !important;
}
.focus\:text-white-alpha-90:focus {
  color: rgba(255,255,255,0.9) !important;
}

.active\:text-white-alpha-10:active {
  color: rgba(255,255,255,0.1) !important;
}
.active\:text-white-alpha-20:active {
  color: rgba(255,255,255,0.2) !important;
}
.active\:text-white-alpha-30:active {
  color: rgba(255,255,255,0.3) !important;
}
.active\:text-white-alpha-40:active {
  color: rgba(255,255,255,0.4) !important;
}
.active\:text-white-alpha-50:active {
  color: rgba(255,255,255,0.5) !important;
}
.active\:text-white-alpha-60:active {
  color: rgba(255,255,255,0.6) !important;
}
.active\:text-white-alpha-70:active {
  color: rgba(255,255,255,0.7) !important;
}
.active\:text-white-alpha-80:active {
  color: rgba(255,255,255,0.8) !important;
}
.active\:text-white-alpha-90:active {
  color: rgba(255,255,255,0.9) !important;
}

.text-black-alpha-10 {
  color: rgba(0,0,0,0.1) !important;
}
.text-black-alpha-20 {
  color: rgba(0,0,0,0.2) !important;
}
.text-black-alpha-30 {
  color: rgba(0,0,0,0.3) !important;
}
.text-black-alpha-40 {
  color: rgba(0,0,0,0.4) !important;
}
.text-black-alpha-50 {
  color: rgba(0,0,0,0.5) !important;
}
.text-black-alpha-60 {
  color: rgba(0,0,0,0.6) !important;
}
.text-black-alpha-70 {
  color: rgba(0,0,0,0.7) !important;
}
.text-black-alpha-80 {
  color: rgba(0,0,0,0.8) !important;
}
.text-black-alpha-90 {
  color: rgba(0,0,0,0.9) !important;
}

.hover\:text-black-alpha-10:hover {
  color: rgba(0,0,0,0.1) !important;
}
.hover\:text-black-alpha-20:hover {
  color: rgba(0,0,0,0.2) !important;
}
.hover\:text-black-alpha-30:hover {
  color: rgba(0,0,0,0.3) !important;
}
.hover\:text-black-alpha-40:hover {
  color: rgba(0,0,0,0.4) !important;
}
.hover\:text-black-alpha-50:hover {
  color: rgba(0,0,0,0.5) !important;
}
.hover\:text-black-alpha-60:hover {
  color: rgba(0,0,0,0.6) !important;
}
.hover\:text-black-alpha-70:hover {
  color: rgba(0,0,0,0.7) !important;
}
.hover\:text-black-alpha-80:hover {
  color: rgba(0,0,0,0.8) !important;
}
.hover\:text-black-alpha-90:hover {
  color: rgba(0,0,0,0.9) !important;
}

.focus\:text-black-alpha-10:focus {
  color: rgba(0,0,0,0.1) !important;
}
.focus\:text-black-alpha-20:focus {
  color: rgba(0,0,0,0.2) !important;
}
.focus\:text-black-alpha-30:focus {
  color: rgba(0,0,0,0.3) !important;
}
.focus\:text-black-alpha-40:focus {
  color: rgba(0,0,0,0.4) !important;
}
.focus\:text-black-alpha-50:focus {
  color: rgba(0,0,0,0.5) !important;
}
.focus\:text-black-alpha-60:focus {
  color: rgba(0,0,0,0.6) !important;
}
.focus\:text-black-alpha-70:focus {
  color: rgba(0,0,0,0.7) !important;
}
.focus\:text-black-alpha-80:focus {
  color: rgba(0,0,0,0.8) !important;
}
.focus\:text-black-alpha-90:focus {
  color: rgba(0,0,0,0.9) !important;
}

.active\:text-black-alpha-10:active {
  color: rgba(0,0,0,0.1) !important;
}
.active\:text-black-alpha-20:active {
  color: rgba(0,0,0,0.2) !important;
}
.active\:text-black-alpha-30:active {
  color: rgba(0,0,0,0.3) !important;
}
.active\:text-black-alpha-40:active {
  color: rgba(0,0,0,0.4) !important;
}
.active\:text-black-alpha-50:active {
  color: rgba(0,0,0,0.5) !important;
}
.active\:text-black-alpha-60:active {
  color: rgba(0,0,0,0.6) !important;
}
.active\:text-black-alpha-70:active {
  color: rgba(0,0,0,0.7) !important;
}
.active\:text-black-alpha-80:active {
  color: rgba(0,0,0,0.8) !important;
}
.active\:text-black-alpha-90:active {
  color: rgba(0,0,0,0.9) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus {
  color: var(--primary-color) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.hover\:bg-primary:hover {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.hover\:bg-primary-reverse:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus {
  background-color: #ffffff !important;
}

.hover\:bg-white:hover {
  background-color: #ffffff !important;
}

.active\:bg-white:active {
  background-color: #ffffff !important;
}

.focus\:border-primary:focus {
  border-color: var(--primary-color) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary-color) !important;
}

.active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus {
  color: #ffffff !important;
}

.hover\:text-white:hover {
  color: #ffffff !important;
}

.active\:text-white:active {
  color: #ffffff !important;
}

.focus\:border-white:focus {
  border-color: #ffffff !important;
}

.hover\:border-white:hover {
  border-color: #ffffff !important;
}

.active\:border-white:active {
  border-color: #ffffff !important;
}

.focus\:text-color:focus {
  color: var(--text-color) !important;
}

.hover\:text-color:hover {
  color: var(--text-color) !important;
}

.active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus {
  color: var(--text-color-secondary) !important;
}

.hover\:text-color-secondary:hover {
  color: var(--text-color-secondary) !important;
}

.active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus {
  background-color: var(--surface-ground) !important;
}

.hover\:surface-ground:hover {
  background-color: var(--surface-ground) !important;
}

.active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus {
  background-color: var(--surface-section) !important;
}

.hover\:surface-section:hover {
  background-color: var(--surface-section) !important;
}

.active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus {
  background-color: var(--surface-card) !important;
}

.hover\:surface-card:hover {
  background-color: var(--surface-card) !important;
}

.active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus {
  background-color: var(--surface-overlay) !important;
}

.hover\:surface-overlay:hover {
  background-color: var(--surface-overlay) !important;
}

.active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus {
  background-color: var(--surface-hover) !important;
}

.hover\:surface-hover:hover {
  background-color: var(--surface-hover) !important;
}

.active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus {
  border-color: var(--surface-border) !important;
}

.hover\:surface-border:hover {
  border-color: var(--surface-border) !important;
}

.active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }
  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:bg-white {
    background-color: #ffffff !important;
  }
  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .sm\:text-white {
    color: #ffffff !important;
  }
  .sm\:border-white {
    border-color: #ffffff !important;
  }
  .sm\:text-color {
    color: var(--text-color) !important;
  }
  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .sm\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .sm\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .sm\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .sm\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .sm\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .sm\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .sm\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .sm\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .sm\:active\:text-white:active {
    color: #ffffff !important;
  }
  .sm\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .sm\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .sm\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .sm\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .sm\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .sm\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .sm\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .sm\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .sm\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .sm\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .sm\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .sm\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .sm\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .sm\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .sm\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .sm\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .sm\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }
  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:bg-white {
    background-color: #ffffff !important;
  }
  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .md\:text-white {
    color: #ffffff !important;
  }
  .md\:border-white {
    border-color: #ffffff !important;
  }
  .md\:text-color {
    color: var(--text-color) !important;
  }
  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .md\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .md\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .md\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .md\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .md\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .md\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .md\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .md\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .md\:active\:text-white:active {
    color: #ffffff !important;
  }
  .md\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .md\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .md\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .md\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .md\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .md\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .md\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .md\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .md\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .md\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .md\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .md\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .md\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .md\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .md\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .md\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .md\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .md\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .md\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }
  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:bg-white {
    background-color: #ffffff !important;
  }
  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .lg\:text-white {
    color: #ffffff !important;
  }
  .lg\:border-white {
    border-color: #ffffff !important;
  }
  .lg\:text-color {
    color: var(--text-color) !important;
  }
  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .lg\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .lg\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .lg\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .lg\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .lg\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .lg\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .lg\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .lg\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .lg\:active\:text-white:active {
    color: #ffffff !important;
  }
  .lg\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .lg\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .lg\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .lg\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .lg\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .lg\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .lg\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .lg\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .lg\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .lg\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .lg\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .lg\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .lg\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .lg\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .lg\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .lg\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .lg\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }
  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:bg-white {
    background-color: #ffffff !important;
  }
  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xl\:text-white {
    color: #ffffff !important;
  }
  .xl\:border-white {
    border-color: #ffffff !important;
  }
  .xl\:text-color {
    color: var(--text-color) !important;
  }
  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xl\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xl\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xl\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xl\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xl\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xl\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xl\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xl\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xl\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xl\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xl\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xl\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xl\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xl\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xl\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xl\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xl\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xl\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xl\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xl\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xl\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xl\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xl\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xl\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xl\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xl\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
.field {
  margin-bottom: 1rem;
}

.field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.field.grid > label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.field > small {
  margin-top: 0.25rem;
}

.field.grid,
.formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed,
.formgrid.grid .col-fixed,
.field.grid .col,
.formgrid.grid .col,
.field.grid .col-1,
.formgrid.grid .col-1,
.field.grid .col-2,
.formgrid.grid .col-2,
.field.grid .col-3,
.formgrid.grid .col-3,
.field.grid .col-4,
.formgrid.grid .col-4,
.field.grid .col-5,
.formgrid.grid .col-5,
.field.grid .col-6,
.formgrid.grid .col-6,
.field.grid .col-7,
.formgrid.grid .col-7,
.field.grid .col-8,
.formgrid.grid .col-8,
.field.grid .col-9,
.formgrid.grid .col-9,
.field.grid .col-10,
.formgrid.grid .col-10,
.field.grid .col-11,
.formgrid.grid .col-11,
.field.grid .col-12,
.formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.field-checkbox > label,
.field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:inline {
    display: inline !important;
  }
  .sm\:inline-block {
    display: inline-block !important;
  }
  .sm\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .sm\:inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline {
    display: inline !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .md\:inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline {
    display: inline !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .lg\:inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:hidden {
    display: none !important;
  }
  .xl\:block {
    display: block !important;
  }
  .xl\:inline {
    display: inline !important;
  }
  .xl\:inline-block {
    display: inline-block !important;
  }
  .xl\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .xl\:inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-justify {
    text-align: justify !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-justify {
    text-align: justify !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-justify {
    text-align: justify !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-justify {
    text-align: justify !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
}
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }
  .sm\:font-normal {
    font-weight: 400 !important;
  }
  .sm\:font-medium {
    font-weight: 500 !important;
  }
  .sm\:font-semibold {
    font-weight: 600 !important;
  }
  .sm\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:font-light {
    font-weight: 300 !important;
  }
  .md\:font-normal {
    font-weight: 400 !important;
  }
  .md\:font-medium {
    font-weight: 500 !important;
  }
  .md\:font-semibold {
    font-weight: 600 !important;
  }
  .md\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }
  .lg\:font-normal {
    font-weight: 400 !important;
  }
  .lg\:font-medium {
    font-weight: 500 !important;
  }
  .lg\:font-semibold {
    font-weight: 600 !important;
  }
  .lg\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }
  .xl\:font-normal {
    font-weight: 400 !important;
  }
  .xl\:font-medium {
    font-weight: 500 !important;
  }
  .xl\:font-semibold {
    font-weight: 600 !important;
  }
  .xl\:font-bold {
    font-weight: 700 !important;
  }
}
.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-xs {
    font-size: 0.75rem !important;
  }
  .sm\:text-sm {
    font-size: 0.875rem !important;
  }
  .sm\:text-base {
    font-size: 1rem !important;
  }
  .sm\:text-lg {
    font-size: 1.125rem !important;
  }
  .sm\:text-xl {
    font-size: 1.25rem !important;
  }
  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }
  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }
  .sm\:text-4xl {
    font-size: 2rem !important;
  }
  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }
  .sm\:text-6xl {
    font-size: 3rem !important;
  }
  .sm\:text-7xl {
    font-size: 4rem !important;
  }
  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-xs {
    font-size: 0.75rem !important;
  }
  .md\:text-sm {
    font-size: 0.875rem !important;
  }
  .md\:text-base {
    font-size: 1rem !important;
  }
  .md\:text-lg {
    font-size: 1.125rem !important;
  }
  .md\:text-xl {
    font-size: 1.25rem !important;
  }
  .md\:text-2xl {
    font-size: 1.5rem !important;
  }
  .md\:text-3xl {
    font-size: 1.75rem !important;
  }
  .md\:text-4xl {
    font-size: 2rem !important;
  }
  .md\:text-5xl {
    font-size: 2.5rem !important;
  }
  .md\:text-6xl {
    font-size: 3rem !important;
  }
  .md\:text-7xl {
    font-size: 4rem !important;
  }
  .md\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-xs {
    font-size: 0.75rem !important;
  }
  .lg\:text-sm {
    font-size: 0.875rem !important;
  }
  .lg\:text-base {
    font-size: 1rem !important;
  }
  .lg\:text-lg {
    font-size: 1.125rem !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
  }
  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }
  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }
  .lg\:text-4xl {
    font-size: 2rem !important;
  }
  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }
  .lg\:text-6xl {
    font-size: 3rem !important;
  }
  .lg\:text-7xl {
    font-size: 4rem !important;
  }
  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-xs {
    font-size: 0.75rem !important;
  }
  .xl\:text-sm {
    font-size: 0.875rem !important;
  }
  .xl\:text-base {
    font-size: 1rem !important;
  }
  .xl\:text-lg {
    font-size: 1.125rem !important;
  }
  .xl\:text-xl {
    font-size: 1.25rem !important;
  }
  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }
  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }
  .xl\:text-4xl {
    font-size: 2rem !important;
  }
  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }
  .xl\:text-6xl {
    font-size: 3rem !important;
  }
  .xl\:text-7xl {
    font-size: 4rem !important;
  }
  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}
.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (min-width: 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .sm\:vertical-align-top {
    vertical-align: top !important;
  }
  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .md\:vertical-align-top {
    vertical-align: top !important;
  }
  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .lg\:vertical-align-top {
    vertical-align: top !important;
  }
  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xl\:vertical-align-top {
    vertical-align: top !important;
  }
  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}
.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .sm\:flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .sm\:flex-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .sm\:flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .md\:flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .md\:flex-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .md\:flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .lg\:flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .lg\:flex-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .lg\:flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .xl\:flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .xl\:flex-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .xl\:flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .sm\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .md\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .md\:flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .lg\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .xl\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-justify-content: space-evenly !important;
          justify-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .sm\:justify-content-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .sm\:justify-content-evenly {
    -webkit-justify-content: space-evenly !important;
            justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:justify-content-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .md\:justify-content-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .md\:justify-content-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .md\:justify-content-evenly {
    -webkit-justify-content: space-evenly !important;
            justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:justify-content-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .lg\:justify-content-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .lg\:justify-content-evenly {
    -webkit-justify-content: space-evenly !important;
            justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:justify-content-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .xl\:justify-content-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .xl\:justify-content-evenly {
    -webkit-justify-content: space-evenly !important;
            justify-content: space-evenly !important;
  }
}
.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}

.align-content-evenly {
  -webkit-align-content: space-evenly !important;
          align-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .sm\:align-content-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .sm\:align-content-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .sm\:align-content-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .sm\:align-content-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .sm\:align-content-evenly {
    -webkit-align-content: space-evenly !important;
            align-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-content-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .md\:align-content-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .md\:align-content-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .md\:align-content-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .md\:align-content-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .md\:align-content-evenly {
    -webkit-align-content: space-evenly !important;
            align-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-content-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .lg\:align-content-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .lg\:align-content-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .lg\:align-content-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .lg\:align-content-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .lg\:align-content-evenly {
    -webkit-align-content: space-evenly !important;
            align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-content-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .xl\:align-content-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .xl\:align-content-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .xl\:align-content-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .xl\:align-content-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .xl\:align-content-evenly {
    -webkit-align-content: space-evenly !important;
            align-content: space-evenly !important;
  }
}
.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .sm\:align-items-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .sm\:align-items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .sm\:align-items-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .sm\:align-items-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-items-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .md\:align-items-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .md\:align-items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .md\:align-items-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .md\:align-items-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-items-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .lg\:align-items-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .lg\:align-items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .lg\:align-items-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .lg\:align-items-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-items-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .xl\:align-items-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .xl\:align-items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .xl\:align-items-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .xl\:align-items-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
}
.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .sm\:align-self-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .sm\:align-self-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .sm\:align-self-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .sm\:align-self-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
  .sm\:align-self-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-self-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .md\:align-self-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .md\:align-self-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .md\:align-self-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .md\:align-self-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
  .md\:align-self-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-self-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .lg\:align-self-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .lg\:align-self-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .lg\:align-self-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .lg\:align-self-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
  .lg\:align-self-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-self-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .xl\:align-self-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .xl\:align-self-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .xl\:align-self-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .xl\:align-self-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
  .xl\:align-self-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
}
.flex-order-0 {
  -webkit-order: 0 !important;
          order: 0 !important;
}

.flex-order-1 {
  -webkit-order: 1 !important;
          order: 1 !important;
}

.flex-order-2 {
  -webkit-order: 2 !important;
          order: 2 !important;
}

.flex-order-3 {
  -webkit-order: 3 !important;
          order: 3 !important;
}

.flex-order-4 {
  -webkit-order: 4 !important;
          order: 4 !important;
}

.flex-order-5 {
  -webkit-order: 5 !important;
          order: 5 !important;
}

.flex-order-6 {
  -webkit-order: 6 !important;
          order: 6 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    -webkit-order: 0 !important;
            order: 0 !important;
  }
  .sm\:flex-order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }
  .sm\:flex-order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }
  .sm\:flex-order-3 {
    -webkit-order: 3 !important;
            order: 3 !important;
  }
  .sm\:flex-order-4 {
    -webkit-order: 4 !important;
            order: 4 !important;
  }
  .sm\:flex-order-5 {
    -webkit-order: 5 !important;
            order: 5 !important;
  }
  .sm\:flex-order-6 {
    -webkit-order: 6 !important;
            order: 6 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-order-0 {
    -webkit-order: 0 !important;
            order: 0 !important;
  }
  .md\:flex-order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }
  .md\:flex-order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }
  .md\:flex-order-3 {
    -webkit-order: 3 !important;
            order: 3 !important;
  }
  .md\:flex-order-4 {
    -webkit-order: 4 !important;
            order: 4 !important;
  }
  .md\:flex-order-5 {
    -webkit-order: 5 !important;
            order: 5 !important;
  }
  .md\:flex-order-6 {
    -webkit-order: 6 !important;
            order: 6 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-order-0 {
    -webkit-order: 0 !important;
            order: 0 !important;
  }
  .lg\:flex-order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }
  .lg\:flex-order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }
  .lg\:flex-order-3 {
    -webkit-order: 3 !important;
            order: 3 !important;
  }
  .lg\:flex-order-4 {
    -webkit-order: 4 !important;
            order: 4 !important;
  }
  .lg\:flex-order-5 {
    -webkit-order: 5 !important;
            order: 5 !important;
  }
  .lg\:flex-order-6 {
    -webkit-order: 6 !important;
            order: 6 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-order-0 {
    -webkit-order: 0 !important;
            order: 0 !important;
  }
  .xl\:flex-order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }
  .xl\:flex-order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }
  .xl\:flex-order-3 {
    -webkit-order: 3 !important;
            order: 3 !important;
  }
  .xl\:flex-order-4 {
    -webkit-order: 4 !important;
            order: 4 !important;
  }
  .xl\:flex-order-5 {
    -webkit-order: 5 !important;
            order: 5 !important;
  }
  .xl\:flex-order-6 {
    -webkit-order: 6 !important;
            order: 6 !important;
  }
}
.flex-1 {
  -webkit-flex: 1 1 !important;
          flex: 1 1 !important;
}

.flex-auto {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-initial {
  -webkit-flex: 0 1 auto !important;
          flex: 0 1 auto !important;
}

.flex-none {
  -webkit-flex: none !important;
          flex: none !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
  }
  .sm\:flex-auto {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .sm\:flex-initial {
    -webkit-flex: 0 1 auto !important;
            flex: 0 1 auto !important;
  }
  .sm\:flex-none {
    -webkit-flex: none !important;
            flex: none !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-1 {
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
  }
  .md\:flex-auto {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .md\:flex-initial {
    -webkit-flex: 0 1 auto !important;
            flex: 0 1 auto !important;
  }
  .md\:flex-none {
    -webkit-flex: none !important;
            flex: none !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-1 {
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
  }
  .lg\:flex-auto {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .lg\:flex-initial {
    -webkit-flex: 0 1 auto !important;
            flex: 0 1 auto !important;
  }
  .lg\:flex-none {
    -webkit-flex: none !important;
            flex: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-1 {
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
  }
  .xl\:flex-auto {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .xl\:flex-initial {
    -webkit-flex: 0 1 auto !important;
            flex: 0 1 auto !important;
  }
  .xl\:flex-none {
    -webkit-flex: none !important;
            flex: none !important;
  }
}
.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
}
.gap-0 {
  grid-gap: 0rem !important;
  gap: 0rem !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-5 {
  grid-gap: 2rem !important;
  gap: 2rem !important;
}

.gap-6 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.gap-7 {
  grid-gap: 4rem !important;
  gap: 4rem !important;
}

.gap-8 {
  grid-gap: 5rem !important;
  gap: 5rem !important;
}

.row-gap-0 {
  grid-row-gap: 0rem !important;
  row-gap: 0rem !important;
}

.row-gap-1 {
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important;
}

.row-gap-4 {
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important;
}

.row-gap-6 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important;
}

.row-gap-7 {
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important;
}

.row-gap-8 {
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important;
}

.column-gap-0 {
  grid-column-gap: 0rem !important;
  -webkit-column-gap: 0rem !important;
          column-gap: 0rem !important;
}

.column-gap-1 {
  grid-column-gap: 0.25rem !important;
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.column-gap-2 {
  grid-column-gap: 0.5rem !important;
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.column-gap-3 {
  grid-column-gap: 1rem !important;
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.column-gap-4 {
  grid-column-gap: 1.5rem !important;
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}

.column-gap-5 {
  grid-column-gap: 2rem !important;
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.column-gap-6 {
  grid-column-gap: 3rem !important;
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}

.column-gap-7 {
  grid-column-gap: 4rem !important;
  -webkit-column-gap: 4rem !important;
          column-gap: 4rem !important;
}

.column-gap-8 {
  grid-column-gap: 5rem !important;
  -webkit-column-gap: 5rem !important;
          column-gap: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:gap-0 {
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
  .sm\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .sm\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .sm\:gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .sm\:gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .sm\:gap-5 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }
  .sm\:gap-6 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .sm\:gap-7 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }
  .sm\:gap-8 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
  .sm\:row-gap-0 {
    grid-row-gap: 0rem !important;
    row-gap: 0rem !important;
  }
  .sm\:row-gap-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }
  .sm\:row-gap-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }
  .sm\:row-gap-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }
  .sm\:row-gap-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }
  .sm\:row-gap-5 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }
  .sm\:row-gap-6 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }
  .sm\:row-gap-7 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }
  .sm\:row-gap-8 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }
  .sm\:column-gap-0 {
    grid-column-gap: 0rem !important;
    -webkit-column-gap: 0rem !important;
            column-gap: 0rem !important;
  }
  .sm\:column-gap-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .sm\:column-gap-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .sm\:column-gap-3 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .sm\:column-gap-4 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .sm\:column-gap-5 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }
  .sm\:column-gap-6 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .sm\:column-gap-7 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }
  .sm\:column-gap-8 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:gap-0 {
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
  .md\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .md\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .md\:gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .md\:gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .md\:gap-5 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }
  .md\:gap-6 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .md\:gap-7 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }
  .md\:gap-8 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
  .md\:row-gap-0 {
    grid-row-gap: 0rem !important;
    row-gap: 0rem !important;
  }
  .md\:row-gap-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }
  .md\:row-gap-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }
  .md\:row-gap-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }
  .md\:row-gap-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }
  .md\:row-gap-5 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }
  .md\:row-gap-6 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }
  .md\:row-gap-7 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }
  .md\:row-gap-8 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }
  .md\:column-gap-0 {
    grid-column-gap: 0rem !important;
    -webkit-column-gap: 0rem !important;
            column-gap: 0rem !important;
  }
  .md\:column-gap-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .md\:column-gap-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .md\:column-gap-3 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .md\:column-gap-4 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .md\:column-gap-5 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }
  .md\:column-gap-6 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .md\:column-gap-7 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }
  .md\:column-gap-8 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:gap-0 {
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
  .lg\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .lg\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .lg\:gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .lg\:gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .lg\:gap-5 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }
  .lg\:gap-6 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .lg\:gap-7 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }
  .lg\:gap-8 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
  .lg\:row-gap-0 {
    grid-row-gap: 0rem !important;
    row-gap: 0rem !important;
  }
  .lg\:row-gap-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }
  .lg\:row-gap-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }
  .lg\:row-gap-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }
  .lg\:row-gap-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }
  .lg\:row-gap-5 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }
  .lg\:row-gap-6 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }
  .lg\:row-gap-7 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }
  .lg\:row-gap-8 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }
  .lg\:column-gap-0 {
    grid-column-gap: 0rem !important;
    -webkit-column-gap: 0rem !important;
            column-gap: 0rem !important;
  }
  .lg\:column-gap-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .lg\:column-gap-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .lg\:column-gap-3 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .lg\:column-gap-4 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .lg\:column-gap-5 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }
  .lg\:column-gap-6 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .lg\:column-gap-7 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }
  .lg\:column-gap-8 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:gap-0 {
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
  .xl\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .xl\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .xl\:gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .xl\:gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .xl\:gap-5 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }
  .xl\:gap-6 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .xl\:gap-7 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }
  .xl\:gap-8 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
  .xl\:row-gap-0 {
    grid-row-gap: 0rem !important;
    row-gap: 0rem !important;
  }
  .xl\:row-gap-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }
  .xl\:row-gap-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }
  .xl\:row-gap-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }
  .xl\:row-gap-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }
  .xl\:row-gap-5 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }
  .xl\:row-gap-6 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }
  .xl\:row-gap-7 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }
  .xl\:row-gap-8 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }
  .xl\:column-gap-0 {
    grid-column-gap: 0rem !important;
    -webkit-column-gap: 0rem !important;
            column-gap: 0rem !important;
  }
  .xl\:column-gap-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .xl\:column-gap-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .xl\:column-gap-3 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .xl\:column-gap-4 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .xl\:column-gap-5 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }
  .xl\:column-gap-6 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .xl\:column-gap-7 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }
  .xl\:column-gap-8 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }
}
.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0rem !important;
  }
  .sm\:p-1 {
    padding: 0.25rem !important;
  }
  .sm\:p-2 {
    padding: 0.5rem !important;
  }
  .sm\:p-3 {
    padding: 1rem !important;
  }
  .sm\:p-4 {
    padding: 1.5rem !important;
  }
  .sm\:p-5 {
    padding: 2rem !important;
  }
  .sm\:p-6 {
    padding: 3rem !important;
  }
  .sm\:p-7 {
    padding: 4rem !important;
  }
  .sm\:p-8 {
    padding: 5rem !important;
  }
  .sm\:pt-0 {
    padding-top: 0rem !important;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .sm\:pt-3 {
    padding-top: 1rem !important;
  }
  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .sm\:pt-5 {
    padding-top: 2rem !important;
  }
  .sm\:pt-6 {
    padding-top: 3rem !important;
  }
  .sm\:pt-7 {
    padding-top: 4rem !important;
  }
  .sm\:pt-8 {
    padding-top: 5rem !important;
  }
  .sm\:pr-0 {
    padding-right: 0rem !important;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .sm\:pr-3 {
    padding-right: 1rem !important;
  }
  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .sm\:pr-5 {
    padding-right: 2rem !important;
  }
  .sm\:pr-6 {
    padding-right: 3rem !important;
  }
  .sm\:pr-7 {
    padding-right: 4rem !important;
  }
  .sm\:pr-8 {
    padding-right: 5rem !important;
  }
  .sm\:pl-0 {
    padding-left: 0rem !important;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .sm\:pl-3 {
    padding-left: 1rem !important;
  }
  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .sm\:pl-5 {
    padding-left: 2rem !important;
  }
  .sm\:pl-6 {
    padding-left: 3rem !important;
  }
  .sm\:pl-7 {
    padding-left: 4rem !important;
  }
  .sm\:pl-8 {
    padding-left: 5rem !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .sm\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sm\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:p-0 {
    padding: 0rem !important;
  }
  .md\:p-1 {
    padding: 0.25rem !important;
  }
  .md\:p-2 {
    padding: 0.5rem !important;
  }
  .md\:p-3 {
    padding: 1rem !important;
  }
  .md\:p-4 {
    padding: 1.5rem !important;
  }
  .md\:p-5 {
    padding: 2rem !important;
  }
  .md\:p-6 {
    padding: 3rem !important;
  }
  .md\:p-7 {
    padding: 4rem !important;
  }
  .md\:p-8 {
    padding: 5rem !important;
  }
  .md\:pt-0 {
    padding-top: 0rem !important;
  }
  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .md\:pt-3 {
    padding-top: 1rem !important;
  }
  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .md\:pt-5 {
    padding-top: 2rem !important;
  }
  .md\:pt-6 {
    padding-top: 3rem !important;
  }
  .md\:pt-7 {
    padding-top: 4rem !important;
  }
  .md\:pt-8 {
    padding-top: 5rem !important;
  }
  .md\:pr-0 {
    padding-right: 0rem !important;
  }
  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .md\:pr-3 {
    padding-right: 1rem !important;
  }
  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .md\:pr-5 {
    padding-right: 2rem !important;
  }
  .md\:pr-6 {
    padding-right: 3rem !important;
  }
  .md\:pr-7 {
    padding-right: 4rem !important;
  }
  .md\:pr-8 {
    padding-right: 5rem !important;
  }
  .md\:pl-0 {
    padding-left: 0rem !important;
  }
  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .md\:pl-3 {
    padding-left: 1rem !important;
  }
  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .md\:pl-5 {
    padding-left: 2rem !important;
  }
  .md\:pl-6 {
    padding-left: 3rem !important;
  }
  .md\:pl-7 {
    padding-left: 4rem !important;
  }
  .md\:pl-8 {
    padding-left: 5rem !important;
  }
  .md\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .md\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .md\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:p-0 {
    padding: 0rem !important;
  }
  .lg\:p-1 {
    padding: 0.25rem !important;
  }
  .lg\:p-2 {
    padding: 0.5rem !important;
  }
  .lg\:p-3 {
    padding: 1rem !important;
  }
  .lg\:p-4 {
    padding: 1.5rem !important;
  }
  .lg\:p-5 {
    padding: 2rem !important;
  }
  .lg\:p-6 {
    padding: 3rem !important;
  }
  .lg\:p-7 {
    padding: 4rem !important;
  }
  .lg\:p-8 {
    padding: 5rem !important;
  }
  .lg\:pt-0 {
    padding-top: 0rem !important;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .lg\:pt-3 {
    padding-top: 1rem !important;
  }
  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .lg\:pt-5 {
    padding-top: 2rem !important;
  }
  .lg\:pt-6 {
    padding-top: 3rem !important;
  }
  .lg\:pt-7 {
    padding-top: 4rem !important;
  }
  .lg\:pt-8 {
    padding-top: 5rem !important;
  }
  .lg\:pr-0 {
    padding-right: 0rem !important;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .lg\:pr-3 {
    padding-right: 1rem !important;
  }
  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .lg\:pr-5 {
    padding-right: 2rem !important;
  }
  .lg\:pr-6 {
    padding-right: 3rem !important;
  }
  .lg\:pr-7 {
    padding-right: 4rem !important;
  }
  .lg\:pr-8 {
    padding-right: 5rem !important;
  }
  .lg\:pl-0 {
    padding-left: 0rem !important;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .lg\:pl-3 {
    padding-left: 1rem !important;
  }
  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .lg\:pl-5 {
    padding-left: 2rem !important;
  }
  .lg\:pl-6 {
    padding-left: 3rem !important;
  }
  .lg\:pl-7 {
    padding-left: 4rem !important;
  }
  .lg\:pl-8 {
    padding-left: 5rem !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .lg\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .lg\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:p-0 {
    padding: 0rem !important;
  }
  .xl\:p-1 {
    padding: 0.25rem !important;
  }
  .xl\:p-2 {
    padding: 0.5rem !important;
  }
  .xl\:p-3 {
    padding: 1rem !important;
  }
  .xl\:p-4 {
    padding: 1.5rem !important;
  }
  .xl\:p-5 {
    padding: 2rem !important;
  }
  .xl\:p-6 {
    padding: 3rem !important;
  }
  .xl\:p-7 {
    padding: 4rem !important;
  }
  .xl\:p-8 {
    padding: 5rem !important;
  }
  .xl\:pt-0 {
    padding-top: 0rem !important;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .xl\:pt-3 {
    padding-top: 1rem !important;
  }
  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .xl\:pt-5 {
    padding-top: 2rem !important;
  }
  .xl\:pt-6 {
    padding-top: 3rem !important;
  }
  .xl\:pt-7 {
    padding-top: 4rem !important;
  }
  .xl\:pt-8 {
    padding-top: 5rem !important;
  }
  .xl\:pr-0 {
    padding-right: 0rem !important;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .xl\:pr-3 {
    padding-right: 1rem !important;
  }
  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .xl\:pr-5 {
    padding-right: 2rem !important;
  }
  .xl\:pr-6 {
    padding-right: 3rem !important;
  }
  .xl\:pr-7 {
    padding-right: 4rem !important;
  }
  .xl\:pr-8 {
    padding-right: 5rem !important;
  }
  .xl\:pl-0 {
    padding-left: 0rem !important;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .xl\:pl-3 {
    padding-left: 1rem !important;
  }
  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .xl\:pl-5 {
    padding-left: 2rem !important;
  }
  .xl\:pl-6 {
    padding-left: 3rem !important;
  }
  .xl\:pl-7 {
    padding-left: 4rem !important;
  }
  .xl\:pl-8 {
    padding-left: 5rem !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .xl\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .xl\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0rem !important;
  }
  .sm\:m-1 {
    margin: 0.25rem !important;
  }
  .sm\:m-2 {
    margin: 0.5rem !important;
  }
  .sm\:m-3 {
    margin: 1rem !important;
  }
  .sm\:m-4 {
    margin: 1.5rem !important;
  }
  .sm\:m-5 {
    margin: 2rem !important;
  }
  .sm\:m-6 {
    margin: 3rem !important;
  }
  .sm\:m-7 {
    margin: 4rem !important;
  }
  .sm\:m-8 {
    margin: 5rem !important;
  }
  .sm\:-m-1 {
    margin: -0.25rem !important;
  }
  .sm\:-m-2 {
    margin: -0.5rem !important;
  }
  .sm\:-m-3 {
    margin: -1rem !important;
  }
  .sm\:-m-4 {
    margin: -1.5rem !important;
  }
  .sm\:-m-5 {
    margin: -2rem !important;
  }
  .sm\:-m-6 {
    margin: -3rem !important;
  }
  .sm\:-m-7 {
    margin: -4rem !important;
  }
  .sm\:-m-8 {
    margin: -5rem !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:mt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .sm\:mt-3 {
    margin-top: 1rem !important;
  }
  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .sm\:mt-5 {
    margin-top: 2rem !important;
  }
  .sm\:mt-6 {
    margin-top: 3rem !important;
  }
  .sm\:mt-7 {
    margin-top: 4rem !important;
  }
  .sm\:mt-8 {
    margin-top: 5rem !important;
  }
  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }
  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }
  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }
  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }
  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }
  .sm\:mt-auto {
    margin-top: auto !important;
  }
  .sm\:mr-0 {
    margin-right: 0rem !important;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .sm\:mr-3 {
    margin-right: 1rem !important;
  }
  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .sm\:mr-5 {
    margin-right: 2rem !important;
  }
  .sm\:mr-6 {
    margin-right: 3rem !important;
  }
  .sm\:mr-7 {
    margin-right: 4rem !important;
  }
  .sm\:mr-8 {
    margin-right: 5rem !important;
  }
  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }
  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }
  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }
  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }
  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }
  .sm\:mr-auto {
    margin-right: auto !important;
  }
  .sm\:ml-0 {
    margin-left: 0rem !important;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .sm\:ml-3 {
    margin-left: 1rem !important;
  }
  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .sm\:ml-5 {
    margin-left: 2rem !important;
  }
  .sm\:ml-6 {
    margin-left: 3rem !important;
  }
  .sm\:ml-7 {
    margin-left: 4rem !important;
  }
  .sm\:ml-8 {
    margin-left: 5rem !important;
  }
  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }
  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }
  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }
  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }
  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }
  .sm\:ml-auto {
    margin-left: auto !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .sm\:mb-auto {
    margin-bottom: auto !important;
  }
  .sm\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:m-0 {
    margin: 0rem !important;
  }
  .md\:m-1 {
    margin: 0.25rem !important;
  }
  .md\:m-2 {
    margin: 0.5rem !important;
  }
  .md\:m-3 {
    margin: 1rem !important;
  }
  .md\:m-4 {
    margin: 1.5rem !important;
  }
  .md\:m-5 {
    margin: 2rem !important;
  }
  .md\:m-6 {
    margin: 3rem !important;
  }
  .md\:m-7 {
    margin: 4rem !important;
  }
  .md\:m-8 {
    margin: 5rem !important;
  }
  .md\:-m-1 {
    margin: -0.25rem !important;
  }
  .md\:-m-2 {
    margin: -0.5rem !important;
  }
  .md\:-m-3 {
    margin: -1rem !important;
  }
  .md\:-m-4 {
    margin: -1.5rem !important;
  }
  .md\:-m-5 {
    margin: -2rem !important;
  }
  .md\:-m-6 {
    margin: -3rem !important;
  }
  .md\:-m-7 {
    margin: -4rem !important;
  }
  .md\:-m-8 {
    margin: -5rem !important;
  }
  .md\:m-auto {
    margin: auto !important;
  }
  .md\:mt-0 {
    margin-top: 0rem !important;
  }
  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .md\:mt-3 {
    margin-top: 1rem !important;
  }
  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .md\:mt-5 {
    margin-top: 2rem !important;
  }
  .md\:mt-6 {
    margin-top: 3rem !important;
  }
  .md\:mt-7 {
    margin-top: 4rem !important;
  }
  .md\:mt-8 {
    margin-top: 5rem !important;
  }
  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .md\:-mt-3 {
    margin-top: -1rem !important;
  }
  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .md\:-mt-5 {
    margin-top: -2rem !important;
  }
  .md\:-mt-6 {
    margin-top: -3rem !important;
  }
  .md\:-mt-7 {
    margin-top: -4rem !important;
  }
  .md\:-mt-8 {
    margin-top: -5rem !important;
  }
  .md\:mt-auto {
    margin-top: auto !important;
  }
  .md\:mr-0 {
    margin-right: 0rem !important;
  }
  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .md\:mr-3 {
    margin-right: 1rem !important;
  }
  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .md\:mr-5 {
    margin-right: 2rem !important;
  }
  .md\:mr-6 {
    margin-right: 3rem !important;
  }
  .md\:mr-7 {
    margin-right: 4rem !important;
  }
  .md\:mr-8 {
    margin-right: 5rem !important;
  }
  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .md\:-mr-3 {
    margin-right: -1rem !important;
  }
  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .md\:-mr-5 {
    margin-right: -2rem !important;
  }
  .md\:-mr-6 {
    margin-right: -3rem !important;
  }
  .md\:-mr-7 {
    margin-right: -4rem !important;
  }
  .md\:-mr-8 {
    margin-right: -5rem !important;
  }
  .md\:mr-auto {
    margin-right: auto !important;
  }
  .md\:ml-0 {
    margin-left: 0rem !important;
  }
  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .md\:ml-3 {
    margin-left: 1rem !important;
  }
  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .md\:ml-5 {
    margin-left: 2rem !important;
  }
  .md\:ml-6 {
    margin-left: 3rem !important;
  }
  .md\:ml-7 {
    margin-left: 4rem !important;
  }
  .md\:ml-8 {
    margin-left: 5rem !important;
  }
  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .md\:-ml-3 {
    margin-left: -1rem !important;
  }
  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .md\:-ml-5 {
    margin-left: -2rem !important;
  }
  .md\:-ml-6 {
    margin-left: -3rem !important;
  }
  .md\:-ml-7 {
    margin-left: -4rem !important;
  }
  .md\:-ml-8 {
    margin-left: -5rem !important;
  }
  .md\:ml-auto {
    margin-left: auto !important;
  }
  .md\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .md\:mb-auto {
    margin-bottom: auto !important;
  }
  .md\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:m-0 {
    margin: 0rem !important;
  }
  .lg\:m-1 {
    margin: 0.25rem !important;
  }
  .lg\:m-2 {
    margin: 0.5rem !important;
  }
  .lg\:m-3 {
    margin: 1rem !important;
  }
  .lg\:m-4 {
    margin: 1.5rem !important;
  }
  .lg\:m-5 {
    margin: 2rem !important;
  }
  .lg\:m-6 {
    margin: 3rem !important;
  }
  .lg\:m-7 {
    margin: 4rem !important;
  }
  .lg\:m-8 {
    margin: 5rem !important;
  }
  .lg\:-m-1 {
    margin: -0.25rem !important;
  }
  .lg\:-m-2 {
    margin: -0.5rem !important;
  }
  .lg\:-m-3 {
    margin: -1rem !important;
  }
  .lg\:-m-4 {
    margin: -1.5rem !important;
  }
  .lg\:-m-5 {
    margin: -2rem !important;
  }
  .lg\:-m-6 {
    margin: -3rem !important;
  }
  .lg\:-m-7 {
    margin: -4rem !important;
  }
  .lg\:-m-8 {
    margin: -5rem !important;
  }
  .lg\:m-auto {
    margin: auto !important;
  }
  .lg\:mt-0 {
    margin-top: 0rem !important;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .lg\:mt-3 {
    margin-top: 1rem !important;
  }
  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .lg\:mt-5 {
    margin-top: 2rem !important;
  }
  .lg\:mt-6 {
    margin-top: 3rem !important;
  }
  .lg\:mt-7 {
    margin-top: 4rem !important;
  }
  .lg\:mt-8 {
    margin-top: 5rem !important;
  }
  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }
  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }
  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }
  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }
  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }
  .lg\:mt-auto {
    margin-top: auto !important;
  }
  .lg\:mr-0 {
    margin-right: 0rem !important;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .lg\:mr-3 {
    margin-right: 1rem !important;
  }
  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .lg\:mr-5 {
    margin-right: 2rem !important;
  }
  .lg\:mr-6 {
    margin-right: 3rem !important;
  }
  .lg\:mr-7 {
    margin-right: 4rem !important;
  }
  .lg\:mr-8 {
    margin-right: 5rem !important;
  }
  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }
  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }
  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }
  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }
  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }
  .lg\:mr-auto {
    margin-right: auto !important;
  }
  .lg\:ml-0 {
    margin-left: 0rem !important;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .lg\:ml-3 {
    margin-left: 1rem !important;
  }
  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .lg\:ml-5 {
    margin-left: 2rem !important;
  }
  .lg\:ml-6 {
    margin-left: 3rem !important;
  }
  .lg\:ml-7 {
    margin-left: 4rem !important;
  }
  .lg\:ml-8 {
    margin-left: 5rem !important;
  }
  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }
  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }
  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }
  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }
  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }
  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .lg\:mb-auto {
    margin-bottom: auto !important;
  }
  .lg\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .lg\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0rem !important;
  }
  .xl\:m-1 {
    margin: 0.25rem !important;
  }
  .xl\:m-2 {
    margin: 0.5rem !important;
  }
  .xl\:m-3 {
    margin: 1rem !important;
  }
  .xl\:m-4 {
    margin: 1.5rem !important;
  }
  .xl\:m-5 {
    margin: 2rem !important;
  }
  .xl\:m-6 {
    margin: 3rem !important;
  }
  .xl\:m-7 {
    margin: 4rem !important;
  }
  .xl\:m-8 {
    margin: 5rem !important;
  }
  .xl\:-m-1 {
    margin: -0.25rem !important;
  }
  .xl\:-m-2 {
    margin: -0.5rem !important;
  }
  .xl\:-m-3 {
    margin: -1rem !important;
  }
  .xl\:-m-4 {
    margin: -1.5rem !important;
  }
  .xl\:-m-5 {
    margin: -2rem !important;
  }
  .xl\:-m-6 {
    margin: -3rem !important;
  }
  .xl\:-m-7 {
    margin: -4rem !important;
  }
  .xl\:-m-8 {
    margin: -5rem !important;
  }
  .xl\:m-auto {
    margin: auto !important;
  }
  .xl\:mt-0 {
    margin-top: 0rem !important;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .xl\:mt-3 {
    margin-top: 1rem !important;
  }
  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .xl\:mt-5 {
    margin-top: 2rem !important;
  }
  .xl\:mt-6 {
    margin-top: 3rem !important;
  }
  .xl\:mt-7 {
    margin-top: 4rem !important;
  }
  .xl\:mt-8 {
    margin-top: 5rem !important;
  }
  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }
  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }
  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }
  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }
  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }
  .xl\:mt-auto {
    margin-top: auto !important;
  }
  .xl\:mr-0 {
    margin-right: 0rem !important;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .xl\:mr-3 {
    margin-right: 1rem !important;
  }
  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .xl\:mr-5 {
    margin-right: 2rem !important;
  }
  .xl\:mr-6 {
    margin-right: 3rem !important;
  }
  .xl\:mr-7 {
    margin-right: 4rem !important;
  }
  .xl\:mr-8 {
    margin-right: 5rem !important;
  }
  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }
  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }
  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }
  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }
  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }
  .xl\:mr-auto {
    margin-right: auto !important;
  }
  .xl\:ml-0 {
    margin-left: 0rem !important;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .xl\:ml-3 {
    margin-left: 1rem !important;
  }
  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .xl\:ml-5 {
    margin-left: 2rem !important;
  }
  .xl\:ml-6 {
    margin-left: 3rem !important;
  }
  .xl\:ml-7 {
    margin-left: 4rem !important;
  }
  .xl\:ml-8 {
    margin-left: 5rem !important;
  }
  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }
  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }
  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }
  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }
  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }
  .xl\:ml-auto {
    margin-left: auto !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .xl\:mb-auto {
    margin-bottom: auto !important;
  }
  .xl\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xl\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.shadow-1 {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.shadow-2 {
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-3 {
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-4 {
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-5 {
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.shadow-7 {
  -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.shadow-8 {
  -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-none:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.hover\:shadow-none:hover {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.active\:shadow-none:active {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.focus\:shadow-1:focus {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.hover\:shadow-1:hover {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.active\:shadow-1:active {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.focus\:shadow-2:focus {
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.hover\:shadow-2:hover {
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.active\:shadow-2:active {
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.focus\:shadow-3:focus {
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-3:hover {
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.active\:shadow-3:active {
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.focus\:shadow-4:focus {
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-4:hover {
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-4:active {
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-5:focus {
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-5:hover {
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-5:active {
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-6:focus {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.hover\:shadow-6:hover {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.active\:shadow-6:active {
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.focus\:shadow-7:focus {
  -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.hover\:shadow-7:hover {
  -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.active\:shadow-7:active {
  -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.focus\:shadow-8:focus {
  -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-8:hover {
  -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-8:active {
  -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .sm\:shadow-1 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:shadow-2 {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:shadow-3 {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:shadow-4 {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-5 {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-6 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:shadow-7 {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:shadow-8 {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-none:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .sm\:hover\:shadow-none:hover {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .sm\:active\:shadow-none:active {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .sm\:focus\:shadow-1:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:hover\:shadow-1:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:active\:shadow-1:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:focus\:shadow-2:focus {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:hover\:shadow-2:hover {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:active\:shadow-2:active {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:focus\:shadow-3:focus {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:hover\:shadow-3:hover {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:active\:shadow-3:active {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:focus\:shadow-4:focus {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-4:hover {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-4:active {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-5:focus {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-5:hover {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-5:active {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-6:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:hover\:shadow-6:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:active\:shadow-6:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:focus\:shadow-7:focus {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:hover\:shadow-7:hover {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:active\:shadow-7:active {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:focus\:shadow-8:focus {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-8:hover {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-8:active {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:shadow-none {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .md\:shadow-1 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:shadow-2 {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:shadow-3 {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:shadow-4 {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-5 {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-6 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:shadow-7 {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:shadow-8 {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-none:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .md\:hover\:shadow-none:hover {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .md\:active\:shadow-none:active {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .md\:focus\:shadow-1:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:hover\:shadow-1:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:active\:shadow-1:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:focus\:shadow-2:focus {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:hover\:shadow-2:hover {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:active\:shadow-2:active {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:focus\:shadow-3:focus {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:hover\:shadow-3:hover {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:active\:shadow-3:active {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:focus\:shadow-4:focus {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-4:hover {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-4:active {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-5:focus {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-5:hover {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-5:active {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-6:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:hover\:shadow-6:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:active\:shadow-6:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:focus\:shadow-7:focus {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:hover\:shadow-7:hover {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:active\:shadow-7:active {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:focus\:shadow-8:focus {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-8:hover {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-8:active {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:shadow-none {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .lg\:shadow-1 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:shadow-2 {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:shadow-3 {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:shadow-4 {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-5 {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-6 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:shadow-7 {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:shadow-8 {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-none:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .lg\:hover\:shadow-none:hover {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .lg\:active\:shadow-none:active {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .lg\:focus\:shadow-1:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:hover\:shadow-1:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:active\:shadow-1:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:focus\:shadow-2:focus {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:hover\:shadow-2:hover {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:active\:shadow-2:active {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:focus\:shadow-3:focus {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:hover\:shadow-3:hover {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:active\:shadow-3:active {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:focus\:shadow-4:focus {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-4:hover {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-4:active {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-5:focus {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-5:hover {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-5:active {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-6:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:hover\:shadow-6:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:active\:shadow-6:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:focus\:shadow-7:focus {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:hover\:shadow-7:hover {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:active\:shadow-7:active {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:focus\:shadow-8:focus {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-8:hover {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-8:active {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:shadow-none {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .xl\:shadow-1 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:shadow-2 {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:shadow-3 {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:shadow-4 {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-5 {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-6 {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:shadow-7 {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:shadow-8 {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-none:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .xl\:hover\:shadow-none:hover {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .xl\:active\:shadow-none:active {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .xl\:focus\:shadow-1:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:hover\:shadow-1:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:active\:shadow-1:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:focus\:shadow-2:focus {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:hover\:shadow-2:hover {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:active\:shadow-2:active {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:focus\:shadow-3:focus {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:hover\:shadow-3:hover {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:active\:shadow-3:active {
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:focus\:shadow-4:focus {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-4:hover {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-4:active {
    -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-5:focus {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-5:hover {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-5:active {
    -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-6:focus {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:hover\:shadow-6:hover {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:active\:shadow-6:active {
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:focus\:shadow-7:focus {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:hover\:shadow-7:hover {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:active\:shadow-7:active {
    -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:focus\:shadow-8:focus {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-8:hover {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-8:active {
    -webkit-box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
.border-none {
  border-width: 0px !important;
  border-style: none;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-top-none {
  border-top-width: 0px !important;
  border-top-style: none;
}

.border-top-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-top-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-top-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-right-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-right-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
}

.border-left-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-left-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-x-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-y-none {
  border-top-width: 0px !important;
  border-top-style: none;
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .sm\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .sm\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .sm\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .sm\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .sm\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .sm\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .sm\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .sm\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .sm\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .sm\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .sm\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .sm\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .sm\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .md\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .md\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .md\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .md\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .md\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .md\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .md\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .md\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .md\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .md\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .md\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .lg\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .lg\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .lg\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .lg\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .lg\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .lg\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .lg\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .lg\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .lg\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .lg\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .lg\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .lg\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .lg\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }
  .sm\:border-dashed {
    border-style: dashed !important;
  }
  .sm\:border-dotted {
    border-style: dotted !important;
  }
  .sm\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }
  .md\:border-dashed {
    border-style: dashed !important;
  }
  .md\:border-dotted {
    border-style: dotted !important;
  }
  .md\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }
  .lg\:border-dashed {
    border-style: dashed !important;
  }
  .lg\:border-dotted {
    border-style: dotted !important;
  }
  .lg\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }
  .xl\:border-dashed {
    border-style: dashed !important;
  }
  .xl\:border-dotted {
    border-style: dotted !important;
  }
  .xl\:border-double {
    border-style: double !important;
  }
}
.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: 0.125rem !important;
}

.border-round-sm {
  border-radius: 0.25rem !important;
}

.border-round-md {
  border-radius: 0.375rem !important;
}

.border-round-lg {
  border-radius: 0.5rem !important;
}

.border-round-xl {
  border-radius: 0.75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }
  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .sm\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .sm\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .sm\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .sm\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .sm\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .sm\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }
  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .md\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .md\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .md\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .md\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .md\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .md\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }
  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .lg\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .lg\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .lg\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .lg\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .lg\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .lg\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }
  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xl\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xl\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xl\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xl\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xl\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xl\:border-circle {
    border-radius: 50% !important;
  }
}
.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-bottom-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-bottom-sm {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-left-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.border-round-top-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.border-round-right-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-bottom-md {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-bottom-xl {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .sm\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .sm\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .sm\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .sm\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .sm\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .sm\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .sm\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .sm\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .sm\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .sm\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .sm\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .md\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .md\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .md\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .md\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .md\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .md\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .md\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .md\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .md\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .md\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .md\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .lg\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .lg\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .lg\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .lg\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .lg\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .lg\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .lg\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .lg\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .lg\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .lg\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .lg\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xl\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xl\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xl\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xl\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xl\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xl\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xl\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xl\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xl\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xl\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xl\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: -webkit-min-content !important;
  width: min-content !important;
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }
  .sm\:w-screen {
    width: 100vw !important;
  }
  .sm\:w-auto {
    width: auto !important;
  }
  .sm\:w-1 {
    width: 8.3333% !important;
  }
  .sm\:w-2 {
    width: 16.6667% !important;
  }
  .sm\:w-3 {
    width: 25% !important;
  }
  .sm\:w-4 {
    width: 33.3333% !important;
  }
  .sm\:w-5 {
    width: 41.6667% !important;
  }
  .sm\:w-6 {
    width: 50% !important;
  }
  .sm\:w-7 {
    width: 58.3333% !important;
  }
  .sm\:w-8 {
    width: 66.6667% !important;
  }
  .sm\:w-9 {
    width: 75% !important;
  }
  .sm\:w-10 {
    width: 83.3333% !important;
  }
  .sm\:w-11 {
    width: 91.6667% !important;
  }
  .sm\:w-12 {
    width: 100% !important;
  }
  .sm\:w-min {
    width: -webkit-min-content !important;
    width: min-content !important;
  }
  .sm\:w-max {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
  .sm\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .sm\:w-1rem {
    width: 1rem !important;
  }
  .sm\:w-2rem {
    width: 2rem !important;
  }
  .sm\:w-3rem {
    width: 3rem !important;
  }
  .sm\:w-4rem {
    width: 4rem !important;
  }
  .sm\:w-5rem {
    width: 5rem !important;
  }
  .sm\:w-6rem {
    width: 6rem !important;
  }
  .sm\:w-7rem {
    width: 7rem !important;
  }
  .sm\:w-8rem {
    width: 8rem !important;
  }
  .sm\:w-9rem {
    width: 9rem !important;
  }
  .sm\:w-10rem {
    width: 10rem !important;
  }
  .sm\:w-11rem {
    width: 11rem !important;
  }
  .sm\:w-12rem {
    width: 12rem !important;
  }
  .sm\:w-13rem {
    width: 13rem !important;
  }
  .sm\:w-14rem {
    width: 14rem !important;
  }
  .sm\:w-15rem {
    width: 15rem !important;
  }
  .sm\:w-16rem {
    width: 16rem !important;
  }
  .sm\:w-17rem {
    width: 17rem !important;
  }
  .sm\:w-18rem {
    width: 18rem !important;
  }
  .sm\:w-19rem {
    width: 19rem !important;
  }
  .sm\:w-20rem {
    width: 20rem !important;
  }
  .sm\:w-21rem {
    width: 21rem !important;
  }
  .sm\:w-22rem {
    width: 22rem !important;
  }
  .sm\:w-23rem {
    width: 23rem !important;
  }
  .sm\:w-24rem {
    width: 24rem !important;
  }
  .sm\:w-25rem {
    width: 25rem !important;
  }
  .sm\:w-26rem {
    width: 26rem !important;
  }
  .sm\:w-27rem {
    width: 27rem !important;
  }
  .sm\:w-28rem {
    width: 28rem !important;
  }
  .sm\:w-29rem {
    width: 29rem !important;
  }
  .sm\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-screen {
    width: 100vw !important;
  }
  .md\:w-auto {
    width: auto !important;
  }
  .md\:w-1 {
    width: 8.3333% !important;
  }
  .md\:w-2 {
    width: 16.6667% !important;
  }
  .md\:w-3 {
    width: 25% !important;
  }
  .md\:w-4 {
    width: 33.3333% !important;
  }
  .md\:w-5 {
    width: 41.6667% !important;
  }
  .md\:w-6 {
    width: 50% !important;
  }
  .md\:w-7 {
    width: 58.3333% !important;
  }
  .md\:w-8 {
    width: 66.6667% !important;
  }
  .md\:w-9 {
    width: 75% !important;
  }
  .md\:w-10 {
    width: 83.3333% !important;
  }
  .md\:w-11 {
    width: 91.6667% !important;
  }
  .md\:w-12 {
    width: 100% !important;
  }
  .md\:w-min {
    width: -webkit-min-content !important;
    width: min-content !important;
  }
  .md\:w-max {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
  .md\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .md\:w-1rem {
    width: 1rem !important;
  }
  .md\:w-2rem {
    width: 2rem !important;
  }
  .md\:w-3rem {
    width: 3rem !important;
  }
  .md\:w-4rem {
    width: 4rem !important;
  }
  .md\:w-5rem {
    width: 5rem !important;
  }
  .md\:w-6rem {
    width: 6rem !important;
  }
  .md\:w-7rem {
    width: 7rem !important;
  }
  .md\:w-8rem {
    width: 8rem !important;
  }
  .md\:w-9rem {
    width: 9rem !important;
  }
  .md\:w-10rem {
    width: 10rem !important;
  }
  .md\:w-11rem {
    width: 11rem !important;
  }
  .md\:w-12rem {
    width: 12rem !important;
  }
  .md\:w-13rem {
    width: 13rem !important;
  }
  .md\:w-14rem {
    width: 14rem !important;
  }
  .md\:w-15rem {
    width: 15rem !important;
  }
  .md\:w-16rem {
    width: 16rem !important;
  }
  .md\:w-17rem {
    width: 17rem !important;
  }
  .md\:w-18rem {
    width: 18rem !important;
  }
  .md\:w-19rem {
    width: 19rem !important;
  }
  .md\:w-20rem {
    width: 20rem !important;
  }
  .md\:w-21rem {
    width: 21rem !important;
  }
  .md\:w-22rem {
    width: 22rem !important;
  }
  .md\:w-23rem {
    width: 23rem !important;
  }
  .md\:w-24rem {
    width: 24rem !important;
  }
  .md\:w-25rem {
    width: 25rem !important;
  }
  .md\:w-26rem {
    width: 26rem !important;
  }
  .md\:w-27rem {
    width: 27rem !important;
  }
  .md\:w-28rem {
    width: 28rem !important;
  }
  .md\:w-29rem {
    width: 29rem !important;
  }
  .md\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:w-screen {
    width: 100vw !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-1 {
    width: 8.3333% !important;
  }
  .lg\:w-2 {
    width: 16.6667% !important;
  }
  .lg\:w-3 {
    width: 25% !important;
  }
  .lg\:w-4 {
    width: 33.3333% !important;
  }
  .lg\:w-5 {
    width: 41.6667% !important;
  }
  .lg\:w-6 {
    width: 50% !important;
  }
  .lg\:w-7 {
    width: 58.3333% !important;
  }
  .lg\:w-8 {
    width: 66.6667% !important;
  }
  .lg\:w-9 {
    width: 75% !important;
  }
  .lg\:w-10 {
    width: 83.3333% !important;
  }
  .lg\:w-11 {
    width: 91.6667% !important;
  }
  .lg\:w-12 {
    width: 100% !important;
  }
  .lg\:w-min {
    width: -webkit-min-content !important;
    width: min-content !important;
  }
  .lg\:w-max {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
  .lg\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .lg\:w-1rem {
    width: 1rem !important;
  }
  .lg\:w-2rem {
    width: 2rem !important;
  }
  .lg\:w-3rem {
    width: 3rem !important;
  }
  .lg\:w-4rem {
    width: 4rem !important;
  }
  .lg\:w-5rem {
    width: 5rem !important;
  }
  .lg\:w-6rem {
    width: 6rem !important;
  }
  .lg\:w-7rem {
    width: 7rem !important;
  }
  .lg\:w-8rem {
    width: 8rem !important;
  }
  .lg\:w-9rem {
    width: 9rem !important;
  }
  .lg\:w-10rem {
    width: 10rem !important;
  }
  .lg\:w-11rem {
    width: 11rem !important;
  }
  .lg\:w-12rem {
    width: 12rem !important;
  }
  .lg\:w-13rem {
    width: 13rem !important;
  }
  .lg\:w-14rem {
    width: 14rem !important;
  }
  .lg\:w-15rem {
    width: 15rem !important;
  }
  .lg\:w-16rem {
    width: 16rem !important;
  }
  .lg\:w-17rem {
    width: 17rem !important;
  }
  .lg\:w-18rem {
    width: 18rem !important;
  }
  .lg\:w-19rem {
    width: 19rem !important;
  }
  .lg\:w-20rem {
    width: 20rem !important;
  }
  .lg\:w-21rem {
    width: 21rem !important;
  }
  .lg\:w-22rem {
    width: 22rem !important;
  }
  .lg\:w-23rem {
    width: 23rem !important;
  }
  .lg\:w-24rem {
    width: 24rem !important;
  }
  .lg\:w-25rem {
    width: 25rem !important;
  }
  .lg\:w-26rem {
    width: 26rem !important;
  }
  .lg\:w-27rem {
    width: 27rem !important;
  }
  .lg\:w-28rem {
    width: 28rem !important;
  }
  .lg\:w-29rem {
    width: 29rem !important;
  }
  .lg\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }
  .xl\:w-screen {
    width: 100vw !important;
  }
  .xl\:w-auto {
    width: auto !important;
  }
  .xl\:w-1 {
    width: 8.3333% !important;
  }
  .xl\:w-2 {
    width: 16.6667% !important;
  }
  .xl\:w-3 {
    width: 25% !important;
  }
  .xl\:w-4 {
    width: 33.3333% !important;
  }
  .xl\:w-5 {
    width: 41.6667% !important;
  }
  .xl\:w-6 {
    width: 50% !important;
  }
  .xl\:w-7 {
    width: 58.3333% !important;
  }
  .xl\:w-8 {
    width: 66.6667% !important;
  }
  .xl\:w-9 {
    width: 75% !important;
  }
  .xl\:w-10 {
    width: 83.3333% !important;
  }
  .xl\:w-11 {
    width: 91.6667% !important;
  }
  .xl\:w-12 {
    width: 100% !important;
  }
  .xl\:w-min {
    width: -webkit-min-content !important;
    width: min-content !important;
  }
  .xl\:w-max {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
  .xl\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .xl\:w-1rem {
    width: 1rem !important;
  }
  .xl\:w-2rem {
    width: 2rem !important;
  }
  .xl\:w-3rem {
    width: 3rem !important;
  }
  .xl\:w-4rem {
    width: 4rem !important;
  }
  .xl\:w-5rem {
    width: 5rem !important;
  }
  .xl\:w-6rem {
    width: 6rem !important;
  }
  .xl\:w-7rem {
    width: 7rem !important;
  }
  .xl\:w-8rem {
    width: 8rem !important;
  }
  .xl\:w-9rem {
    width: 9rem !important;
  }
  .xl\:w-10rem {
    width: 10rem !important;
  }
  .xl\:w-11rem {
    width: 11rem !important;
  }
  .xl\:w-12rem {
    width: 12rem !important;
  }
  .xl\:w-13rem {
    width: 13rem !important;
  }
  .xl\:w-14rem {
    width: 14rem !important;
  }
  .xl\:w-15rem {
    width: 15rem !important;
  }
  .xl\:w-16rem {
    width: 16rem !important;
  }
  .xl\:w-17rem {
    width: 17rem !important;
  }
  .xl\:w-18rem {
    width: 18rem !important;
  }
  .xl\:w-19rem {
    width: 19rem !important;
  }
  .xl\:w-20rem {
    width: 20rem !important;
  }
  .xl\:w-21rem {
    width: 21rem !important;
  }
  .xl\:w-22rem {
    width: 22rem !important;
  }
  .xl\:w-23rem {
    width: 23rem !important;
  }
  .xl\:w-24rem {
    width: 24rem !important;
  }
  .xl\:w-25rem {
    width: 25rem !important;
  }
  .xl\:w-26rem {
    width: 26rem !important;
  }
  .xl\:w-27rem {
    width: 27rem !important;
  }
  .xl\:w-28rem {
    width: 28rem !important;
  }
  .xl\:w-29rem {
    width: 29rem !important;
  }
  .xl\:w-30rem {
    width: 30rem !important;
  }
}
.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: -webkit-min-content !important;
  height: min-content !important;
}

.h-max {
  height: -webkit-max-content !important;
  height: max-content !important;
}

.h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }
  .sm\:h-screen {
    height: 100vh !important;
  }
  .sm\:h-auto {
    height: auto !important;
  }
  .sm\:h-min {
    height: -webkit-min-content !important;
    height: min-content !important;
  }
  .sm\:h-max {
    height: -webkit-max-content !important;
    height: max-content !important;
  }
  .sm\:h-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .sm\:h-1rem {
    height: 1rem !important;
  }
  .sm\:h-2rem {
    height: 2rem !important;
  }
  .sm\:h-3rem {
    height: 3rem !important;
  }
  .sm\:h-4rem {
    height: 4rem !important;
  }
  .sm\:h-5rem {
    height: 5rem !important;
  }
  .sm\:h-6rem {
    height: 6rem !important;
  }
  .sm\:h-7rem {
    height: 7rem !important;
  }
  .sm\:h-8rem {
    height: 8rem !important;
  }
  .sm\:h-9rem {
    height: 9rem !important;
  }
  .sm\:h-10rem {
    height: 10rem !important;
  }
  .sm\:h-11rem {
    height: 11rem !important;
  }
  .sm\:h-12rem {
    height: 12rem !important;
  }
  .sm\:h-13rem {
    height: 13rem !important;
  }
  .sm\:h-14rem {
    height: 14rem !important;
  }
  .sm\:h-15rem {
    height: 15rem !important;
  }
  .sm\:h-16rem {
    height: 16rem !important;
  }
  .sm\:h-17rem {
    height: 17rem !important;
  }
  .sm\:h-18rem {
    height: 18rem !important;
  }
  .sm\:h-19rem {
    height: 19rem !important;
  }
  .sm\:h-20rem {
    height: 20rem !important;
  }
  .sm\:h-21rem {
    height: 21rem !important;
  }
  .sm\:h-22rem {
    height: 22rem !important;
  }
  .sm\:h-23rem {
    height: 23rem !important;
  }
  .sm\:h-24rem {
    height: 24rem !important;
  }
  .sm\:h-25rem {
    height: 25rem !important;
  }
  .sm\:h-26rem {
    height: 26rem !important;
  }
  .sm\:h-27rem {
    height: 27rem !important;
  }
  .sm\:h-28rem {
    height: 28rem !important;
  }
  .sm\:h-29rem {
    height: 29rem !important;
  }
  .sm\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:h-full {
    height: 100% !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:h-min {
    height: -webkit-min-content !important;
    height: min-content !important;
  }
  .md\:h-max {
    height: -webkit-max-content !important;
    height: max-content !important;
  }
  .md\:h-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .md\:h-1rem {
    height: 1rem !important;
  }
  .md\:h-2rem {
    height: 2rem !important;
  }
  .md\:h-3rem {
    height: 3rem !important;
  }
  .md\:h-4rem {
    height: 4rem !important;
  }
  .md\:h-5rem {
    height: 5rem !important;
  }
  .md\:h-6rem {
    height: 6rem !important;
  }
  .md\:h-7rem {
    height: 7rem !important;
  }
  .md\:h-8rem {
    height: 8rem !important;
  }
  .md\:h-9rem {
    height: 9rem !important;
  }
  .md\:h-10rem {
    height: 10rem !important;
  }
  .md\:h-11rem {
    height: 11rem !important;
  }
  .md\:h-12rem {
    height: 12rem !important;
  }
  .md\:h-13rem {
    height: 13rem !important;
  }
  .md\:h-14rem {
    height: 14rem !important;
  }
  .md\:h-15rem {
    height: 15rem !important;
  }
  .md\:h-16rem {
    height: 16rem !important;
  }
  .md\:h-17rem {
    height: 17rem !important;
  }
  .md\:h-18rem {
    height: 18rem !important;
  }
  .md\:h-19rem {
    height: 19rem !important;
  }
  .md\:h-20rem {
    height: 20rem !important;
  }
  .md\:h-21rem {
    height: 21rem !important;
  }
  .md\:h-22rem {
    height: 22rem !important;
  }
  .md\:h-23rem {
    height: 23rem !important;
  }
  .md\:h-24rem {
    height: 24rem !important;
  }
  .md\:h-25rem {
    height: 25rem !important;
  }
  .md\:h-26rem {
    height: 26rem !important;
  }
  .md\:h-27rem {
    height: 27rem !important;
  }
  .md\:h-28rem {
    height: 28rem !important;
  }
  .md\:h-29rem {
    height: 29rem !important;
  }
  .md\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:h-screen {
    height: 100vh !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-min {
    height: -webkit-min-content !important;
    height: min-content !important;
  }
  .lg\:h-max {
    height: -webkit-max-content !important;
    height: max-content !important;
  }
  .lg\:h-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .lg\:h-1rem {
    height: 1rem !important;
  }
  .lg\:h-2rem {
    height: 2rem !important;
  }
  .lg\:h-3rem {
    height: 3rem !important;
  }
  .lg\:h-4rem {
    height: 4rem !important;
  }
  .lg\:h-5rem {
    height: 5rem !important;
  }
  .lg\:h-6rem {
    height: 6rem !important;
  }
  .lg\:h-7rem {
    height: 7rem !important;
  }
  .lg\:h-8rem {
    height: 8rem !important;
  }
  .lg\:h-9rem {
    height: 9rem !important;
  }
  .lg\:h-10rem {
    height: 10rem !important;
  }
  .lg\:h-11rem {
    height: 11rem !important;
  }
  .lg\:h-12rem {
    height: 12rem !important;
  }
  .lg\:h-13rem {
    height: 13rem !important;
  }
  .lg\:h-14rem {
    height: 14rem !important;
  }
  .lg\:h-15rem {
    height: 15rem !important;
  }
  .lg\:h-16rem {
    height: 16rem !important;
  }
  .lg\:h-17rem {
    height: 17rem !important;
  }
  .lg\:h-18rem {
    height: 18rem !important;
  }
  .lg\:h-19rem {
    height: 19rem !important;
  }
  .lg\:h-20rem {
    height: 20rem !important;
  }
  .lg\:h-21rem {
    height: 21rem !important;
  }
  .lg\:h-22rem {
    height: 22rem !important;
  }
  .lg\:h-23rem {
    height: 23rem !important;
  }
  .lg\:h-24rem {
    height: 24rem !important;
  }
  .lg\:h-25rem {
    height: 25rem !important;
  }
  .lg\:h-26rem {
    height: 26rem !important;
  }
  .lg\:h-27rem {
    height: 27rem !important;
  }
  .lg\:h-28rem {
    height: 28rem !important;
  }
  .lg\:h-29rem {
    height: 29rem !important;
  }
  .lg\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }
  .xl\:h-screen {
    height: 100vh !important;
  }
  .xl\:h-auto {
    height: auto !important;
  }
  .xl\:h-min {
    height: -webkit-min-content !important;
    height: min-content !important;
  }
  .xl\:h-max {
    height: -webkit-max-content !important;
    height: max-content !important;
  }
  .xl\:h-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .xl\:h-1rem {
    height: 1rem !important;
  }
  .xl\:h-2rem {
    height: 2rem !important;
  }
  .xl\:h-3rem {
    height: 3rem !important;
  }
  .xl\:h-4rem {
    height: 4rem !important;
  }
  .xl\:h-5rem {
    height: 5rem !important;
  }
  .xl\:h-6rem {
    height: 6rem !important;
  }
  .xl\:h-7rem {
    height: 7rem !important;
  }
  .xl\:h-8rem {
    height: 8rem !important;
  }
  .xl\:h-9rem {
    height: 9rem !important;
  }
  .xl\:h-10rem {
    height: 10rem !important;
  }
  .xl\:h-11rem {
    height: 11rem !important;
  }
  .xl\:h-12rem {
    height: 12rem !important;
  }
  .xl\:h-13rem {
    height: 13rem !important;
  }
  .xl\:h-14rem {
    height: 14rem !important;
  }
  .xl\:h-15rem {
    height: 15rem !important;
  }
  .xl\:h-16rem {
    height: 16rem !important;
  }
  .xl\:h-17rem {
    height: 17rem !important;
  }
  .xl\:h-18rem {
    height: 18rem !important;
  }
  .xl\:h-19rem {
    height: 19rem !important;
  }
  .xl\:h-20rem {
    height: 20rem !important;
  }
  .xl\:h-21rem {
    height: 21rem !important;
  }
  .xl\:h-22rem {
    height: 22rem !important;
  }
  .xl\:h-23rem {
    height: 23rem !important;
  }
  .xl\:h-24rem {
    height: 24rem !important;
  }
  .xl\:h-25rem {
    height: 25rem !important;
  }
  .xl\:h-26rem {
    height: 26rem !important;
  }
  .xl\:h-27rem {
    height: 27rem !important;
  }
  .xl\:h-28rem {
    height: 28rem !important;
  }
  .xl\:h-29rem {
    height: 29rem !important;
  }
  .xl\:h-30rem {
    height: 30rem !important;
  }
}
.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: -webkit-min-content !important;
  min-width: min-content !important;
}

.min-w-max {
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0px !important;
  }
  .sm\:min-w-full {
    min-width: 100% !important;
  }
  .sm\:min-w-screen {
    min-width: 100vw !important;
  }
  .sm\:min-w-min {
    min-width: -webkit-min-content !important;
    min-width: min-content !important;
  }
  .sm\:min-w-max {
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0px !important;
  }
  .md\:min-w-full {
    min-width: 100% !important;
  }
  .md\:min-w-screen {
    min-width: 100vw !important;
  }
  .md\:min-w-min {
    min-width: -webkit-min-content !important;
    min-width: min-content !important;
  }
  .md\:min-w-max {
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-w-0 {
    min-width: 0px !important;
  }
  .lg\:min-w-full {
    min-width: 100% !important;
  }
  .lg\:min-w-screen {
    min-width: 100vw !important;
  }
  .lg\:min-w-min {
    min-width: -webkit-min-content !important;
    min-width: min-content !important;
  }
  .lg\:min-w-max {
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-w-0 {
    min-width: 0px !important;
  }
  .xl\:min-w-full {
    min-width: 100% !important;
  }
  .xl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xl\:min-w-min {
    min-width: -webkit-min-content !important;
    min-width: min-content !important;
  }
  .xl\:min-w-max {
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
  }
}
.max-w-0 {
  max-width: 0px !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
}

.max-w-max {
  max-width: -webkit-max-content !important;
  max-width: max-content !important;
}

.max-w-fit {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0px !important;
  }
  .sm\:max-w-full {
    max-width: 100% !important;
  }
  .sm\:max-w-screen {
    max-width: 100vw !important;
  }
  .sm\:max-w-min {
    max-width: -webkit-min-content !important;
    max-width: min-content !important;
  }
  .sm\:max-w-max {
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
  }
  .sm\:max-w-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }
  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }
  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }
  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }
  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }
  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }
  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }
  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }
  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }
  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }
  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }
  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }
  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }
  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }
  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }
  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }
  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }
  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }
  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }
  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }
  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }
  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }
  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }
  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }
  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }
  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }
  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }
  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }
  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }
  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-w-0 {
    max-width: 0px !important;
  }
  .md\:max-w-full {
    max-width: 100% !important;
  }
  .md\:max-w-screen {
    max-width: 100vw !important;
  }
  .md\:max-w-min {
    max-width: -webkit-min-content !important;
    max-width: min-content !important;
  }
  .md\:max-w-max {
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
  }
  .md\:max-w-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
  .md\:max-w-1rem {
    max-width: 1rem !important;
  }
  .md\:max-w-2rem {
    max-width: 2rem !important;
  }
  .md\:max-w-3rem {
    max-width: 3rem !important;
  }
  .md\:max-w-4rem {
    max-width: 4rem !important;
  }
  .md\:max-w-5rem {
    max-width: 5rem !important;
  }
  .md\:max-w-6rem {
    max-width: 6rem !important;
  }
  .md\:max-w-7rem {
    max-width: 7rem !important;
  }
  .md\:max-w-8rem {
    max-width: 8rem !important;
  }
  .md\:max-w-9rem {
    max-width: 9rem !important;
  }
  .md\:max-w-10rem {
    max-width: 10rem !important;
  }
  .md\:max-w-11rem {
    max-width: 11rem !important;
  }
  .md\:max-w-12rem {
    max-width: 12rem !important;
  }
  .md\:max-w-13rem {
    max-width: 13rem !important;
  }
  .md\:max-w-14rem {
    max-width: 14rem !important;
  }
  .md\:max-w-15rem {
    max-width: 15rem !important;
  }
  .md\:max-w-16rem {
    max-width: 16rem !important;
  }
  .md\:max-w-17rem {
    max-width: 17rem !important;
  }
  .md\:max-w-18rem {
    max-width: 18rem !important;
  }
  .md\:max-w-19rem {
    max-width: 19rem !important;
  }
  .md\:max-w-20rem {
    max-width: 20rem !important;
  }
  .md\:max-w-21rem {
    max-width: 21rem !important;
  }
  .md\:max-w-22rem {
    max-width: 22rem !important;
  }
  .md\:max-w-23rem {
    max-width: 23rem !important;
  }
  .md\:max-w-24rem {
    max-width: 24rem !important;
  }
  .md\:max-w-25rem {
    max-width: 25rem !important;
  }
  .md\:max-w-26rem {
    max-width: 26rem !important;
  }
  .md\:max-w-27rem {
    max-width: 27rem !important;
  }
  .md\:max-w-28rem {
    max-width: 28rem !important;
  }
  .md\:max-w-29rem {
    max-width: 29rem !important;
  }
  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-w-0 {
    max-width: 0px !important;
  }
  .lg\:max-w-full {
    max-width: 100% !important;
  }
  .lg\:max-w-screen {
    max-width: 100vw !important;
  }
  .lg\:max-w-min {
    max-width: -webkit-min-content !important;
    max-width: min-content !important;
  }
  .lg\:max-w-max {
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
  }
  .lg\:max-w-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }
  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }
  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }
  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }
  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }
  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }
  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }
  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }
  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }
  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }
  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }
  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }
  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }
  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }
  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }
  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }
  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }
  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }
  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }
  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }
  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }
  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }
  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }
  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }
  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }
  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }
  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }
  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }
  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }
  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-w-0 {
    max-width: 0px !important;
  }
  .xl\:max-w-full {
    max-width: 100% !important;
  }
  .xl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xl\:max-w-min {
    max-width: -webkit-min-content !important;
    max-width: min-content !important;
  }
  .xl\:max-w-max {
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
  }
  .xl\:max-w-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}
.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0px !important;
  }
  .sm\:min-h-full {
    min-height: 100% !important;
  }
  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-h-0 {
    min-height: 0px !important;
  }
  .md\:min-h-full {
    min-height: 100% !important;
  }
  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-h-0 {
    min-height: 0px !important;
  }
  .lg\:min-h-full {
    min-height: 100% !important;
  }
  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-h-0 {
    min-height: 0px !important;
  }
  .xl\:min-h-full {
    min-height: 100% !important;
  }
  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}
.max-h-0 {
  max-height: 0px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: -webkit-min-content !important;
  max-height: min-content !important;
}

.max-h-max {
  max-height: -webkit-max-content !important;
  max-height: max-content !important;
}

.max-h-fit {
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0px !important;
  }
  .sm\:max-h-full {
    max-height: 100% !important;
  }
  .sm\:max-h-screen {
    max-height: 100vh !important;
  }
  .sm\:max-h-min {
    max-height: -webkit-min-content !important;
    max-height: min-content !important;
  }
  .sm\:max-h-max {
    max-height: -webkit-max-content !important;
    max-height: max-content !important;
  }
  .sm\:max-h-fit {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }
  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }
  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }
  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }
  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }
  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }
  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }
  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }
  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }
  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }
  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }
  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }
  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }
  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }
  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }
  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }
  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }
  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }
  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }
  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }
  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }
  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }
  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }
  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }
  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }
  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }
  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }
  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }
  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }
  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-h-0 {
    max-height: 0px !important;
  }
  .md\:max-h-full {
    max-height: 100% !important;
  }
  .md\:max-h-screen {
    max-height: 100vh !important;
  }
  .md\:max-h-min {
    max-height: -webkit-min-content !important;
    max-height: min-content !important;
  }
  .md\:max-h-max {
    max-height: -webkit-max-content !important;
    max-height: max-content !important;
  }
  .md\:max-h-fit {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
  .md\:max-h-1rem {
    max-height: 1rem !important;
  }
  .md\:max-h-2rem {
    max-height: 2rem !important;
  }
  .md\:max-h-3rem {
    max-height: 3rem !important;
  }
  .md\:max-h-4rem {
    max-height: 4rem !important;
  }
  .md\:max-h-5rem {
    max-height: 5rem !important;
  }
  .md\:max-h-6rem {
    max-height: 6rem !important;
  }
  .md\:max-h-7rem {
    max-height: 7rem !important;
  }
  .md\:max-h-8rem {
    max-height: 8rem !important;
  }
  .md\:max-h-9rem {
    max-height: 9rem !important;
  }
  .md\:max-h-10rem {
    max-height: 10rem !important;
  }
  .md\:max-h-11rem {
    max-height: 11rem !important;
  }
  .md\:max-h-12rem {
    max-height: 12rem !important;
  }
  .md\:max-h-13rem {
    max-height: 13rem !important;
  }
  .md\:max-h-14rem {
    max-height: 14rem !important;
  }
  .md\:max-h-15rem {
    max-height: 15rem !important;
  }
  .md\:max-h-16rem {
    max-height: 16rem !important;
  }
  .md\:max-h-17rem {
    max-height: 17rem !important;
  }
  .md\:max-h-18rem {
    max-height: 18rem !important;
  }
  .md\:max-h-19rem {
    max-height: 19rem !important;
  }
  .md\:max-h-20rem {
    max-height: 20rem !important;
  }
  .md\:max-h-21rem {
    max-height: 21rem !important;
  }
  .md\:max-h-22rem {
    max-height: 22rem !important;
  }
  .md\:max-h-23rem {
    max-height: 23rem !important;
  }
  .md\:max-h-24rem {
    max-height: 24rem !important;
  }
  .md\:max-h-25rem {
    max-height: 25rem !important;
  }
  .md\:max-h-26rem {
    max-height: 26rem !important;
  }
  .md\:max-h-27rem {
    max-height: 27rem !important;
  }
  .md\:max-h-28rem {
    max-height: 28rem !important;
  }
  .md\:max-h-29rem {
    max-height: 29rem !important;
  }
  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-h-0 {
    max-height: 0px !important;
  }
  .lg\:max-h-full {
    max-height: 100% !important;
  }
  .lg\:max-h-screen {
    max-height: 100vh !important;
  }
  .lg\:max-h-min {
    max-height: -webkit-min-content !important;
    max-height: min-content !important;
  }
  .lg\:max-h-max {
    max-height: -webkit-max-content !important;
    max-height: max-content !important;
  }
  .lg\:max-h-fit {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }
  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }
  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }
  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }
  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }
  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }
  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }
  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }
  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }
  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }
  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }
  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }
  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }
  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }
  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }
  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }
  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }
  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }
  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }
  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }
  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }
  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }
  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }
  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }
  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }
  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }
  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }
  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }
  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }
  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-h-0 {
    max-height: 0px !important;
  }
  .xl\:max-h-full {
    max-height: 100% !important;
  }
  .xl\:max-h-screen {
    max-height: 100vh !important;
  }
  .xl\:max-h-min {
    max-height: -webkit-min-content !important;
    max-height: min-content !important;
  }
  .xl\:max-h-max {
    max-height: -webkit-max-content !important;
    max-height: max-content !important;
  }
  .xl\:max-h-fit {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }
  .sm\:fixed {
    position: fixed !important;
  }
  .sm\:absolute {
    position: absolute !important;
  }
  .sm\:relative {
    position: relative !important;
  }
  .sm\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:static {
    position: static !important;
  }
  .md\:fixed {
    position: fixed !important;
  }
  .md\:absolute {
    position: absolute !important;
  }
  .md\:relative {
    position: relative !important;
  }
  .md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:static {
    position: static !important;
  }
  .lg\:fixed {
    position: fixed !important;
  }
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:relative {
    position: relative !important;
  }
  .lg\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:static {
    position: static !important;
  }
  .xl\:fixed {
    position: fixed !important;
  }
  .xl\:absolute {
    position: absolute !important;
  }
  .xl\:relative {
    position: relative !important;
  }
  .xl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }
  .sm\:top-0 {
    top: 0px !important;
  }
  .sm\:top-50 {
    top: 50% !important;
  }
  .sm\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:top-auto {
    top: auto !important;
  }
  .md\:top-0 {
    top: 0px !important;
  }
  .md\:top-50 {
    top: 50% !important;
  }
  .md\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:top-auto {
    top: auto !important;
  }
  .lg\:top-0 {
    top: 0px !important;
  }
  .lg\:top-50 {
    top: 50% !important;
  }
  .lg\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }
  .xl\:top-0 {
    top: 0px !important;
  }
  .xl\:top-50 {
    top: 50% !important;
  }
  .xl\:top-100 {
    top: 100% !important;
  }
}
.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }
  .sm\:left-0 {
    left: 0px !important;
  }
  .sm\:left-50 {
    left: 50% !important;
  }
  .sm\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:left-auto {
    left: auto !important;
  }
  .md\:left-0 {
    left: 0px !important;
  }
  .md\:left-50 {
    left: 50% !important;
  }
  .md\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:left-auto {
    left: auto !important;
  }
  .lg\:left-0 {
    left: 0px !important;
  }
  .lg\:left-50 {
    left: 50% !important;
  }
  .lg\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }
  .xl\:left-0 {
    left: 0px !important;
  }
  .xl\:left-50 {
    left: 50% !important;
  }
  .xl\:left-100 {
    left: 100% !important;
  }
}
.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0px !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }
  .sm\:right-0 {
    right: 0px !important;
  }
  .sm\:right-50 {
    right: 50% !important;
  }
  .sm\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:right-auto {
    right: auto !important;
  }
  .md\:right-0 {
    right: 0px !important;
  }
  .md\:right-50 {
    right: 50% !important;
  }
  .md\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:right-auto {
    right: auto !important;
  }
  .lg\:right-0 {
    right: 0px !important;
  }
  .lg\:right-50 {
    right: 50% !important;
  }
  .lg\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }
  .xl\:right-0 {
    right: 0px !important;
  }
  .xl\:right-50 {
    right: 50% !important;
  }
  .xl\:right-100 {
    right: 100% !important;
  }
}
.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }
  .sm\:bottom-0 {
    bottom: 0px !important;
  }
  .sm\:bottom-50 {
    bottom: 50% !important;
  }
  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }
  .md\:bottom-0 {
    bottom: 0px !important;
  }
  .md\:bottom-50 {
    bottom: 50% !important;
  }
  .md\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }
  .lg\:bottom-0 {
    bottom: 0px !important;
  }
  .lg\:bottom-50 {
    bottom: 50% !important;
  }
  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }
  .xl\:bottom-0 {
    bottom: 0px !important;
  }
  .xl\:bottom-50 {
    bottom: 50% !important;
  }
  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }
  .sm\:overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:overflow-visible {
    overflow: visible !important;
  }
  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }
  .md\:overflow-hidden {
    overflow: hidden !important;
  }
  .md\:overflow-visible {
    overflow: visible !important;
  }
  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }
  .lg\:overflow-hidden {
    overflow: hidden !important;
  }
  .lg\:overflow-visible {
    overflow: visible !important;
  }
  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }
  .xl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xl\:overflow-visible {
    overflow: visible !important;
  }
  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }
  .sm\:z-0 {
    z-index: 0 !important;
  }
  .sm\:z-1 {
    z-index: 1 !important;
  }
  .sm\:z-2 {
    z-index: 2 !important;
  }
  .sm\:z-3 {
    z-index: 3 !important;
  }
  .sm\:z-4 {
    z-index: 4 !important;
  }
  .sm\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }
  .md\:z-0 {
    z-index: 0 !important;
  }
  .md\:z-1 {
    z-index: 1 !important;
  }
  .md\:z-2 {
    z-index: 2 !important;
  }
  .md\:z-3 {
    z-index: 3 !important;
  }
  .md\:z-4 {
    z-index: 4 !important;
  }
  .md\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }
  .lg\:z-0 {
    z-index: 0 !important;
  }
  .lg\:z-1 {
    z-index: 1 !important;
  }
  .lg\:z-2 {
    z-index: 2 !important;
  }
  .lg\:z-3 {
    z-index: 3 !important;
  }
  .lg\:z-4 {
    z-index: 4 !important;
  }
  .lg\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }
  .xl\:z-0 {
    z-index: 0 !important;
  }
  .xl\:z-1 {
    z-index: 1 !important;
  }
  .xl\:z-2 {
    z-index: 2 !important;
  }
  .xl\:z-3 {
    z-index: 3 !important;
  }
  .xl\:z-4 {
    z-index: 4 !important;
  }
  .xl\:z-5 {
    z-index: 5 !important;
  }
}
.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }
  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .md\:bg-repeat-round {
    background-repeat: round !important;
  }
  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }
  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }
  .sm\:bg-cover {
    background-size: cover !important;
  }
  .sm\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }
  .md\:bg-cover {
    background-size: cover !important;
  }
  .md\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }
  .lg\:bg-cover {
    background-size: cover !important;
  }
  .lg\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }
  .xl\:bg-cover {
    background-size: cover !important;
  }
  .xl\:bg-contain {
    background-size: contain !important;
  }
}
.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }
  .sm\:bg-center {
    background-position: center !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .sm\:bg-left-top {
    background-position: left top !important;
  }
  .sm\:bg-right {
    background-position: right !important;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .sm\:bg-right-top {
    background-position: right top !important;
  }
  .sm\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }
  .md\:bg-center {
    background-position: center !important;
  }
  .md\:bg-left {
    background-position: left !important;
  }
  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .md\:bg-left-top {
    background-position: left top !important;
  }
  .md\:bg-right {
    background-position: right !important;
  }
  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .md\:bg-right-top {
    background-position: right top !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }
  .lg\:bg-center {
    background-position: center !important;
  }
  .lg\:bg-left {
    background-position: left !important;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .lg\:bg-left-top {
    background-position: left top !important;
  }
  .lg\:bg-right {
    background-position: right !important;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .lg\:bg-right-top {
    background-position: right top !important;
  }
  .lg\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }
  .xl\:bg-center {
    background-position: center !important;
  }
  .xl\:bg-left {
    background-position: left !important;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xl\:bg-left-top {
    background-position: left top !important;
  }
  .xl\:bg-right {
    background-position: right !important;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xl\:bg-right-top {
    background-position: right top !important;
  }
  .xl\:bg-top {
    background-position: top !important;
  }
}
.select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  -webkit-transition-property: none !important;
  transition-property: none !important;
}

.transition-all {
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.transition-colors {
  -webkit-transition-property: background-color,border-color,color !important;
  transition-property: background-color,border-color,color !important;
}

.transition-transform {
  -webkit-transition-property: -webkit-transform !important;
  transition-property: -webkit-transform !important;
  transition-property: transform !important;
  transition-property: transform, -webkit-transform !important;
}

.transition-duration-100 {
  -webkit-transition-duration: 100ms !important;
          transition-duration: 100ms !important;
}

.transition-duration-150 {
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important;
}

.transition-duration-200 {
  -webkit-transition-duration: 200ms !important;
          transition-duration: 200ms !important;
}

.transition-duration-300 {
  -webkit-transition-duration: 300ms !important;
          transition-duration: 300ms !important;
}

.transition-duration-400 {
  -webkit-transition-duration: 400ms !important;
          transition-duration: 400ms !important;
}

.transition-duration-500 {
  -webkit-transition-duration: 500ms !important;
          transition-duration: 500ms !important;
}

.transition-duration-1000 {
  -webkit-transition-duration: 1000ms !important;
          transition-duration: 1000ms !important;
}

.transition-duration-2000 {
  -webkit-transition-duration: 2000ms !important;
          transition-duration: 2000ms !important;
}

.transition-duration-3000 {
  -webkit-transition-duration: 3000ms !important;
          transition-duration: 3000ms !important;
}

.transition-linear {
  -webkit-transition-timing-function: linear !important;
          transition-timing-function: linear !important;
}

.transition-ease-in {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  -webkit-transition-delay: 100ms !important;
          transition-delay: 100ms !important;
}

.transition-delay-150 {
  -webkit-transition-delay: 150ms !important;
          transition-delay: 150ms !important;
}

.transition-delay-200 {
  -webkit-transition-delay: 200ms !important;
          transition-delay: 200ms !important;
}

.transition-delay-300 {
  -webkit-transition-delay: 300ms !important;
          transition-delay: 300ms !important;
}

.transition-delay-400 {
  -webkit-transition-delay: 400ms !important;
          transition-delay: 400ms !important;
}

.transition-delay-500 {
  -webkit-transition-delay: 500ms !important;
          transition-delay: 500ms !important;
}

.transition-delay-1000 {
  -webkit-transition-delay: 1000ms !important;
          transition-delay: 1000ms !important;
}

.translate-x-0 {
  -webkit-transform: translateX(0%) !important;
          transform: translateX(0%) !important;
}

.translate-x-100 {
  -webkit-transform: translateX(100%) !important;
          transform: translateX(100%) !important;
}

.-translate-x-100 {
  -webkit-transform: translateX(-100%) !important;
          transform: translateX(-100%) !important;
}

.translate-y-0 {
  -webkit-transform: translateY(0%) !important;
          transform: translateY(0%) !important;
}

.translate-y-100 {
  -webkit-transform: translateY(100%) !important;
          transform: translateY(100%) !important;
}

.-translate-y-100 {
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important;
  }
  .sm\:translate-x-100 {
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
  }
  .sm\:-translate-x-100 {
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
  }
  .sm\:translate-y-0 {
    -webkit-transform: translateY(0%) !important;
            transform: translateY(0%) !important;
  }
  .sm\:translate-y-100 {
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important;
  }
  .sm\:-translate-y-100 {
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important;
  }
  .md\:translate-x-100 {
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
  }
  .md\:-translate-x-100 {
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
  }
  .md\:translate-y-0 {
    -webkit-transform: translateY(0%) !important;
            transform: translateY(0%) !important;
  }
  .md\:translate-y-100 {
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important;
  }
  .md\:-translate-y-100 {
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important;
  }
  .lg\:translate-x-100 {
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
  }
  .lg\:-translate-x-100 {
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
  }
  .lg\:translate-y-0 {
    -webkit-transform: translateY(0%) !important;
            transform: translateY(0%) !important;
  }
  .lg\:translate-y-100 {
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important;
  }
  .lg\:-translate-y-100 {
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important;
  }
  .xl\:translate-x-100 {
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
  }
  .xl\:-translate-x-100 {
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
  }
  .xl\:translate-y-0 {
    -webkit-transform: translateY(0%) !important;
            transform: translateY(0%) !important;
  }
  .xl\:translate-y-100 {
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important;
  }
  .xl\:-translate-y-100 {
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
  }
}
.rotate-45 {
  -webkit-transform: rotate(45deg) !important;
          transform: rotate(45deg) !important;
}

.-rotate-45 {
  -webkit-transform: rotate(-45deg) !important;
          transform: rotate(-45deg) !important;
}

.rotate-90 {
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important;
}

.-rotate-90 {
  -webkit-transform: rotate(-90deg) !important;
          transform: rotate(-90deg) !important;
}

.rotate-180 {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}

.-rotate-180 {
  -webkit-transform: rotate(-180deg) !important;
          transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }
  .sm\:-rotate-45 {
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
  }
  .sm\:rotate-90 {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
  }
  .sm\:-rotate-90 {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
  }
  .sm\:rotate-180 {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
  }
  .sm\:-rotate-180 {
    -webkit-transform: rotate(-180deg) !important;
            transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }
  .md\:-rotate-45 {
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
  }
  .md\:rotate-90 {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
  }
  .md\:-rotate-90 {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
  }
  .md\:rotate-180 {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
  }
  .md\:-rotate-180 {
    -webkit-transform: rotate(-180deg) !important;
            transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }
  .lg\:-rotate-45 {
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
  }
  .lg\:rotate-90 {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
  }
  .lg\:-rotate-90 {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
  }
  .lg\:rotate-180 {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
  }
  .lg\:-rotate-180 {
    -webkit-transform: rotate(-180deg) !important;
            transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }
  .xl\:-rotate-45 {
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
  }
  .xl\:rotate-90 {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
  }
  .xl\:-rotate-90 {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
  }
  .xl\:rotate-180 {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
  }
  .xl\:-rotate-180 {
    -webkit-transform: rotate(-180deg) !important;
            transform: rotate(-180deg) !important;
  }
}
.origin-center {
  -webkit-transform-origin: center !important;
          transform-origin: center !important;
}

.origin-top {
  -webkit-transform-origin: top !important;
          transform-origin: top !important;
}

.origin-top-right {
  -webkit-transform-origin: top right !important;
          transform-origin: top right !important;
}

.origin-right {
  -webkit-transform-origin: right !important;
          transform-origin: right !important;
}

.origin-bottom-right {
  -webkit-transform-origin: bottom right !important;
          transform-origin: bottom right !important;
}

.origin-bottom {
  -webkit-transform-origin: bottom !important;
          transform-origin: bottom !important;
}

.origin-bottom-left {
  -webkit-transform-origin: bottom left !important;
          transform-origin: bottom left !important;
}

.origin-left {
  -webkit-transform-origin: left !important;
          transform-origin: left !important;
}

.origin-top-left {
  -webkit-transform-origin: top-left !important;
          transform-origin: top-left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    -webkit-transform-origin: center !important;
            transform-origin: center !important;
  }
  .sm\:origin-top {
    -webkit-transform-origin: top !important;
            transform-origin: top !important;
  }
  .sm\:origin-top-right {
    -webkit-transform-origin: top right !important;
            transform-origin: top right !important;
  }
  .sm\:origin-right {
    -webkit-transform-origin: right !important;
            transform-origin: right !important;
  }
  .sm\:origin-bottom-right {
    -webkit-transform-origin: bottom right !important;
            transform-origin: bottom right !important;
  }
  .sm\:origin-bottom {
    -webkit-transform-origin: bottom !important;
            transform-origin: bottom !important;
  }
  .sm\:origin-bottom-left {
    -webkit-transform-origin: bottom left !important;
            transform-origin: bottom left !important;
  }
  .sm\:origin-left {
    -webkit-transform-origin: left !important;
            transform-origin: left !important;
  }
  .sm\:origin-top-left {
    -webkit-transform-origin: top-left !important;
            transform-origin: top-left !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:origin-center {
    -webkit-transform-origin: center !important;
            transform-origin: center !important;
  }
  .md\:origin-top {
    -webkit-transform-origin: top !important;
            transform-origin: top !important;
  }
  .md\:origin-top-right {
    -webkit-transform-origin: top right !important;
            transform-origin: top right !important;
  }
  .md\:origin-right {
    -webkit-transform-origin: right !important;
            transform-origin: right !important;
  }
  .md\:origin-bottom-right {
    -webkit-transform-origin: bottom right !important;
            transform-origin: bottom right !important;
  }
  .md\:origin-bottom {
    -webkit-transform-origin: bottom !important;
            transform-origin: bottom !important;
  }
  .md\:origin-bottom-left {
    -webkit-transform-origin: bottom left !important;
            transform-origin: bottom left !important;
  }
  .md\:origin-left {
    -webkit-transform-origin: left !important;
            transform-origin: left !important;
  }
  .md\:origin-top-left {
    -webkit-transform-origin: top-left !important;
            transform-origin: top-left !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:origin-center {
    -webkit-transform-origin: center !important;
            transform-origin: center !important;
  }
  .lg\:origin-top {
    -webkit-transform-origin: top !important;
            transform-origin: top !important;
  }
  .lg\:origin-top-right {
    -webkit-transform-origin: top right !important;
            transform-origin: top right !important;
  }
  .lg\:origin-right {
    -webkit-transform-origin: right !important;
            transform-origin: right !important;
  }
  .lg\:origin-bottom-right {
    -webkit-transform-origin: bottom right !important;
            transform-origin: bottom right !important;
  }
  .lg\:origin-bottom {
    -webkit-transform-origin: bottom !important;
            transform-origin: bottom !important;
  }
  .lg\:origin-bottom-left {
    -webkit-transform-origin: bottom left !important;
            transform-origin: bottom left !important;
  }
  .lg\:origin-left {
    -webkit-transform-origin: left !important;
            transform-origin: left !important;
  }
  .lg\:origin-top-left {
    -webkit-transform-origin: top-left !important;
            transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    -webkit-transform-origin: center !important;
            transform-origin: center !important;
  }
  .xl\:origin-top {
    -webkit-transform-origin: top !important;
            transform-origin: top !important;
  }
  .xl\:origin-top-right {
    -webkit-transform-origin: top right !important;
            transform-origin: top right !important;
  }
  .xl\:origin-right {
    -webkit-transform-origin: right !important;
            transform-origin: right !important;
  }
  .xl\:origin-bottom-right {
    -webkit-transform-origin: bottom right !important;
            transform-origin: bottom right !important;
  }
  .xl\:origin-bottom {
    -webkit-transform-origin: bottom !important;
            transform-origin: bottom !important;
  }
  .xl\:origin-bottom-left {
    -webkit-transform-origin: bottom left !important;
            transform-origin: bottom left !important;
  }
  .xl\:origin-left {
    -webkit-transform-origin: left !important;
            transform-origin: left !important;
  }
  .xl\:origin-top-left {
    -webkit-transform-origin: top-left !important;
            transform-origin: top-left !important;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@-webkit-keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@-webkit-keyframes fadeinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes fadeoutleft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes fadeoutleft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@-webkit-keyframes fadeinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes fadeinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes fadeoutright {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes fadeoutright {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@-webkit-keyframes fadeinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@-webkit-keyframes fadeoutup {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes fadeoutup {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@-webkit-keyframes fadeindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes fadeindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@-webkit-keyframes fadeoutdown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(2000px) rotateX(-100deg);
            transform: perspective(2000px) rotateX(-100deg);
  }
  to {
    -webkit-transform: perspective(2000px) rotateX(0);
            transform: perspective(2000px) rotateX(0);
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(2000px) rotateX(-100deg);
            transform: perspective(2000px) rotateX(-100deg);
  }
  to {
    -webkit-transform: perspective(2000px) rotateX(0);
            transform: perspective(2000px) rotateX(0);
  }
}
@-webkit-keyframes flipleft {
  from {
    -webkit-transform: perspective(2000px) rotateY(-100deg);
            transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateY(0);
            transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipleft {
  from {
    -webkit-transform: perspective(2000px) rotateY(-100deg);
            transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateY(0);
            transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes flipright {
  from {
    -webkit-transform: perspective(2000px) rotateY(100deg);
            transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateY(0);
            transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipright {
  from {
    -webkit-transform: perspective(2000px) rotateY(100deg);
            transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateY(0);
            transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes flipup {
  from {
    -webkit-transform: perspective(2000px) rotateX(-100deg);
            transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateX(0);
            transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}
@keyframes flipup {
  from {
    -webkit-transform: perspective(2000px) rotateX(-100deg);
            transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }
  to {
    -webkit-transform: perspective(2000px) rotateX(0);
            transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes zoomin {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomin {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes zoomindown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@keyframes zoomindown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@-webkit-keyframes zoominleft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@keyframes zoominleft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes zoominright {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@keyframes zoominright {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@-webkit-keyframes zoominup {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@keyframes zoominup {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
.fadein {
  -webkit-animation: fadein 0.15s linear;
          animation: fadein 0.15s linear;
}

.fadeout {
  -webkit-animation: fadeout 0.15s linear;
          animation: fadeout 0.15s linear;
}

.slidedown {
  -webkit-animation: slidedown 0.45s ease-in-out;
          animation: slidedown 0.45s ease-in-out;
}

.slideup {
  -webkit-animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
          animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  -webkit-animation: scalein 0.15s linear;
          animation: scalein 0.15s linear;
}

.fadeinleft {
  -webkit-animation: fadeinleft 0.15s linear;
          animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  -webkit-animation: fadeoutleft 0.15s linear;
          animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  -webkit-animation: fadeinright 0.15s linear;
          animation: fadeinright 0.15s linear;
}

.fadeoutright {
  -webkit-animation: fadeoutright 0.15s linear;
          animation: fadeoutright 0.15s linear;
}

.fadeinup {
  -webkit-animation: fadeinup 0.15s linear;
          animation: fadeinup 0.15s linear;
}

.fadeoutup {
  -webkit-animation: fadeoutup 0.15s linear;
          animation: fadeoutup 0.15s linear;
}

.fadeindown {
  -webkit-animation: fadeindown 0.15s linear;
          animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  -webkit-animation: fadeoutdown 0.15s linear;
          animation: fadeoutdown 0.15s linear;
}

.animate-width {
  -webkit-animation: animate-width 1000ms linear;
          animation: animate-width 1000ms linear;
}

.flip {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation: flip 0.15s linear;
          animation: flip 0.15s linear;
}

.flipup {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation: flipup 0.15s linear;
          animation: flipup 0.15s linear;
}

.flipleft {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation: flipleft 0.15s linear;
          animation: flipleft 0.15s linear;
}

.flipright {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation: flipright 0.15s linear;
          animation: flipright 0.15s linear;
}

.zoomin {
  -webkit-animation: zoomin 0.15s linear;
          animation: zoomin 0.15s linear;
}

.zoomindown {
  -webkit-animation: zoomindown 0.15s linear;
          animation: zoomindown 0.15s linear;
}

.zoominleft {
  -webkit-animation: zoominleft 0.15s linear;
          animation: zoominleft 0.15s linear;
}

.zoominright {
  -webkit-animation: zoominright 0.15s linear;
          animation: zoominright 0.15s linear;
}

.zoominup {
  -webkit-animation: zoominup 0.15s linear;
          animation: zoominup 0.15s linear;
}

.animation-duration-100 {
  -webkit-animation-duration: 100ms !important;
          animation-duration: 100ms !important;
}

.animation-duration-150 {
  -webkit-animation-duration: 150ms !important;
          animation-duration: 150ms !important;
}

.animation-duration-200 {
  -webkit-animation-duration: 200ms !important;
          animation-duration: 200ms !important;
}

.animation-duration-300 {
  -webkit-animation-duration: 300ms !important;
          animation-duration: 300ms !important;
}

.animation-duration-400 {
  -webkit-animation-duration: 400ms !important;
          animation-duration: 400ms !important;
}

.animation-duration-500 {
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
}

.animation-duration-1000 {
  -webkit-animation-duration: 1000ms !important;
          animation-duration: 1000ms !important;
}

.animation-duration-2000 {
  -webkit-animation-duration: 2000ms !important;
          animation-duration: 2000ms !important;
}

.animation-duration-3000 {
  -webkit-animation-duration: 3000ms !important;
          animation-duration: 3000ms !important;
}

.animation-delay-100 {
  -webkit-animation-delay: 100ms !important;
          animation-delay: 100ms !important;
}

.animation-delay-150 {
  -webkit-animation-delay: 150ms !important;
          animation-delay: 150ms !important;
}

.animation-delay-200 {
  -webkit-animation-delay: 200ms !important;
          animation-delay: 200ms !important;
}

.animation-delay-300 {
  -webkit-animation-delay: 300ms !important;
          animation-delay: 300ms !important;
}

.animation-delay-400 {
  -webkit-animation-delay: 400ms !important;
          animation-delay: 400ms !important;
}

.animation-delay-500 {
  -webkit-animation-delay: 500ms !important;
          animation-delay: 500ms !important;
}

.animation-delay-1000 {
  -webkit-animation-delay: 1000ms !important;
          animation-delay: 1000ms !important;
}

.animation-iteration-1 {
  -webkit-animation-iteration-count: 1 !important;
          animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  -webkit-animation-iteration-count: 2 !important;
          animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

.animation-linear {
  -webkit-animation-timing-function: linear !important;
          animation-timing-function: linear !important;
}

.animation-ease-in {
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
          animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  -webkit-animation-fill-mode: none !important;
          animation-fill-mode: none !important;
}

.animation-fill-forwards {
  -webkit-animation-fill-mode: forwards !important;
          animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  -webkit-animation-fill-mode: backwards !important;
          animation-fill-mode: backwards !important;
}

.animation-fill-both {
  -webkit-animation-fill-mode: both !important;
          animation-fill-mode: both !important;
}

@font-face {
    font-family: 'primeicons';
    font-display: block;
    src: url(/_next/static/media/primeicons.8ca441e1.eot);
    src: url(/_next/static/media/primeicons.8ca441e1.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/primeicons.e1a53edb.woff2) format('woff2'), url(/_next/static/media/primeicons.7f772274.woff) format('woff'), url(/_next/static/media/primeicons.310a7310.ttf) format('truetype'), url(/_next/static/media/primeicons.19e14e48.svg?#primeicons) format('svg');
    font-weight: normal;
    font-style: normal;
}

.pi {
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pi:before {
    --webkit-backface-visibility:hidden;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.pi-fw {
    width: 1.28571429em;
    text-align: center;
}

.pi-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

@media (prefers-reduced-motion: reduce) {
  .pi-spin {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.pi-folder-plus:before {
    content: "\ea05";
}

.pi-receipt:before {
    content: "\ea06";
}

.pi-asterisk:before {
    content: "\ea07";
}

.pi-face-smile:before {
    content: "\ea08";
}

.pi-pinterest:before {
    content: "\ea09";
}

.pi-expand:before {
    content: "\ea0a";
}

.pi-pen-to-square:before {
    content: "\ea0b";
}

.pi-wave-pulse:before {
    content: "\ea0c";
}

.pi-turkish-lira:before {
    content: "\ea0d";
}

.pi-spinner-dotted:before {
    content: "\ea0e";
}

.pi-crown:before {
    content: "\ea0f";
}

.pi-pause-circle:before {
    content: "\ea10";
}

.pi-warehouse:before {
    content: "\ea11";
}

.pi-objects-column:before {
    content: "\ea12";
}

.pi-clipboard:before {
    content: "\ea13";
}

.pi-play-circle:before {
    content: "\ea14";
}

.pi-venus:before {
    content: "\ea15";
}

.pi-cart-minus:before {
    content: "\ea16";
}

.pi-file-plus:before {
    content: "\ea17";
}

.pi-microchip:before {
    content: "\ea18";
}

.pi-twitch:before {
    content: "\ea19";
}

.pi-building-columns:before {
    content: "\ea1a";
}

.pi-file-check:before {
    content: "\ea1b";
}

.pi-microchip-ai:before {
    content: "\ea1c";
}

.pi-trophy:before {
    content: "\ea1d";
}

.pi-barcode:before {
    content: "\ea1e";
}

.pi-file-arrow-up:before {
    content: "\ea1f";
}

.pi-mars:before {
    content: "\ea20";
}

.pi-tiktok:before {
    content: "\ea21";
}

.pi-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\ea22";
}

.pi-ethereum:before {
    content: "\ea23";
}

.pi-list-check:before {
    content: "\ea24";
}

.pi-thumbtack:before {
    content: "\ea25";
}

.pi-arrow-down-left-and-arrow-up-right-to-center:before {
    content: "\ea26";
}

.pi-equals:before {
    content: "\ea27";
}

.pi-lightbulb:before {
    content: "\ea28";
}

.pi-star-half:before {
    content: "\ea29";
}

.pi-address-book:before {
    content: "\ea2a";
}

.pi-chart-scatter:before {
    content: "\ea2b";
}

.pi-indian-rupee:before {
    content: "\ea2c";
}

.pi-star-half-fill:before {
    content: "\ea2d";
}

.pi-cart-arrow-down:before {
    content: "\ea2e";
}

.pi-calendar-clock:before {
    content: "\ea2f";
}

.pi-sort-up-fill:before {
    content: "\ea30";
}

.pi-sparkles:before {
    content: "\ea31";
}

.pi-bullseye:before {
    content: "\ea32";
}

.pi-sort-down-fill:before {
    content: "\ea33";
}

.pi-graduation-cap:before {
    content: "\ea34";
}

.pi-hammer:before {
    content: "\ea35";
}

.pi-bell-slash:before {
    content: "\ea36";
}

.pi-gauge:before {
    content: "\ea37";
}

.pi-shop:before {
    content: "\ea38";
}

.pi-headphones:before {
    content: "\ea39";
}

.pi-eraser:before {
    content: "\ea04";
}

.pi-stopwatch:before {
    content: "\ea01";
}

.pi-verified:before {
    content: "\ea02";
}

.pi-delete-left:before {
    content: "\ea03";
}

.pi-hourglass:before {
    content: "\e9fe";
}

.pi-truck:before {
    content: "\ea00";
}

.pi-wrench:before {
    content: "\e9ff";
}

.pi-microphone:before {
    content: "\e9fa";
}

.pi-megaphone:before {
    content: "\e9fb";
}

.pi-arrow-right-arrow-left:before {
    content: "\e9fc";
}

.pi-bitcoin:before {
    content: "\e9fd";
}

.pi-file-edit:before {
    content: "\e9f6";
}

.pi-language:before {
    content: "\e9f7";
}

.pi-file-export:before {
    content: "\e9f8";
}

.pi-file-import:before {
    content: "\e9f9";
}

.pi-file-word:before {
    content: "\e9f1";
}

.pi-gift:before {
    content: "\e9f2";
}

.pi-cart-plus:before {
    content: "\e9f3";
}

.pi-thumbs-down-fill:before {
    content: "\e9f4";
}

.pi-thumbs-up-fill:before {
    content: "\e9f5";
}

.pi-arrows-alt:before {
    content: "\e9f0";
}

.pi-calculator:before {
    content: "\e9ef";
}

.pi-sort-alt-slash:before {
    content: "\e9ee";
}

.pi-arrows-h:before {
    content: "\e9ec";
}

.pi-arrows-v:before {
    content: "\e9ed";
}

.pi-pound:before {
    content: "\e9eb";
}

.pi-prime:before {
    content: "\e9ea";
}

.pi-chart-pie:before {
    content: "\e9e9";
}

.pi-reddit:before {
    content: "\e9e8";
}

.pi-code:before {
    content: "\e9e7";
}

.pi-sync:before {
    content: "\e9e6";
}

.pi-shopping-bag:before {
    content: "\e9e5";
}

.pi-server:before {
    content: "\e9e4";
}

.pi-database:before {
    content: "\e9e3";
}

.pi-hashtag:before {
    content: "\e9e2";
}

.pi-bookmark-fill:before {
    content: "\e9df";
}

.pi-filter-fill:before {
    content: "\e9e0";
}

.pi-heart-fill:before {
    content: "\e9e1";
}

.pi-flag-fill:before {
    content: "\e9de";
}

.pi-circle:before {
    content: "\e9dc";
}

.pi-circle-fill:before {
    content: "\e9dd";
}

.pi-bolt:before {
    content: "\e9db";
}

.pi-history:before {
    content: "\e9da";
}

.pi-box:before {
    content: "\e9d9";
}

.pi-at:before {
    content: "\e9d8";
}

.pi-arrow-up-right:before {
    content: "\e9d4";
}

.pi-arrow-up-left:before {
    content: "\e9d5";
}

.pi-arrow-down-left:before {
    content: "\e9d6";
}

.pi-arrow-down-right:before {
    content: "\e9d7";
}

.pi-telegram:before {
    content: "\e9d3";
}

.pi-stop-circle:before {
    content: "\e9d2";
}

.pi-stop:before {
    content: "\e9d1";
}

.pi-whatsapp:before {
    content: "\e9d0";
}

.pi-building:before {
    content: "\e9cf";
}

.pi-qrcode:before {
    content: "\e9ce";
}

.pi-car:before {
    content: "\e9cd";
}

.pi-instagram:before {
    content: "\e9cc";
}

.pi-linkedin:before {
    content: "\e9cb";
}

.pi-send:before {
    content: "\e9ca";
}

.pi-slack:before {
    content: "\e9c9";
}

.pi-sun:before {
    content: "\e9c8";
}

.pi-moon:before {
    content: "\e9c7";
}

.pi-vimeo:before {
    content: "\e9c6";
}

.pi-youtube:before {
    content: "\e9c5";
}

.pi-flag:before {
    content: "\e9c4";
}

.pi-wallet:before {
    content: "\e9c3";
}

.pi-map:before {
    content: "\e9c2";
}

.pi-link:before {
    content: "\e9c1";
}

.pi-credit-card:before {
    content: "\e9bf";
}

.pi-discord:before {
    content: "\e9c0";
}

.pi-percentage:before {
    content: "\e9be";
}

.pi-euro:before {
    content: "\e9bd";
}

.pi-book:before {
    content: "\e9ba";
}

.pi-shield:before {
    content: "\e9b9";
}

.pi-paypal:before {
    content: "\e9bb";
}

.pi-amazon:before {
    content: "\e9bc";
}

.pi-phone:before {
    content: "\e9b8";
}

.pi-filter-slash:before {
    content: "\e9b7";
}

.pi-facebook:before {
    content: "\e9b4";
}

.pi-github:before {
    content: "\e9b5";
}

.pi-twitter:before {
    content: "\e9b6";
}

.pi-step-backward-alt:before {
    content: "\e9ac";
}

.pi-step-forward-alt:before {
    content: "\e9ad";
}

.pi-forward:before {
    content: "\e9ae";
}

.pi-backward:before {
    content: "\e9af";
}

.pi-fast-backward:before {
    content: "\e9b0";
}

.pi-fast-forward:before {
    content: "\e9b1";
}

.pi-pause:before {
    content: "\e9b2";
}

.pi-play:before {
    content: "\e9b3";
}

.pi-compass:before {
    content: "\e9ab";
}

.pi-id-card:before {
    content: "\e9aa";
}

.pi-ticket:before {
    content: "\e9a9";
}

.pi-file-o:before {
    content: "\e9a8";
}

.pi-reply:before {
    content: "\e9a7";
}

.pi-directions-alt:before {
    content: "\e9a5";
}

.pi-directions:before {
    content: "\e9a6";
}

.pi-thumbs-up:before {
    content: "\e9a3";
}

.pi-thumbs-down:before {
    content: "\e9a4";
}

.pi-sort-numeric-down-alt:before {
    content: "\e996";
}

.pi-sort-numeric-up-alt:before {
    content: "\e997";
}

.pi-sort-alpha-down-alt:before {
    content: "\e998";
}

.pi-sort-alpha-up-alt:before {
    content: "\e999";
}

.pi-sort-numeric-down:before {
    content: "\e99a";
}

.pi-sort-numeric-up:before {
    content: "\e99b";
}

.pi-sort-alpha-down:before {
    content: "\e99c";
}

.pi-sort-alpha-up:before {
    content: "\e99d";
}

.pi-sort-alt:before {
    content: "\e99e";
}

.pi-sort-amount-up:before {
    content: "\e99f";
}

.pi-sort-amount-down:before {
    content: "\e9a0";
}

.pi-sort-amount-down-alt:before {
    content: "\e9a1";
}

.pi-sort-amount-up-alt:before {
    content: "\e9a2";
}

.pi-palette:before {
    content: "\e995";
}

.pi-undo:before {
    content: "\e994";
}

.pi-desktop:before {
    content: "\e993";
}

.pi-sliders-v:before {
    content: "\e991";
}

.pi-sliders-h:before {
    content: "\e992";
}

.pi-search-plus:before {
    content: "\e98f";
}

.pi-search-minus:before {
    content: "\e990";
}

.pi-file-excel:before {
    content: "\e98e";
}

.pi-file-pdf:before {
    content: "\e98d";
}

.pi-check-square:before {
    content: "\e98c";
}

.pi-chart-line:before {
    content: "\e98b";
}

.pi-user-edit:before {
    content: "\e98a";
}

.pi-exclamation-circle:before {
    content: "\e989";
}

.pi-android:before {
    content: "\e985";
}

.pi-google:before {
    content: "\e986";
}

.pi-apple:before {
    content: "\e987";
}

.pi-microsoft:before {
    content: "\e988";
}

.pi-heart:before {
    content: "\e984";
}

.pi-mobile:before {
    content: "\e982";
}

.pi-tablet:before {
    content: "\e983";
}

.pi-key:before {
    content: "\e981";
}

.pi-shopping-cart:before {
    content: "\e980";
}

.pi-comments:before {
    content: "\e97e";
}

.pi-comment:before {
    content: "\e97f";
}

.pi-briefcase:before {
    content: "\e97d";
}

.pi-bell:before {
    content: "\e97c";
}

.pi-paperclip:before {
    content: "\e97b";
}

.pi-share-alt:before {
    content: "\e97a";
}

.pi-envelope:before {
    content: "\e979";
}

.pi-volume-down:before {
    content: "\e976";
}

.pi-volume-up:before {
    content: "\e977";
}

.pi-volume-off:before {
    content: "\e978";
}

.pi-eject:before {
    content: "\e975";
}

.pi-money-bill:before {
    content: "\e974";
}

.pi-images:before {
    content: "\e973";
}

.pi-image:before {
    content: "\e972";
}

.pi-sign-in:before {
    content: "\e970";
}

.pi-sign-out:before {
    content: "\e971";
}

.pi-wifi:before {
    content: "\e96f";
}

.pi-sitemap:before {
    content: "\e96e";
}

.pi-chart-bar:before {
    content: "\e96d";
}

.pi-camera:before {
    content: "\e96c";
}

.pi-dollar:before {
    content: "\e96b";
}

.pi-lock-open:before {
    content: "\e96a";
}

.pi-table:before {
    content: "\e969";
}

.pi-map-marker:before {
    content: "\e968";
}

.pi-list:before {
    content: "\e967";
}

.pi-eye-slash:before {
    content: "\e965";
}

.pi-eye:before {
    content: "\e966";
}

.pi-folder-open:before {
    content: "\e964";
}

.pi-folder:before {
    content: "\e963";
}

.pi-video:before {
    content: "\e962";
}

.pi-inbox:before {
    content: "\e961";
}

.pi-lock:before {
    content: "\e95f";
}

.pi-unlock:before {
    content: "\e960";
}

.pi-tags:before {
    content: "\e95d";
}

.pi-tag:before {
    content: "\e95e";
}

.pi-power-off:before {
    content: "\e95c";
}

.pi-save:before {
    content: "\e95b";
}

.pi-question-circle:before {
    content: "\e959";
}

.pi-question:before {
    content: "\e95a";
}

.pi-copy:before {
    content: "\e957";
}

.pi-file:before {
    content: "\e958";
}

.pi-clone:before {
    content: "\e955";
}

.pi-calendar-times:before {
    content: "\e952";
}

.pi-calendar-minus:before {
    content: "\e953";
}

.pi-calendar-plus:before {
    content: "\e954";
}

.pi-ellipsis-v:before {
    content: "\e950";
}

.pi-ellipsis-h:before {
    content: "\e951";
}

.pi-bookmark:before {
    content: "\e94e";
}

.pi-globe:before {
    content: "\e94f";
}

.pi-replay:before {
    content: "\e94d";
}

.pi-filter:before {
    content: "\e94c";
}

.pi-print:before {
    content: "\e94b";
}

.pi-align-right:before {
    content: "\e946";
}

.pi-align-left:before {
    content: "\e947";
}

.pi-align-center:before {
    content: "\e948";
}

.pi-align-justify:before {
    content: "\e949";
}

.pi-cog:before {
    content: "\e94a";
}

.pi-cloud-download:before {
    content: "\e943";
}

.pi-cloud-upload:before {
    content: "\e944";
}

.pi-cloud:before {
    content: "\e945";
}

.pi-pencil:before {
    content: "\e942";
}

.pi-users:before {
    content: "\e941";
}

.pi-clock:before {
    content: "\e940";
}

.pi-user-minus:before {
    content: "\e93e";
}

.pi-user-plus:before {
    content: "\e93f";
}

.pi-trash:before {
    content: "\e93d";
}

.pi-external-link:before {
    content: "\e93c";
}

.pi-window-maximize:before {
    content: "\e93b";
}

.pi-window-minimize:before {
    content: "\e93a";
}

.pi-refresh:before {
    content: "\e938";
}
  
.pi-user:before {
    content: "\e939";
}

.pi-exclamation-triangle:before {
    content: "\e922";
}

.pi-calendar:before {
    content: "\e927";
}

.pi-chevron-circle-left:before {
    content: "\e928";
}

.pi-chevron-circle-down:before {
    content: "\e929";
}

.pi-chevron-circle-right:before {
    content: "\e92a";
}

.pi-chevron-circle-up:before {
    content: "\e92b";
}

.pi-angle-double-down:before {
    content: "\e92c";
}

.pi-angle-double-left:before {
    content: "\e92d";
}

.pi-angle-double-right:before {
    content: "\e92e";
}

.pi-angle-double-up:before {
    content: "\e92f";
}

.pi-angle-down:before {
    content: "\e930";
}

.pi-angle-left:before {
    content: "\e931";
}

.pi-angle-right:before {
    content: "\e932";
}

.pi-angle-up:before {
    content: "\e933";
}

.pi-upload:before {
    content: "\e934";
}

.pi-download:before {
    content: "\e956";
}

.pi-ban:before {
    content: "\e935";
}

.pi-star-fill:before {
    content: "\e936";
}

.pi-star:before {
    content: "\e937";
}

.pi-chevron-left:before {
    content: "\e900";
}

.pi-chevron-right:before {
    content: "\e901";
}

.pi-chevron-down:before {
    content: "\e902";
}

.pi-chevron-up:before {
    content: "\e903";
}

.pi-caret-left:before {
    content: "\e904";
}

.pi-caret-right:before {
    content: "\e905";
}

.pi-caret-down:before {
    content: "\e906";
}

.pi-caret-up:before {
    content: "\e907";
}

.pi-search:before {
    content: "\e908";
}

.pi-check:before {
    content: "\e909";
}

.pi-check-circle:before {
    content: "\e90a";
}

.pi-times:before {
    content: "\e90b";
}

.pi-times-circle:before {
    content: "\e90c";
}

.pi-plus:before {
    content: "\e90d";
}

.pi-plus-circle:before {
    content: "\e90e";
}

.pi-minus:before {
    content: "\e90f";
}

.pi-minus-circle:before {
    content: "\e910";
}

.pi-circle-on:before {
    content: "\e911";
}

.pi-circle-off:before {
    content: "\e912";
}

.pi-sort-down:before {
    content: "\e913";
}

.pi-sort-up:before {
    content: "\e914";
}

.pi-sort:before {
    content: "\e915";
}

.pi-step-backward:before {
    content: "\e916";
}

.pi-step-forward:before {
    content: "\e917";
}

.pi-th-large:before {
    content: "\e918";
}

.pi-arrow-down:before {
    content: "\e919";
}

.pi-arrow-left:before {
    content: "\e91a";
}

.pi-arrow-right:before {
    content: "\e91b";
}

.pi-arrow-up:before {
    content: "\e91c";
}

.pi-bars:before {
    content: "\e91d";
}

.pi-arrow-circle-down:before {
    content: "\e91e";
}

.pi-arrow-circle-left:before {
    content: "\e91f";
}

.pi-arrow-circle-right:before {
    content: "\e920";
}

.pi-arrow-circle-up:before {
    content: "\e921";
}

.pi-info:before {
    content: "\e923";
}

.pi-info-circle:before {
    content: "\e924";
}

.pi-home:before {
    content: "\e925";
}

.pi-spinner:before {
    content: "\e926";
}

/**
 * The primereact[.min].css has been deprecated. In order not to break existing projects, it is currently included in the build as an empty file.
 */
:root {
    --surface-a: #ffffff;
    --surface-b: #f9f9f9;
    --surface-c: #e9f7f8;
    --surface-d: #cdcdcd;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #404040;
    --text-color-secondary: #656565;
    --primary-color: #26aebc;
    --primary-color-text: #ffffff;
    --font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --surface-0: #ffffff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: #eeeeee;
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #a1a1a1; /* was #9e9e9e */
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #fafafa;
    --gray-100: #f5f5f5;
    --gray-200: #eeeeee;
    --gray-300: #e0e0e0;
    --gray-400: #bdbdbd;
    --gray-500: #a1a1a1; /* was #9e9e9e */
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 4px;
    --surface-ground: #eff3f8;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dfe7ef;
    --surface-hover: #f6f9fc;
    --focus-ring: 0 0 0 0.2rem #7dced7;
    --maskbg: rgba(0, 0, 0, 0.4);
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: local(""), url(/_next/static/media/Inter-Light.27083fa6.woff2) format("woff2"), url(/_next/static/media/Inter-Light.91dfddd8.woff) format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local(""), url(/_next/static/media/Inter-Regular.b86dc1c7.woff2) format("woff2"), url(/_next/static/media/Inter-Regular.30f6c67c.woff) format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: local(""), url(/_next/static/media/Inter-Medium.82b81898.woff2) format("woff2"), url(/_next/static/media/Inter-Medium.21f51000.woff) format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: local(""), url(/_next/static/media/Inter-SemiBold.e663e519.woff2) format("woff2"), url(/_next/static/media/Inter-SemiBold.4920f759.woff) format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: local(""), url(/_next/static/media/Inter-Bold.b19f66e1.woff2) format("woff2"), url(/_next/static/media/Inter-Bold.495ff245.woff) format("woff");
}
:root {
    --blue-50:#f5f9ff;
    --blue-100:#d0e1fd;
    --blue-200:#abc9fb;
    --blue-300:#85b2f9;
    --blue-400:#609af8;
    --blue-500:#26aebc;
    --blue-600:#326fd1;
    --blue-700:#295bac;
    --blue-800:#204887;
    --blue-900:#183462;
    --green-50:#f4fcf7;
    --green-100:#caf1d8;
    --green-200:#a0e6ba;
    --green-300:#76db9b;
    --green-400:#4cd07d;
    --green-500:#22c55e;
    --green-600:#1da750;
    --green-700:#188a42;
    --green-800:#136c34;
    --green-900:#0e4f26;
    --yellow-50:#fefbf3;
    --yellow-100:#faedc4;
    --yellow-200:#f6de95;
    --yellow-300:#f2d066;
    --yellow-400:#eec137;
    --yellow-500:#eab308;
    --yellow-600:#c79807;
    --yellow-700:#a47d06;
    --yellow-800:#816204;
    --yellow-900:#5e4803;
    --cyan-50:#f3fbfd;
    --cyan-100:#c3edf5;
    --cyan-200:#94e0ed;
    --cyan-300:#65d2e4;
    --cyan-400:#35c4dc;
    --cyan-500:#06b6d4;
    --cyan-600:#059bb4;
    --cyan-700:#047f94;
    --cyan-800:#036475;
    --cyan-900:#024955;
    --pink-50:#fef6fa;
    --pink-100:#fad3e7;
    --pink-200:#f7b0d3;
    --pink-300:#f38ec0;
    --pink-400:#f06bac;
    --pink-500:#ec4899;
    --pink-600:#c93d82;
    --pink-700:#a5326b;
    --pink-800:#822854;
    --pink-900:#5e1d3d;
    --indigo-50:#f7f7fe;
    --indigo-100:#dadafc;
    --indigo-200:#bcbdf9;
    --indigo-300:#9ea0f6;
    --indigo-400:#8183f4;
    --indigo-500:#6366f1;
    --indigo-600:#5457cd;
    --indigo-700:#4547a9;
    --indigo-800:#363885;
    --indigo-900:#282960;
    --teal-50:#f3fbfb;
    --teal-100:#c7eeea;
    --teal-200:#9ae0d9;
    --teal-300:#6dd3c8;
    --teal-400:#41c5b7;
    --teal-500:#14b8a6;
    --teal-600:#119c8d;
    --teal-700:#0e8174;
    --teal-800:#0b655b;
    --teal-900:#084a42;
    --orange-50:#fff8f3;
    --orange-100:#feddc7;
    --orange-200:#fcc39b;
    --orange-300:#fba86f;
    --orange-400:#fa8e42;
    --orange-500:#f97316;
    --orange-600:#d46213;
    --orange-700:#ae510f;
    --orange-800:#893f0c;
    --orange-900:#642e09;
    --bluegray-50:#f7f8f9;
    --bluegray-100:#dadee3;
    --bluegray-200:#bcc3cd;
    --bluegray-300:#9fa9b7;
    --bluegray-400:#818ea1;
    --bluegray-500:#64748b;
    --bluegray-600:#556376;
    --bluegray-700:#465161;
    --bluegray-800:#37404c;
    --bluegray-900:#282e38;
    --purple-50:#fbf7ff;
    --purple-100:#ead6fd;
    --purple-200:#dab6fc;
    --purple-300:#c996fa;
    --purple-400:#b975f9;
    --purple-500:#a855f7;
    --purple-600:#8f48d2;
    --purple-700:#763cad;
    --purple-800:#5c2f88;
    --purple-900:#432263;
    --red-50:#fff5f5;
    --red-100:#ffd0ce;
    --red-200:#ffaca7;
    --red-300:#ff8780;
    --red-400:#ff6259;
    --red-500:#ff3d32;
    --red-600:#d9342b;
    --red-700:#b32b23;
    --red-800:#8c221c;
    --red-900:#661814;
    --primary-50:#f5f9ff;
    --primary-100:#d0e1fd;
    --primary-200:#abc9fb;
    --primary-300:#85b2f9;
    --primary-400:#609af8;
    --primary-500:#26aebc;
    --primary-600:#326fd1;
    --primary-700:#295bac;
    --primary-800:#204887;
    --primary-900:#183462;
}

.p-component:disabled, .p-disabled {
    opacity: .6;
}

input:disabled, input:read-only, textarea:disabled, textarea:read-only {
    background: #efefef !important;
    color: #404040 !important;
}

.p-editor-container .p-editor-toolbar {
    background: #f9fafb;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #e5e7eb;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #6b7280;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #6b7280;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #6b7280;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #ffffff;
    border: 0 none;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0.75rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #404040;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #404040;
    background: #e9f7f8;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.75rem 1.25rem;
}
.p-editor-container .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #e5e7eb;
}
.p-editor-container .p-editor-content .ql-editor {
    background: #ffffff;
    color: #404040;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #404040;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #404040;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #404040;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #26aebc;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #26aebc;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #26aebc;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #26aebc;
}

@layer primereact {
    * {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    .p-component {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 14px;
        font-weight: normal;
    }
    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
    }
    .p-disabled, .p-component:disabled {
        opacity: 0.6;
    }
    .p-error {
        color: #e24c4c;
    }
    .p-text-secondary {
        color: #6b7280;
    }
    .pi {
        font-size: 1rem;
    }
    .p-icon {
        width: 1rem;
        height: 1rem;
    }
    .p-link {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        border-radius: 4px;
    }
    .p-link:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-component-overlay-enter {
        -webkit-animation: p-component-overlay-enter-animation 150ms forwards;
                animation: p-component-overlay-enter-animation 150ms forwards;
    }
    .p-component-overlay-leave {
        -webkit-animation: p-component-overlay-leave-animation 150ms forwards;
                animation: p-component-overlay-leave-animation 150ms forwards;
    }
    @-webkit-keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }
        to {
            background-color: var(--maskbg);
        }
    }
    @keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }
        to {
            background-color: var(--maskbg);
        }
    }
    @-webkit-keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }
        to {
            background-color: transparent;
        }
    }
    @keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }
        to {
            background-color: transparent;
        }
    }
    .p-autocomplete .p-autocomplete-loader {
        right: 0.75rem;
    }
    .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
        right: 3.75rem;
    }
    .p-autocomplete .p-autocomplete-multiple-container {
        padding: 0.375rem 0.75rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
        border-color: #26aebc;
    }
    .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
        padding: 0.375rem 0;
    }
    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #404040;
        padding: 0;
        margin: 0;
    }
    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
        padding: 0.375rem 0.75rem;
        margin-right: 0.5rem;
        background: #eff6ff;
        color: #259da9;
        border-radius: 4px;
    }
    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
        margin-left: 0.5rem;
    }
    .p-autocomplete.p-invalid.p-component > .p-inputtext {
        border-color: #e24c4c;
    }
    .p-autocomplete-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-autocomplete-panel .p-autocomplete-items {
        padding: 0.75rem 0;
    }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
    }
    .p-calendar.p-invalid.p-component > .p-inputtext {
        border-color: #e24c4c;
    }
    .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-datepicker {
        padding: 0.5rem;
        background: #ffffff;
        color: #404040;
        border: 1px solid #d1d5db;
        border-radius: 4px;
    }
    .p-datepicker:not(.p-datepicker-inline) {
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
        background: #ffffff;
    }
    .p-datepicker .p-datepicker-header {
        padding: 0.5rem;
        color: #404040;
        background: #ffffff;
        font-weight: 600;
        margin: 0;
        border-bottom: 1px solid #e5e7eb;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
    .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title {
        line-height: 2rem;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        color: #404040;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        font-weight: 600;
        padding: 0.5rem;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
        color: #26aebc;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0.5rem;
    }
    .p-datepicker table {
        font-size: 1rem;
        margin: 0.5rem 0;
    }
    .p-datepicker table th {
        padding: 0.5rem;
    }
    .p-datepicker table th > span {
        width: 2.5rem;
        height: 2.5rem;
    }
    .p-datepicker table td {
        padding: 0.5rem;
    }
    .p-datepicker table td > span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border: 1px solid transparent;
    }
    .p-datepicker table td > span.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-datepicker table td > span:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datepicker table td.p-datepicker-today > span {
        background: #d1d5db;
        color: #404040;
        border-color: transparent;
    }
    .p-datepicker table td.p-datepicker-today > span.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-datepicker .p-datepicker-buttonbar {
        padding: 1rem 0;
        border-top: 1px solid #e5e7eb;
    }
    .p-datepicker .p-datepicker-buttonbar .p-button {
        width: auto;
    }
    .p-datepicker .p-timepicker {
        border-top: 1px solid #e5e7eb;
        padding: 0.5rem;
    }
    .p-datepicker .p-timepicker button {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-datepicker .p-timepicker button:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-datepicker .p-timepicker button:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datepicker .p-timepicker button:last-child {
        margin-top: 0.2em;
    }
    .p-datepicker .p-timepicker span {
        font-size: 1.25rem;
    }
    .p-datepicker .p-timepicker > div {
        padding: 0 0.5rem;
    }
    .p-datepicker.p-datepicker-timeonly .p-timepicker {
        border-top: 0 none;
    }
    .p-datepicker .p-monthpicker {
        margin: 0.5rem 0;
    }
    .p-datepicker .p-monthpicker .p-monthpicker-month {
        padding: 0.5rem;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-datepicker .p-yearpicker {
        margin: 0.5rem 0;
    }
    .p-datepicker .p-yearpicker .p-yearpicker-year {
        padding: 0.5rem;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
        border-left: 1px solid #e5e7eb;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }
    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-left: 0;
        border-left: 0 none;
    }
    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-right: 0;
    }
    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        background: #e9f7f8;
    }
    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
        background: #e9f7f8;
    }
    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
        background: #e9f7f8;
    }
    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    @media screen and (max-width: 769px) {
        .p-datepicker table th,
        .p-datepicker table td {
            padding: 0;
        }
    }
    .p-cascadeselect {
        background: #ffffff;
        border: 1px solid #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        outline-color: transparent;
    }
    .p-cascadeselect:not(.p-disabled):hover {
        border-color: #26aebc;
    }
    .p-cascadeselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-cascadeselect.p-variant-filled {
        background-color: #e9f7f8;
    }
    .p-cascadeselect.p-variant-filled:enabled:hover {
        background-color: #e9f7f8;
    }
    .p-cascadeselect.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }
    .p-cascadeselect .p-cascadeselect-label {
        background: transparent;
        border: 0 none;
        padding: 0.75rem 0.75rem;
    }
    .p-cascadeselect .p-cascadeselect-label.p-placeholder {
        color: #6b7280;
    }
    .p-cascadeselect .p-cascadeselect-label:enabled:focus {
        outline: 0 none;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
    .p-cascadeselect .p-cascadeselect-trigger {
        background: transparent;
        color: #6b7280;
        width: 3rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-cascadeselect.p-invalid.p-component {
        border-color: #e24c4c;
    }
    .p-cascadeselect-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-cascadeselect-panel .p-cascadeselect-items {
        padding: 0.75rem 0;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
        margin: 0;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
        margin-top: 0;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:last-child {
        margin-bottom: 0;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #404040;
        background: #e9f7f8;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
        padding: 0.75rem 1.25rem;
    }
    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
        font-size: 0.875rem;
    }
    .p-checkbox {
        position: relative;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        vertical-align: bottom;
    }
    .p-checkbox-input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        cursor: pointer;
    }
    .p-checkbox-box {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-checkbox {
        width: 22px;
        height: 22px;
    }
    .p-checkbox .p-checkbox-input {
        border: 2px solid #d1d5db;
        border-radius: 4px;
    }
    .p-checkbox .p-checkbox-box {
        border: 2px solid #d1d5db;
        background: #ffffff;
        width: 22px;
        height: 22px;
        color: #404040;
        border-radius: 4px;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        outline-color: transparent;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        color: #ffffff;
        font-size: 14px;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
        width: 14px;
        height: 14px;
    }
    .p-checkbox .p-checkbox-box {
        border: 2px solid #d1d5db;
        background: #ffffff;
        width: 22px;
        height: 22px;
        color: #404040;
        border-radius: 4px;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        outline-color: transparent;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        color: #ffffff;
        font-size: 14px;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
        width: 14px;
        height: 14px;
    }
    .p-checkbox.p-highlight .p-checkbox-box {
        border-color: #26aebc;
        background: #26aebc;
    }
    .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        border-color: #26aebc;
    }
    .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
        border-color: #259da9;
        background: #259da9;
        color: #ffffff;
    }
    .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-checkbox.p-invalid > .p-checkbox-box {
        border-color: #e24c4c;
    }
    .p-checkbox.p-variant-filled .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-checkbox.p-variant-filled.p-highlight .p-checkbox-box {
        background: #26aebc;
    }
    .p-checkbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-checkbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
        background: #259da9;
    }
    .p-input-filled .p-checkbox .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-checkbox.p-highlight .p-checkbox-box {
        background: #26aebc;
    }
    .p-input-filled .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
        background: #259da9;
    }
    .p-checkbox {
        position: relative;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        vertical-align: bottom;
    }
    .p-checkbox-input {
        cursor: pointer;
    }
    .p-checkbox-box {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-tristatecheckbox.p-variant-filled .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-tristatecheckbox.p-variant-filled.p-highlight .p-checkbox-box {
        background: #26aebc;
    }
    .p-tristatecheckbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        background-color: #e9f7f8;
    }
    .p-tristatecheckbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
        background: #259da9;
    }
    .p-chips {
        display: -webkit-inline-flex;
        display: inline-flex;
    }
    .p-chips-multiple-container {
        margin: 0;
        padding: 0;
        list-style-type: none;
        cursor: text;
        overflow: hidden;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .p-chips-token {
        cursor: default;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .p-chips-input-token {
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
        display: -webkit-inline-flex;
        display: inline-flex;
    }
    .p-chips-token-icon {
        cursor: pointer;
    }
    .p-chips-input-token input {
        border: 0 none;
        outline: 0 none;
        background-color: transparent;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
        border-radius: 0;
        width: 100%;
    }
    .p-fluid .p-chips {
        display: -webkit-flex;
        display: flex;
    }
    .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
        border-color: #26aebc;
    }
    .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-chips .p-chips-multiple-container {
        padding: 0.375rem 0.75rem;
        outline-color: transparent;
    }
    .p-chips .p-chips-multiple-container .p-chips-token {
        padding: 0.375rem 0.75rem;
        margin-right: 0.5rem;
        background: #e5e7eb;
        color: #404040;
        border-radius: 16px;
    }
    .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
        background: #e5e7eb;
        color: #404040;
    }
    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
        margin-left: 0.5rem;
    }
    .p-chips .p-chips-multiple-container .p-chips-input-token {
        padding: 0.375rem 0;
    }
    .p-chips .p-chips-multiple-container .p-chips-input-token input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #404040;
        padding: 0;
        margin: 0;
    }
    .p-chips.p-invalid.p-component > .p-inputtext {
        border-color: #e24c4c;
    }
    .p-colorpicker-preview {
        width: 2rem;
        height: 2rem;
    }
    .p-colorpicker-panel {
        background: #323232;
        border: 1px solid #191919;
    }
    .p-colorpicker-panel .p-colorpicker-color-handle,
    .p-colorpicker-panel .p-colorpicker-hue-handle {
        border-color: #ffffff;
    }
    .p-colorpicker-overlay-panel {
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-dropdown {
        display: -webkit-inline-flex;
        display: inline-flex;
        cursor: pointer;
        position: relative;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-dropdown-clear-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-dropdown-trigger {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
    }
    .p-dropdown-label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
        width: 1%;
        text-overflow: ellipsis;
        cursor: pointer;
    }
    .p-dropdown-label-empty {
        overflow: hidden;
        opacity: 0;
    }
    input.p-dropdown-label {
        cursor: default;
    }
    .p-dropdown .p-dropdown-panel {
        min-width: 100%;
    }
    .p-dropdown-panel {
        position: absolute;
        top: 0;
        left: 0;
    }
    .p-dropdown-items-wrapper {
        overflow: auto;
    }
    .p-dropdown-item {
        cursor: pointer;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-dropdown-item-group {
        cursor: auto;
    }
    .p-dropdown-items {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .p-dropdown-filter {
        width: 100%;
    }
    .p-dropdown-filter-container {
        position: relative;
    }
    .p-dropdown-filter-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-fluid .p-dropdown {
        display: -webkit-flex;
        display: flex;
    }
    .p-fluid .p-dropdown .p-dropdown-label {
        width: 1%;
    }
    .p-dropdown {
        background: #ffffff;
        border: 1px solid #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        outline-color: transparent;
    }
    .p-dropdown:not(.p-disabled):hover {
        border-color: #26aebc;
    }
    .p-dropdown:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-dropdown.p-variant-filled {
        background: #e9f7f8;
    }
    .p-dropdown.p-variant-filled:not(.p-disabled):hover {
        background-color: #e9f7f8;
    }
    .p-dropdown.p-variant-filled:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }
    .p-dropdown.p-variant-filled:not(.p-disabled).p-focus .p-inputtext {
        background-color: transparent;
    }
    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 1.75rem;
    }
    .p-dropdown .p-dropdown-label {
        background: transparent;
        border: 0 none;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
        color: #6b7280;
    }
    .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
        outline: 0 none;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
    .p-dropdown .p-dropdown-trigger {
        background: transparent;
        color: #6b7280;
        width: 3rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-dropdown .p-dropdown-clear-icon {
        color: #6b7280;
        right: 3rem;
    }
    .p-dropdown.p-invalid.p-component {
        border-color: #e24c4c;
    }
    .p-dropdown-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-dropdown-panel .p-dropdown-header {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
        padding-right: 1.75rem;
        margin-right: -1.75rem;
    }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-dropdown-panel .p-dropdown-items {
        padding: 0.75rem 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
        margin-top: 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:last-child {
        margin-bottom: 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #404040;
        background: #e9f7f8;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
        position: relative;
        margin-left: -0.5rem;
        margin-right: 0.5rem;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
        padding: 0.75rem 1.25rem;
        color: #404040;
        background: transparent;
    }
    .p-inputgroup-addon {
        background: #e9f7f8;
        color: #6b7280;
        border-top: 1px solid #d1d5db;
        border-left: 1px solid #d1d5db;
        border-bottom: 1px solid #d1d5db;
        padding: 0.75rem 0.75rem;
        min-width: 3rem;
    }
    .p-inputgroup-addon:last-child {
        border-right: 1px solid #d1d5db;
    }
    .p-inputgroup > .p-component,
    .p-inputgroup > .p-inputwrapper > .p-inputtext,
    .p-inputgroup > .p-float-label > .p-component {
        border-radius: 0;
        margin: 0;
    }
    .p-inputgroup > .p-component + .p-inputgroup-addon,
    .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
    .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
        border-left: 0 none;
    }
    .p-inputgroup > .p-component:focus,
    .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
    .p-inputgroup > .p-float-label > .p-component:focus {
        z-index: 1;
    }
    .p-inputgroup > .p-component:focus ~ label,
    .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
    .p-inputgroup > .p-float-label > .p-component:focus ~ label {
        z-index: 1;
    }
    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child,
    .p-inputgroup > .p-inputwrapper:first-child,
    .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-inputgroup .p-float-label:first-child input {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child,
    .p-inputgroup > .p-inputwrapper:last-child,
    .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-inputgroup .p-float-label:last-child input {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-fluid .p-inputgroup .p-button {
        width: auto;
    }
    .p-fluid .p-inputgroup .p-button.p-button-icon-only {
        width: 3rem;
    }
    .p-inputnumber.p-invalid.p-component > .p-inputtext {
        border-color: #e24c4c;
    }
    .p-inputswitch {
        position: relative;
        display: inline-block;
    }
    .p-inputswitch-input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        cursor: pointer;
    }
    .p-inputswitch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
    }
    .p-inputswitch-slider:before {
        position: absolute;
        content: "";
        top: 50%;
    }
    .p-inputswitch {
        width: 3rem;
        height: 1.75rem;
    }
    .p-inputswitch .p-inputswitch-input {
        border-radius: 30px;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 30px;
        outline-color: transparent;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        background: #ffffff;
        width: 1.25rem;
        height: 1.25rem;
        left: 0.25rem;
        margin-top: -0.625rem;
        border-radius: 50%;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
    }
    .p-inputswitch.p-highlight .p-inputswitch-slider {
        background: #26aebc;
    }
    .p-inputswitch.p-highlight .p-inputswitch-slider:before {
        background: #ffffff;
        -webkit-transform: translateX(1.25rem);
                transform: translateX(1.25rem);
    }
    .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover) .p-inputswitch-slider {
        background: #b7bcc5;
    }
    .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight .p-inputswitch-slider {
        background: #259da9;
    }
    .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:focus-visible) .p-inputswitch-slider {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-inputswitch.p-invalid > .p-inputswitch-slider {
        border-color: #e24c4c;
    }
    .p-inputtext {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 14px;
        color: #404040;
        background: #ffffff;
        padding: 0.75rem 0.75rem;
        border: 1px solid #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        border-radius: 4px;
        outline-color: transparent;
    }
    .p-inputtext:enabled:hover {
        border-color: #26aebc;
    }
    .p-inputtext:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #7dced7;
                box-shadow: 0 0 0 0.2rem #7dced7;
        border-color: #7dced7;
    }
    .p-inputtext.p-invalid.p-component {
        border-color: #e24c4c;
    }
    .p-inputtext.p-variant-filled {
        background-color: #e9f7f8;
    }
    .p-inputtext.p-variant-filled:enabled:hover {
        background-color: #e9f7f8;
    }
    .p-inputtext.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }
    .p-inputtext.p-inputtext-sm {
        font-size: 0.875rem;
        padding: 0.65625rem 0.65625rem;
    }
    .p-inputtext.p-inputtext-lg {
        font-size: 1.25rem;
        padding: 0.9375rem 0.9375rem;
    }
    .p-float-label > label {
        left: 0.75rem;
        color: #6b7280;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
    }
    .p-float-label > .p-invalid + label {
        color: #e24c4c;
    }
    .p-icon-field-left > .p-inputtext {
        padding-left: 2.5rem;
    }
    .p-icon-field-left.p-float-label > label {
        left: 2.5rem;
    }
    .p-icon-field-right > .p-inputtext {
        padding-right: 2.5rem;
    }
    ::-webkit-input-placeholder {
        color: #6b7280;
    }
    :-moz-placeholder {
        color: #6b7280;
    }
    ::-moz-placeholder {
        color: #6b7280;
    }
    :-ms-input-placeholder {
        color: #6b7280;
    }
    .p-input-filled .p-inputtext {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-inputtext:enabled:hover {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-inputtext:enabled:focus {
        background-color: #ffffff;
    }
    .p-inputtext-sm .p-inputtext {
        font-size: 0.875rem;
        padding: 0.65625rem 0.65625rem;
    }
    .p-inputtext-lg .p-inputtext {
        font-size: 1.25rem;
        padding: 0.9375rem 0.9375rem;
    }
    .p-icon-field {
        position: relative;
    }
    .p-icon-field > .p-input-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-fluid .p-icon-field-left,
    .p-fluid .p-icon-field-right {
        width: 100%;
    }
    .p-icon-field-left > .p-input-icon:first-of-type {
        left: 0.75rem;
        color: #6b7280;
    }
    .p-icon-field-right > .p-input-icon:last-of-type {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-inputotp {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .p-inputotp-input {
        text-align: center;
        width: 2.5rem;
    }
    .p-listbox-list-wrapper {
        overflow: auto;
    }
    .p-listbox-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .p-listbox-item {
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .p-listbox-item-group {
        cursor: auto;
    }
    .p-listbox-filter-container {
        position: relative;
    }
    .p-listbox-filter-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-listbox-filter {
        width: 100%;
    }
    .p-listbox {
        background: #ffffff;
        color: #404040;
        border: 1px solid #d1d5db;
        border-radius: 4px;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        outline-color: transparent;
    }
    .p-listbox .p-listbox-header {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-listbox .p-listbox-header .p-listbox-filter {
        padding-right: 1.75rem;
    }
    .p-listbox .p-listbox-header .p-listbox-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-listbox .p-listbox-list {
        padding: 0.75rem 0;
        outline: 0 none;
    }
    .p-listbox .p-listbox-list .p-listbox-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-listbox .p-listbox-list .p-listbox-item:first-child {
        margin-top: 0;
    }
    .p-listbox .p-listbox-list .p-listbox-item:last-child {
        margin-bottom: 0;
    }
    .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-listbox .p-listbox-list .p-listbox-item-group {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
    }
    .p-listbox .p-listbox-list .p-listbox-empty-message {
        padding: 0.75rem 1.25rem;
        color: #404040;
        background: transparent;
    }
    .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #404040;
        background: #e9f7f8;
    }
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
        color: #404040;
        background: #e9f7f8;
    }
    .p-listbox.p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-listbox.p-invalid {
        border-color: #e24c4c;
    }
    .p-mention-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-mention-panel .p-mention-items {
        padding: 0.75rem 0;
    }
    .p-mention-panel .p-mention-items .p-mention-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-mention-panel .p-mention-items .p-mention-item:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-mention-panel .p-mention-items .p-mention-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-multiselect {
        display: -webkit-inline-flex;
        display: inline-flex;
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-multiselect-trigger {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
    }
    .p-multiselect-label-container {
        overflow: hidden;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
        cursor: pointer;
    }
    .p-multiselect-label {
        display: block;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .p-multiselect-label-empty {
        overflow: hidden;
        visibility: hidden;
    }
    .p-multiselect-token {
        cursor: default;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .p-multiselect-token-icon {
        cursor: pointer;
    }
    .p-multiselect .p-multiselect-panel {
        min-width: 100%;
    }
    .p-multiselect-items-wrapper {
        overflow: auto;
    }
    .p-multiselect-items {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .p-multiselect-item {
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
    }
    .p-multiselect-item-group {
        cursor: auto;
    }
    .p-multiselect-header {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .p-multiselect-filter-container {
        position: relative;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
    }
    .p-multiselect-filter-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-multiselect-filter-container .p-inputtext {
        width: 100%;
    }
    .p-multiselect-close {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        overflow: hidden;
        position: relative;
        margin-left: auto;
    }
    .p-fluid .p-multiselect {
        display: -webkit-flex;
        display: flex;
    }
    .p-multiselect {
        background: #ffffff;
        border: 1px solid #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        outline-color: transparent;
    }
    .p-multiselect:not(.p-disabled):hover {
        border-color: #26aebc;
    }
    .p-multiselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-multiselect.p-variant-filled {
        background: #e9f7f8;
    }
    .p-multiselect.p-variant-filled:not(.p-disabled):hover {
        background-color: #e9f7f8;
    }
    .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }
    .p-multiselect .p-multiselect-label {
        padding: 0.75rem 0.75rem;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-multiselect .p-multiselect-label.p-placeholder {
        color: #6b7280;
    }
    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        padding: 0.375rem 0.75rem;
        margin-right: 0.5rem;
        background: #e5e7eb;
        color: #404040;
        border-radius: 16px;
    }
    .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
        margin-left: 0.5rem;
    }
    .p-multiselect .p-multiselect-trigger {
        background: transparent;
        color: #6b7280;
        width: 3rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-multiselect.p-invalid.p-component {
        border-color: #e24c4c;
    }
    .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
        padding: 0.375rem 0.75rem;
    }
    .p-multiselect-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-multiselect-panel .p-multiselect-header {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
        padding-right: 1.75rem;
    }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-multiselect-panel .p-multiselect-header .p-checkbox {
        margin-right: 0.5rem;
    }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        margin-left: 0.5rem;
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-multiselect-panel .p-multiselect-items {
        padding: 0.75rem 0;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
        margin-top: 0;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:last-child {
        margin-bottom: 0;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #404040;
        background: #e9f7f8;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
        margin-right: 0.5rem;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
        padding: 0.75rem 1.25rem;
        color: #404040;
        background: transparent;
    }
    .p-password.p-invalid.p-component > .p-inputtext {
        border-color: #e24c4c;
    }
    .p-password-panel {
        padding: 1.25rem;
        background: #ffffff;
        color: #404040;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .p-password-panel .p-password-meter {
        margin-bottom: 0.5rem;
        background: #e5e7eb;
    }
    .p-password-panel .p-password-meter .p-password-strength.weak {
        background: #ea5455;
    }
    .p-password-panel .p-password-meter .p-password-strength.medium {
        background: #ff9f42;
    }
    .p-password-panel .p-password-meter .p-password-strength.strong {
        background: #29c76f;
    }
    .p-radiobutton {
        position: relative;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        vertical-align: bottom;
    }
    .p-radiobutton-input {
        cursor: pointer;
    }
    .p-radiobutton-box {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-radiobutton-icon {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(0.1);
                transform: translateZ(0) scale(0.1);
        border-radius: 50%;
        visibility: hidden;
    }
    .p-radiobutton.p-highlight .p-radiobutton-icon {
        -webkit-transform: translateZ(0) scale(1, 1);
                transform: translateZ(0) scale(1, 1);
        visibility: visible;
    }
    .p-radiobutton {
        width: 22px;
        height: 22px;
    }
    .p-radiobutton .p-radiobutton-input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        border: 2px solid #d1d5db;
        border-radius: 50%;
    }
    .p-radiobutton .p-radiobutton-box {
        border: 2px solid #d1d5db;
        background: #ffffff;
        width: 22px;
        height: 22px;
        color: #404040;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        outline-color: transparent;
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        width: 12px;
        height: 12px;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        background-color: #ffffff;
    }
    .p-radiobutton.p-highlight .p-radiobutton-box {
        border-color: #26aebc;
        background: #26aebc;
    }
    .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
        border-color: #26aebc;
    }
    .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
        border-color: #259da9;
        background: #259da9;
    }
    .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box .p-radiobutton-icon {
        background-color: #ffffff;
    }
    .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible) .p-radiobutton-box {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-radiobutton.p-invalid > .p-radiobutton-box {
        border-color: #e24c4c;
    }
    .p-radiobutton.p-variant-filled .p-radiobutton-box {
        background-color: #e9f7f8;
    }
    .p-radiobutton.p-variant-filled.p-highlight .p-radiobutton-box {
        background: #26aebc;
    }
    .p-radiobutton.p-variant-filled:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
        background-color: #e9f7f8;
    }
    .p-radiobutton.p-variant-filled:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
        background: #259da9;
    }
    .p-input-filled .p-radiobutton .p-radiobutton-box {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-radiobutton.p-highlight .p-radiobutton-box {
        background: #26aebc;
    }
    .p-input-filled .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
        background: #259da9;
    }
    .p-rating {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-rating-item {
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        cursor: pointer;
    }
    .p-rating.p-readonly .p-rating-item {
        cursor: default;
    }
    .p-rating {
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .p-rating .p-rating-item {
        outline-color: transparent;
        border-radius: 50%;
    }
    .p-rating .p-rating-item .p-rating-icon {
        color: #404040;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        font-size: 1.143rem;
    }
    .p-rating .p-rating-item .p-rating-icon.p-icon {
        width: 1.143rem;
        height: 1.143rem;
    }
    .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
        color: #ea5455;
    }
    .p-rating .p-rating-item.p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
        color: #26aebc;
    }
    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
        color: #26aebc;
    }
    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
        color: #e73d3e;
    }
    .p-selectbutton .p-button {
        background: #ffffff;
        border: 1px solid #d1d5db;
        color: #404040;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-selectbutton .p-button .p-button-icon-left,
    .p-selectbutton .p-button .p-button-icon-right {
        color: #6b7280;
    }
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
        background: #e9f7f8;
        border-color: #d1d5db;
        color: #404040;
    }
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
        color: #374151;
    }
    .p-selectbutton .p-button.p-highlight {
        background: #26aebc;
        border-color: #26aebc;
        color: #ffffff;
    }
    .p-selectbutton .p-button.p-highlight .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight .p-button-icon-right {
        color: #ffffff;
    }
    .p-selectbutton .p-button.p-highlight:hover {
        background: #259da9;
        border-color: #259da9;
        color: #ffffff;
    }
    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
        color: #ffffff;
    }
    .p-selectbutton.p-invalid > .p-button {
        border-color: #e24c4c;
    }
    .p-slider {
        background: #e5e7eb;
        border: 0 none;
        border-radius: 4px;
    }
    .p-slider.p-slider-horizontal {
        height: 0.286rem;
    }
    .p-slider.p-slider-horizontal .p-slider-handle {
        margin-top: -0.5715rem;
        margin-left: -0.5715rem;
    }
    .p-slider.p-slider-vertical {
        width: 0.286rem;
    }
    .p-slider.p-slider-vertical .p-slider-handle {
        margin-left: -0.5715rem;
        margin-bottom: -0.5715rem;
    }
    .p-slider .p-slider-handle {
        height: 1.143rem;
        width: 1.143rem;
        background: #ffffff;
        border: 2px solid #26aebc;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-slider .p-slider-handle:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-slider .p-slider-range {
        background: #26aebc;
    }
    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #26aebc;
        border-color: #26aebc;
    }
    .p-treeselect {
        background: #ffffff;
        border: 1px solid #d1d5db;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-treeselect:not(.p-disabled):hover {
        border-color: #26aebc;
    }
    .p-treeselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-treeselect.p-treeselect-clearable .p-treeselect-label {
        padding-right: 1.75rem;
    }
    .p-treeselect.p-variant-filled {
        background: #e9f7f8;
    }
    .p-treeselect.p-variant-filled:not(.p-disabled):hover {
        background-color: #e9f7f8;
    }
    .p-treeselect.p-variant-filled:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }
    .p-treeselect .p-treeselect-label {
        padding: 0.75rem 0.75rem;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-treeselect .p-treeselect-label.p-placeholder {
        color: #6b7280;
    }
    .p-treeselect.p-treeselect-chip .p-treeselect-token {
        padding: 0.375rem 0.75rem;
        margin-right: 0.5rem;
        background: #e5e7eb;
        color: #404040;
        border-radius: 16px;
    }
    .p-treeselect .p-treeselect-trigger {
        background: transparent;
        color: #6b7280;
        width: 3rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-treeselect .p-treeselect-clear-icon {
        color: #6b7280;
        right: 3rem;
    }
    .p-treeselect.p-invalid.p-component {
        border-color: #e24c4c;
    }
    .p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
        padding: 0.375rem 0.75rem;
    }
    .p-treeselect-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-treeselect-panel .p-treeselect-header {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
        margin-right: 0.5rem;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
        padding-right: 1.75rem;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
        padding-right: 3.5rem;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
        right: 2.5rem;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
        border: 0 none;
    }
    .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
        padding: 0.75rem 1.25rem;
        color: #404040;
        background: transparent;
    }
    .p-input-filled .p-treeselect {
        background: #e9f7f8;
    }
    .p-input-filled .p-treeselect:not(.p-disabled):hover {
        background-color: #e9f7f8;
    }
    .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }
    .p-togglebutton {
        position: relative;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        vertical-align: bottom;
    }
    .p-togglebutton-input {
        cursor: pointer;
    }
    .p-togglebutton .p-button {
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
    }
    .p-togglebutton .p-togglebutton-input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        border: 1px solid #d1d5db;
        border-radius: 4px;
    }
    .p-togglebutton .p-button {
        background: #ffffff;
        border: 1px solid #d1d5db;
        color: #404040;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        outline-color: transparent;
    }
    .p-togglebutton .p-button .p-button-icon-left,
    .p-togglebutton .p-button .p-button-icon-right {
        color: #6b7280;
    }
    .p-togglebutton.p-highlight .p-button {
        background: #26aebc;
        border-color: #26aebc;
        color: #ffffff;
    }
    .p-togglebutton.p-highlight .p-button .p-button-icon-left,
    .p-togglebutton.p-highlight .p-button .p-button-icon-right {
        color: #ffffff;
    }
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button {
        background: #e9f7f8;
        border-color: #d1d5db;
        color: #404040;
    }
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button .p-button-icon-left,
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button .p-button-icon-right {
        color: #374151;
    }
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button {
        background: #259da9;
        border-color: #259da9;
        color: #ffffff;
    }
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button .p-button-icon-left,
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button .p-button-icon-right {
        color: #ffffff;
    }
    .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:focus-visible) .p-button {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #26aebc;
    }
    .p-togglebutton.p-invalid > .p-button {
        border-color: #e24c4c;
    }
    .p-button {
        color: #ffffff;
        background: #26aebc;
        border: 1px solid #26aebc;
        padding: 0.75rem 1.25rem;
        font-size: 1rem;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-button:not(:disabled):hover {
        background: #259da9;
        color: #ffffff;
        border-color: #259da9;
    }
    .p-button:not(:disabled):active {
        background: #259da9;
        color: #ffffff;
        border-color: #259da9;
    }
    .p-button.p-button-outlined {
        background-color: transparent;
        color: #26aebc;
        border: 1px solid;
    }
    .p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(38,174,188,.1);
        color: #26aebc;
        border: 1px solid;
    }
    .p-button.p-button-outlined:not(:disabled):active {
        background: rgba(38,174,188,.16);
        color: #26aebc;
        border: 1px solid;
    }
    .p-button.p-button-outlined.p-button-plain {
        color: #6b7280;
        border-color: #6b7280;
    }
    .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
        background: #e9f7f8;
        color: #6b7280;
    }
    .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
        background: #e5e7eb;
        color: #6b7280;
    }
    .p-button.p-button-text {
        background-color: transparent;
        color: #26aebc;
        border-color: transparent;
    }
    .p-button.p-button-text:not(:disabled):hover {
        background: rgba(38,174,188,.1);
        color: #26aebc;
        border-color: transparent;
    }
    .p-button.p-button-text:not(:disabled):active {
        background: rgba(38,174,188,.16);
        color: #26aebc;
        border-color: transparent;
    }
    .p-button.p-button-text.p-button-plain {
        color: #6b7280;
    }
    .p-button.p-button-text.p-button-plain:not(:disabled):hover {
        background: #e9f7f8;
        color: #6b7280;
    }
    .p-button.p-button-text.p-button-plain:not(:disabled):active {
        background: #e5e7eb;
        color: #6b7280;
    }
    .p-button:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-button .p-button-label {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
    }
    .p-button .p-button-icon-left {
        margin-right: 0.5rem;
    }
    .p-button .p-button-icon-right {
        margin-left: 0.5rem;
    }
    .p-button .p-button-icon-bottom {
        margin-top: 0.5rem;
    }
    .p-button .p-button-icon-top {
        margin-bottom: 0.5rem;
    }
    .p-button .p-badge {
        margin-left: 0.5rem;
        min-width: 1rem;
        height: 1rem;
        line-height: 1rem;
        color: #26aebc;
        background-color: #ffffff;
    }
    .p-button.p-button-raised {
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .p-button.p-button-rounded {
        border-radius: 2rem;
    }
    .p-button.p-button-icon-only {
        width: 3rem;
        padding: 0.75rem 0;
    }
    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
        margin: 0;
    }
    .p-button.p-button-icon-only.p-button-rounded {
        border-radius: 50%;
        height: 3rem;
    }
    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 0.65625rem 1.09375rem;
    }
    .p-button.p-button-sm .p-button-icon {
        font-size: 0.875rem;
    }
    .p-button.p-button-lg {
        font-size: 1.25rem;
        padding: 0.9375rem 1.5625rem;
    }
    .p-button.p-button-lg .p-button-icon {
        font-size: 1.25rem;
    }
    .p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
        margin-left: 0.5rem;
    }
    .p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
        margin-right: 0.5rem;
    }
    .p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
        margin-top: 0.5rem;
    }
    .p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
        margin-bottom: 0.5rem;
    }
    .p-button.p-button-loading-label-only .p-button-loading-icon {
        margin: 0;
    }
    .p-fluid .p-button {
        width: 100%;
    }
    .p-fluid .p-button-icon-only {
        width: 3rem;
    }
    .p-fluid .p-button-group {
        display: -webkit-flex;
        display: flex;
    }
    .p-fluid .p-button-group .p-button {
        -webkit-flex: 1 1;
                flex: 1 1;
    }
    .p-button.p-button-secondary, .p-button-group.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
        color: #ffffff;
        background: #64748b;
        border: 1px solid #64748b;
    }
    .p-button.p-button-secondary:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-secondary:not(:disabled):hover {
        background: #475569;
        color: #ffffff;
        border-color: #475569;
    }
    .p-button.p-button-secondary:not(:disabled):focus, .p-button-group.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-secondary:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #e2e8f0;
                box-shadow: 0 0 0 0.2rem #e2e8f0;
    }
    .p-button.p-button-secondary:not(:disabled):active, .p-button-group.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-secondary:not(:disabled):active {
        background: #334155;
        color: #ffffff;
        border-color: #334155;
    }
    .p-button.p-button-secondary.p-button-outlined, .p-button-group.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
        background-color: transparent;
        color: #64748b;
        border: 1px solid;
    }
    .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(:disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        color: #64748b;
        border: 1px solid;
    }
    .p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(:disabled):active {
        background: rgba(100, 116, 139, 0.16);
        color: #64748b;
        border: 1px solid;
    }
    .p-button.p-button-secondary.p-button-text, .p-button-group.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
        background-color: transparent;
        color: #64748b;
        border-color: transparent;
    }
    .p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(:disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        border-color: transparent;
        color: #64748b;
    }
    .p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:not(:disabled):active {
        background: rgba(100, 116, 139, 0.16);
        border-color: transparent;
        color: #64748b;
    }
    .p-button.p-button-info, .p-button-group.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
        color: #ffffff;
        background: #0ea5e9;
        border: 1px solid #0ea5e9;
    }
    .p-button.p-button-info:not(:disabled):hover, .p-button-group.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-info:not(:disabled):hover {
        background: #0284c7;
        color: #ffffff;
        border-color: #0284c7;
    }
    .p-button.p-button-info:not(:disabled):focus, .p-button-group.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-info:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-button.p-button-info:not(:disabled):active, .p-button-group.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-info:not(:disabled):active {
        background: #0369a1;
        color: #ffffff;
        border-color: #0369a1;
    }
    .p-button.p-button-info.p-button-outlined, .p-button-group.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
        background-color: transparent;
        color: #0ea5e9;
        border: 1px solid;
    }
    .p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        color: #0ea5e9;
        border: 1px solid;
    }
    .p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        color: #0ea5e9;
        border: 1px solid;
    }
    .p-button.p-button-info.p-button-text, .p-button-group.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
        background-color: transparent;
        color: #0ea5e9;
        border-color: transparent;
    }
    .p-button.p-button-info.p-button-text:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        border-color: transparent;
        color: #0ea5e9;
    }
    .p-button.p-button-info.p-button-text:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-info.p-button-text:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        border-color: transparent;
        color: #0ea5e9;
    }
    .p-button.p-button-success, .p-button-group.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
        color: #ffffff;
        background: #22c55e;
        border: 1px solid #22c55e;
    }
    .p-button.p-button-success:not(:disabled):hover, .p-button-group.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-success:not(:disabled):hover {
        background: #16a34a;
        color: #ffffff;
        border-color: #16a34a;
    }
    .p-button.p-button-success:not(:disabled):focus, .p-button-group.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-success:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #bbf7d0;
                box-shadow: 0 0 0 0.2rem #bbf7d0;
    }
    .p-button.p-button-success:not(:disabled):active, .p-button-group.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-success:not(:disabled):active {
        background: #15803d;
        color: #ffffff;
        border-color: #15803d;
    }
    .p-button.p-button-success.p-button-outlined, .p-button-group.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
        background-color: transparent;
        color: #22c55e;
        border: 1px solid;
    }
    .p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        color: #22c55e;
        border: 1px solid;
    }
    .p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        color: #22c55e;
        border: 1px solid;
    }
    .p-button.p-button-success.p-button-text, .p-button-group.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
        background-color: transparent;
        color: #22c55e;
        border-color: transparent;
    }
    .p-button.p-button-success.p-button-text:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        border-color: transparent;
        color: #22c55e;
    }
    .p-button.p-button-success.p-button-text:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-success.p-button-text:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        border-color: transparent;
        color: #22c55e;
    }
    .p-button.p-button-warning, .p-button-group.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
        color: #ffffff;
        background: #f97316;
        border: 1px solid #f97316;
    }
    .p-button.p-button-warning:not(:disabled):hover, .p-button-group.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-warning:not(:disabled):hover {
        background: #ea580c;
        color: #ffffff;
        border-color: #ea580c;
    }
    .p-button.p-button-warning:not(:disabled):focus, .p-button-group.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-warning:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #fde68a;
                box-shadow: 0 0 0 0.2rem #fde68a;
    }
    .p-button.p-button-warning:not(:disabled):active, .p-button-group.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-warning:not(:disabled):active {
        background: #c2410c;
        color: #ffffff;
        border-color: #c2410c;
    }
    .p-button.p-button-warning.p-button-outlined, .p-button-group.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
        background-color: transparent;
        color: #f97316;
        border: 1px solid;
    }
    .p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        color: #f97316;
        border: 1px solid;
    }
    .p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        color: #f97316;
        border: 1px solid;
    }
    .p-button.p-button-warning.p-button-text, .p-button-group.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
        background-color: transparent;
        color: #f97316;
        border-color: transparent;
    }
    .p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        border-color: transparent;
        color: #f97316;
    }
    .p-button.p-button-warning.p-button-text:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        border-color: transparent;
        color: #f97316;
    }
    .p-button.p-button-help, .p-button-group.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
        color: #ffffff;
        background: #a855f7;
        border: 1px solid #a855f7;
    }
    .p-button.p-button-help:not(:disabled):hover, .p-button-group.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-help:not(:disabled):hover {
        background: #9333ea;
        color: #ffffff;
        border-color: #9333ea;
    }
    .p-button.p-button-help:not(:disabled):focus, .p-button-group.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-help:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #e9d5ff;
                box-shadow: 0 0 0 0.2rem #e9d5ff;
    }
    .p-button.p-button-help:not(:disabled):active, .p-button-group.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-help:not(:disabled):active {
        background: #7e22ce;
        color: #ffffff;
        border-color: #7e22ce;
    }
    .p-button.p-button-help.p-button-outlined, .p-button-group.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
        background-color: transparent;
        color: #a855f7;
        border: 1px solid;
    }
    .p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        color: #a855f7;
        border: 1px solid;
    }
    .p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        color: #a855f7;
        border: 1px solid;
    }
    .p-button.p-button-help.p-button-text, .p-button-group.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
        background-color: transparent;
        color: #a855f7;
        border-color: transparent;
    }
    .p-button.p-button-help.p-button-text:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        border-color: transparent;
        color: #a855f7;
    }
    .p-button.p-button-help.p-button-text:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-help.p-button-text:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        border-color: transparent;
        color: #a855f7;
    }
    .p-button.p-button-danger, .p-button-group.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
        color: #ffffff;
        background: #ef4444;
        border: 1px solid #ef4444;
    }
    .p-button.p-button-danger:not(:disabled):hover, .p-button-group.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-danger:not(:disabled):hover {
        background: #dc2626;
        color: #ffffff;
        border-color: #dc2626;
    }
    .p-button.p-button-danger:not(:disabled):focus, .p-button-group.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-danger:not(:disabled):focus {
        -webkit-box-shadow: 0 0 0 0.2rem #fecaca;
                box-shadow: 0 0 0 0.2rem #fecaca;
    }
    .p-button.p-button-danger:not(:disabled):active, .p-button-group.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-danger:not(:disabled):active {
        background: #b91c1c;
        color: #ffffff;
        border-color: #b91c1c;
    }
    .p-button.p-button-danger.p-button-outlined, .p-button-group.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
        background-color: transparent;
        color: #ef4444;
        border: 1px solid;
    }
    .p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(:disabled):hover {
        background: rgba(244,73,73,.04);
        color: #ef4444;
        border: 1px solid;
    }
    .p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(:disabled):active {
        background: rgba(244,73,73,.16);
        color: #ef4444;
        border: 1px solid;
    }
    .p-button.p-button-danger.p-button-text, .p-button-group.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
        background-color: transparent;
        color: #ef4444;
        border-color: transparent;
    }
    .p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:not(:disabled):hover {
        background: rgba(244,73,73,.04);
        border-color: transparent;
        color: #ef4444;
    }
    .p-button.p-button-danger.p-button-text:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:not(:disabled):active {
        background: rgba(244,73,73,.16);
        border-color: transparent;
        color: #ef4444;
    }
    .p-button.p-button-contrast, .p-button-group.p-button-contrast > .p-button, .p-splitbutton.p-button-contrast > .p-button {
        color: #ffffff;
        background: #1f2937;
        border: 1px solid #1f2937;
    }
    .p-button.p-button-contrast:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):hover {
        background: #374151;
        color: #ffffff;
        border-color: #374151;
    }
    .p-button.p-button-contrast:not(:disabled):focus, .p-button-group.p-button-contrast > .p-button:not(:disabled):focus, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):focus {
        -webkit-box-shadow: none;
                box-shadow: none;
    }
    .p-button.p-button-contrast:not(:disabled):active, .p-button-group.p-button-contrast > .p-button:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):active {
        background: #404040;
        color: #ffffff;
        border-color: #404040;
    }
    .p-button.p-button-contrast.p-button-outlined, .p-button-group.p-button-contrast > .p-button.p-button-outlined, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined {
        background-color: transparent;
        color: #1f2937;
        border: 1px solid;
    }
    .p-button.p-button-contrast.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(31, 41, 55, 0.04);
        color: #1f2937;
        border: 1px solid;
    }
    .p-button.p-button-contrast.p-button-outlined:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active {
        background: rgba(31, 41, 55, 0.16);
        color: #1f2937;
        border: 1px solid;
    }
    .p-button.p-button-contrast.p-button-text, .p-button-group.p-button-contrast > .p-button.p-button-text, .p-splitbutton.p-button-contrast > .p-button.p-button-text {
        background-color: transparent;
        color: #1f2937;
        border-color: transparent;
    }
    .p-button.p-button-contrast.p-button-text:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):hover {
        background: rgba(31, 41, 55, 0.04);
        border-color: transparent;
        color: #1f2937;
    }
    .p-button.p-button-contrast.p-button-text:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):active {
        background: rgba(31, 41, 55, 0.16);
        border-color: transparent;
        color: #1f2937;
    }
    .p-button.p-button-link {
        color: #259da9;
        background: transparent;
        border: transparent;
    }
    .p-button.p-button-link:not(:disabled):hover {
        background: transparent;
        color: #259da9;
        border-color: transparent;
    }
    .p-button.p-button-link:not(:disabled):hover .p-button-label {
        text-decoration: underline;
    }
    .p-button.p-button-link:not(:disabled):focus {
        background: transparent;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: transparent;
    }
    .p-button.p-button-link:not(:disabled):active {
        background: transparent;
        color: #259da9;
        border-color: transparent;
    }
    .p-splitbutton {
        border-radius: 4px;
    }
    .p-splitbutton.p-button-outlined > .p-button {
        background-color: transparent;
        color: #26aebc;
        border: 1px solid;
    }
    .p-splitbutton.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(38,174,188,.1);
        color: #26aebc;
    }
    .p-splitbutton.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(38,174,188,.16);
        color: #26aebc;
    }
    .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
        color: #6b7280;
        border-color: #6b7280;
    }
    .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(:disabled):hover {
        background: #e9f7f8;
        color: #6b7280;
    }
    .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(:disabled):active {
        background: #e5e7eb;
        color: #6b7280;
    }
    .p-splitbutton.p-button-text > .p-button {
        background-color: transparent;
        color: #26aebc;
        border-color: transparent;
    }
    .p-splitbutton.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(38,174,188,.1);
        color: #26aebc;
        border-color: transparent;
    }
    .p-splitbutton.p-button-text > .p-button:not(:disabled):active {
        background: rgba(38,174,188,.16);
        color: #26aebc;
        border-color: transparent;
    }
    .p-splitbutton.p-button-text.p-button-plain > .p-button {
        color: #6b7280;
    }
    .p-splitbutton.p-button-text.p-button-plain > .p-button:not(:disabled):hover {
        background: #e9f7f8;
        color: #6b7280;
    }
    .p-splitbutton.p-button-text.p-button-plain > .p-button:not(:disabled):active {
        background: #e5e7eb;
        color: #6b7280;
    }
    .p-splitbutton.p-button-raised {
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .p-splitbutton.p-button-rounded {
        border-radius: 2rem;
    }
    .p-splitbutton.p-button-rounded > .p-button {
        border-radius: 2rem;
    }
    .p-splitbutton.p-button-sm > .p-button {
        font-size: 0.875rem;
        padding: 0.65625rem 1.09375rem;
    }
    .p-splitbutton.p-button-sm > .p-button .p-button-icon {
        font-size: 0.875rem;
    }
    .p-splitbutton.p-button-lg > .p-button {
        font-size: 1.25rem;
        padding: 0.9375rem 1.5625rem;
    }
    .p-splitbutton.p-button-lg > .p-button.p-button-icon-only {
        width: auto;
    }
    .p-splitbutton.p-button-lg > .p-button .p-button-icon {
        font-size: 1.25rem;
    }
    .p-splitbutton .p-splitbutton-menubutton,
    .p-splitbutton .p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
    .p-splitbutton .p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
        background-color: transparent;
        color: #64748b;
        border: 1px solid;
    }
    .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        color: #64748b;
    }
    .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(100, 116, 139, 0.16);
        color: #64748b;
    }
    .p-splitbutton.p-button-secondary.p-button-text > .p-button {
        background-color: transparent;
        color: #64748b;
        border-color: transparent;
    }
    .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        border-color: transparent;
        color: #64748b;
    }
    .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(:disabled):active {
        background: rgba(100, 116, 139, 0.16);
        border-color: transparent;
        color: #64748b;
    }
    .p-splitbutton.p-button-info.p-button-outlined > .p-button {
        background-color: transparent;
        color: #0ea5e9;
        border: 1px solid;
    }
    .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        color: #0ea5e9;
    }
    .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        color: #0ea5e9;
    }
    .p-splitbutton.p-button-info.p-button-text > .p-button {
        background-color: transparent;
        color: #0ea5e9;
        border-color: transparent;
    }
    .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        border-color: transparent;
        color: #0ea5e9;
    }
    .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        border-color: transparent;
        color: #0ea5e9;
    }
    .p-splitbutton.p-button-success.p-button-outlined > .p-button {
        background-color: transparent;
        color: #22c55e;
        border: 1px solid;
    }
    .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        color: #22c55e;
    }
    .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        color: #22c55e;
    }
    .p-splitbutton.p-button-success.p-button-text > .p-button {
        background-color: transparent;
        color: #22c55e;
        border-color: transparent;
    }
    .p-splitbutton.p-button-success.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        border-color: transparent;
        color: #22c55e;
    }
    .p-splitbutton.p-button-success.p-button-text > .p-button:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        border-color: transparent;
        color: #22c55e;
    }
    .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
        background-color: transparent;
        color: #f97316;
        border: 1px solid;
    }
    .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        color: #f97316;
    }
    .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        color: #f97316;
    }
    .p-splitbutton.p-button-warning.p-button-text > .p-button {
        background-color: transparent;
        color: #f97316;
        border-color: transparent;
    }
    .p-splitbutton.p-button-warning.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        border-color: transparent;
        color: #f97316;
    }
    .p-splitbutton.p-button-warning.p-button-text > .p-button:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        border-color: transparent;
        color: #f97316;
    }
    .p-splitbutton.p-button-help.p-button-outlined > .p-button {
        background-color: transparent;
        color: #a855f7;
        border: 1px solid;
    }
    .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        color: #a855f7;
    }
    .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        color: #a855f7;
    }
    .p-splitbutton.p-button-help.p-button-text > .p-button {
        background-color: transparent;
        color: #a855f7;
        border-color: transparent;
    }
    .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        border-color: transparent;
        color: #a855f7;
    }
    .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        border-color: transparent;
        color: #a855f7;
    }
    .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
        background-color: transparent;
        color: #ef4444;
        border: 1px solid;
    }
    .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(:disabled):hover {
        background: rgba(244,73,73,.04);
        color: #ef4444;
    }
    .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(:disabled):active {
        background: rgba(244,73,73,.16);
        color: #ef4444;
    }
    .p-splitbutton.p-button-danger.p-button-text > .p-button {
        background-color: transparent;
        color: #ef4444;
        border-color: transparent;
    }
    .p-splitbutton.p-button-danger.p-button-text > .p-button:not(:disabled):hover {
        background: rgba(244,73,73,.04);
        border-color: transparent;
        color: #ef4444;
    }
    .p-splitbutton.p-button-danger.p-button-text > .p-button:not(:disabled):active {
        background: rgba(244,73,73,.16);
        border-color: transparent;
        color: #ef4444;
    }
    .p-speeddial-button.p-button.p-button-icon-only {
        width: 4rem;
        height: 4rem;
    }
    .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
        font-size: 1.3rem;
    }
    .p-speeddial-button.p-button.p-button-icon-only .p-button-icon.p-icon {
        width: 1.3rem;
        height: 1.3rem;
    }
    .p-speeddial-list {
        outline: 0 none;
    }
    .p-speeddial-action {
        width: 3rem;
        height: 3rem;
        background: #404040;
        color: #fff;
    }
    .p-speeddial-action:hover {
        background: #022354;
        color: #fff;
    }
    .p-speeddial-direction-up .p-speeddial-item {
        margin: 0.25rem;
    }
    .p-speeddial-direction-up .p-speeddial-item:first-child {
        margin-bottom: 0.5rem;
    }
    .p-speeddial-direction-down .p-speeddial-item {
        margin: 0.25rem;
    }
    .p-speeddial-direction-down .p-speeddial-item:first-child {
        margin-top: 0.5rem;
    }
    .p-speeddial-direction-left .p-speeddial-item {
        margin: 0 0.25rem;
    }
    .p-speeddial-direction-left .p-speeddial-item:first-child {
        margin-right: 0.5rem;
    }
    .p-speeddial-direction-right .p-speeddial-item {
        margin: 0 0.25rem;
    }
    .p-speeddial-direction-right .p-speeddial-item:first-child {
        margin-left: 0.5rem;
    }
    .p-speeddial-circle .p-speeddial-item,
    .p-speeddial-semi-circle .p-speeddial-item,
    .p-speeddial-quarter-circle .p-speeddial-item {
        margin: 0;
    }
    .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
    .p-speeddial-semi-circle .p-speeddial-item:first-child,
    .p-speeddial-semi-circle .p-speeddial-item:last-child,
    .p-speeddial-quarter-circle .p-speeddial-item:first-child,
    .p-speeddial-quarter-circle .p-speeddial-item:last-child {
        margin: 0;
    }
    .p-speeddial-mask {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
    }
    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel .p-carousel-content .p-carousel-next {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin: 0.5rem;
    }
    .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
    .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
    .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-carousel .p-carousel-indicators {
        padding: 1rem;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #d1d5db;
        width: 2rem;
        height: 0.5rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
        background: #9ca3af;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-datatable .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-datatable .p-datatable-header {
        background: #f9f9f9;
        color: #374151;
        border: 1px solid #cdcdcd;
        border-width: 1px 0;
        padding: 1rem;
        font-weight: 700;
    }
    .p-datatable .p-datatable-footer {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
    }
    .p-datatable .p-datatable-thead > tr > th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        font-weight: 700;
        color: #374151;
        background: #f9fafb;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-datatable .p-datatable-tfoot > tr > td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        font-weight: 700;
        color: #374151;
        background: #f9fafb;
    }
    .p-datatable .p-sortable-column .p-sortable-column-icon {
        color: #374151;
        margin-left: 0.5rem;
    }
    .p-datatable .p-sortable-column .p-sortable-column-badge {
        border-radius: 50%;
        height: 1.143rem;
        min-width: 1.143rem;
        line-height: 1.143rem;
        color: #259da9;
        background: #eff6ff;
        margin-left: 0.5rem;
    }
    .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
        background: #e9f7f8;
        color: #374151;
    }
    .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
        color: #374151;
    }
    .p-datatable .p-sortable-column.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: #259da9;
    }
    .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
        color: #259da9;
    }
    .p-datatable .p-sortable-column:focus-visible {
        -webkit-box-shadow: inset 0 0 0 0.15rem #bfdbfe;
                box-shadow: inset 0 0 0 0.15rem #bfdbfe;
        outline: 0 none;
    }
    .p-datatable .p-datatable-tbody > tr {
        background: #ffffff;
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        text-align: left;
        border: solid #cdcdcd;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        outline-color: #7dced7;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
        margin-right: 0.5rem;
    }
    .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
        font-weight: 700;
    }
    .p-datatable .p-datatable-tbody > tr > td.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable .p-datatable-tbody > tr.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
        outline: 0.15rem solid #bfdbfe;
        outline-offset: -0.15rem;
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
        -webkit-box-shadow: inset 0 2px 0 0 #eff6ff;
                box-shadow: inset 0 2px 0 0 #eff6ff;
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
        -webkit-box-shadow: inset 0 -2px 0 0 #eff6ff;
                box-shadow: inset 0 -2px 0 0 #eff6ff;
    }
    .p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus-visible {
        outline: 0.15rem solid #bfdbfe;
        outline-offset: -0.15rem;
    }
    .p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus-visible {
        outline: 0.15rem solid #bfdbfe;
        outline-offset: -0.15rem;
    }
    .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-datatable .p-column-resizer-helper {
        background: #26aebc;
    }
    .p-datatable .p-datatable-scrollable-header,
    .p-datatable .p-datatable-scrollable-footer {
        background: #f9fafb;
    }
    .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
    .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
    .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
        background-color: #f9fafb;
    }
    .p-datatable .p-datatable-loading-icon {
        font-size: 2rem;
    }
    .p-datatable .p-datatable-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-header {
        border-width: 1px 1px 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-footer {
        border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-paginator-top {
        border-width: 0 1px 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-paginator-bottom {
        border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
        border-width: 1px 0 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
        border-width: 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
        border-width: 1px 0 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
        border-width: 1px 1px 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
        border-width: 1px 0 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
        border-width: 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
        border-width: 1px 0 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
        border-width: 1px 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
        border-width: 0 0 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
        border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
        border-width: 0 0 1px 1px;
    }
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
        border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
        border-width: 0 0 0 1px;
    }
    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
        border-width: 0 1px 0 1px;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
        background: #fcfcfc;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
        color: #259da9;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
        color: #259da9;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
        background: #f8f8fa;
    }
    .p-datatable.p-datatable-sm .p-datatable-header {
        padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
        padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
        padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
        padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-footer {
        padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-header {
        padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
        padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
        padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
        padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-footer {
        padding: 1.25rem 1.25rem;
    }
    .p-datatable-drag-selection-helper {
        background: rgba(38,174,188,.16);
    }
    .p-dataview .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-dataview .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-dataview .p-dataview-header {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 1px 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
    }
    .p-dataview .p-dataview-content {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        padding: 0;
    }
    .p-dataview .p-dataview-footer {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-dataview .p-dataview-loading-icon {
        font-size: 2rem;
    }
    .p-dataview .p-dataview-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-datascroller .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-datascroller .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-datascroller .p-datascroller-header {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 1px 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
    }
    .p-datascroller .p-datascroller-content {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        padding: 0;
    }
    .p-datascroller.p-datascroller-inline .p-datascroller-list > li {
        border: solid #e5e7eb;
        border-width: 0 0 1px 0;
    }
    .p-datascroller .p-datascroller-footer {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-column-filter-row .p-column-filter-menu-button,
    .p-column-filter-row .p-column-filter-clear-button {
        margin-left: 0.5rem;
    }
    .p-column-filter-menu-button {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-column-filter-menu-button:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
        background: #e9f7f8;
        color: #374151;
    }
    .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
        background: #eff6ff;
        color: #259da9;
    }
    .p-column-filter-menu-button:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-column-filter-clear-button {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-column-filter-clear-button:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-column-filter-clear-button:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-column-filter-overlay {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        min-width: 12.5rem;
    }
    .p-column-filter-overlay .p-column-filter-row-items {
        padding: 0.75rem 0;
    }
    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.15rem #bfdbfe;
                box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-column-filter-overlay-menu .p-column-filter-operator {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-column-filter-overlay-menu .p-column-filter-constraint {
        padding: 1.25rem;
        border-bottom: 1px solid #e5e7eb;
    }
    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
        margin-bottom: 0.5rem;
    }
    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
        margin-top: 0.5rem;
    }
    .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
        border-bottom: 0 none;
    }
    .p-column-filter-overlay-menu .p-column-filter-add-rule {
        padding: 0.75rem 1.25rem;
    }
    .p-column-filter-overlay-menu .p-column-filter-buttonbar {
        padding: 1.25rem;
    }
    .p-orderlist .p-orderlist-controls {
        padding: 1.25rem;
    }
    .p-orderlist .p-orderlist-controls .p-button {
        margin-bottom: 0.5rem;
    }
    .p-orderlist .p-orderlist-header {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        padding: 1.25rem;
        font-weight: 700;
        border-bottom: 0 none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-orderlist .p-orderlist-filter-container {
        padding: 1.25rem;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-bottom: 0 none;
    }
    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
        padding-right: 1.75rem;
    }
    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-orderlist .p-orderlist-list {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        padding: 0.75rem 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        outline: 0 none;
    }
    .p-orderlist .p-orderlist-list .p-orderlist-item {
        padding: 0.75rem 1.25rem;
        margin: 0;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
        transition: -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
        color: #404040;
        background: #e5e7eb;
    }
    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
        background: #f8f8fa;
    }
    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
        background: #e9f7f8;
    }
    .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-organizationchart .p-organizationchart-node-content.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
        color: #70aeff;
    }
    .p-organizationchart .p-organizationchart-line-down {
        background: #e5e7eb;
    }
    .p-organizationchart .p-organizationchart-line-left {
        border-right: 1px solid #e5e7eb;
        border-color: #e5e7eb;
    }
    .p-organizationchart .p-organizationchart-line-top {
        border-top: 1px solid #e5e7eb;
        border-color: #e5e7eb;
    }
    .p-organizationchart .p-organizationchart-node-content {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        padding: 1.25rem;
    }
    .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;
    }
    .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-paginator {
        background: #ffffff;
        color: #6b7280;
        border: solid #e9f7f8;
        border-width: 0;
        padding: 0.5rem 1rem;
        border-radius: 4px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #6b7280;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
    }
    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: #e9f7f8;
        border-color: transparent;
        color: #374151;
    }
    .p-paginator .p-paginator-first {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    .p-paginator .p-paginator-last {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
    .p-paginator .p-dropdown {
        margin-left: 0.5rem;
        height: 3rem;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
        padding-right: 0;
    }
    .p-paginator .p-paginator-page-input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .p-paginator .p-paginator-page-input .p-inputtext {
        max-width: 3rem;
    }
    .p-paginator .p-paginator-current {
        background-color: transparent;
        border: 0 none;
        color: #6b7280;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        padding: 0 0.5rem;
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #6b7280;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #eff6ff;
        border-color: #eff6ff;
        color: #259da9;
    }
    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background: #e9f7f8;
        border-color: transparent;
        color: #374151;
    }
    .p-picklist .p-picklist-buttons {
        padding: 1.25rem;
    }
    .p-picklist .p-picklist-buttons .p-button {
        margin-bottom: 0.5rem;
    }
    .p-picklist .p-picklist-header {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        padding: 1.25rem;
        font-weight: 700;
        border-bottom: 0 none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-picklist .p-picklist-filter-container {
        padding: 1.25rem;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-bottom: 0 none;
    }
    .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
        padding-right: 1.75rem;
    }
    .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-picklist .p-picklist-list {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        padding: 0.75rem 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        outline: 0 none;
    }
    .p-picklist .p-picklist-list .p-picklist-item {
        padding: 0.75rem 1.25rem;
        margin: 0;
        border: 0 none;
        color: #404040;
        background: transparent;
        -webkit-transition: -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
        transition: -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-picklist .p-picklist-list .p-picklist-item.p-focus {
        color: #404040;
        background: #e5e7eb;
    }
    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
        color: #259da9;
        background: #eff6ff;
    }
    .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
        background: #7dced7;
    }
    .p-tree-container {
        margin: 0;
        padding: 0;
        list-style-type: none;
        overflow: auto;
    }
    .p-treenode-children {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .p-treenode-selectable {
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-tree-toggler {
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        overflow: hidden;
        position: relative;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
    }
    .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
        visibility: hidden;
    }
    .p-treenode-content {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .p-tree-filter {
        width: 100%;
    }
    .p-tree-filter-container {
        position: relative;
        display: block;
        width: 100%;
    }
    .p-tree-filter-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }
    .p-tree-loading {
        position: relative;
        min-height: 4rem;
    }
    .p-tree .p-tree-loading-overlay {
        position: absolute;
        z-index: 1;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .p-tree {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        padding: 1.25rem;
        border-radius: 4px;
    }
    .p-tree .p-tree-container .p-treenode {
        padding: 0.143rem;
        outline: 0 none;
    }
    .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.15rem #bfdbfe;
                box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content {
        border-radius: 4px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        padding: 0.5rem;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        margin-right: 0.5rem;
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        margin-right: 0.5rem;
        color: #6b7280;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin-right: 0.5rem;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-indeterminate .p-checkbox-icon {
        color: #404040;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
        color: #259da9;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
        color: #259da9;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-tree .p-tree-filter-container {
        margin-bottom: 0.5rem;
    }
    .p-tree .p-tree-filter-container .p-tree-filter {
        width: 100%;
        padding-right: 1.75rem;
    }
    .p-tree .p-tree-filter-container .p-tree-filter-icon {
        right: 0.75rem;
        color: #6b7280;
    }
    .p-tree .p-treenode-children {
        padding: 0 0 0 1rem;
    }
    .p-tree .p-tree-loading-icon {
        font-size: 2rem;
    }
    .p-tree .p-tree-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-tree .p-treenode-droppoint {
        height: 0.5rem;
    }
    .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
        background: #8cbeff;
    }
    .p-treetable {
        position: relative;
    }
    .p-treetable > .p-treetable-wrapper {
        overflow: auto;
    }
    .p-treetable table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
    }
    .p-treetable .p-sortable-column {
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-treetable-selectable .p-treetable-tbody > tr {
        cursor: pointer;
    }
    .p-treetable-toggler {
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        vertical-align: middle;
        overflow: hidden;
        position: relative;
    }
    .p-treetable-toggler + .p-checkbox {
        vertical-align: middle;
    }
    .p-treetable-toggler + .p-checkbox + span {
        vertical-align: middle;
    }
    /* Resizable */
    .p-treetable-resizable > .p-treetable-wrapper {
        overflow-x: auto;
    }
    .p-treetable-resizable .p-treetable-thead > tr > th,
    .p-treetable-resizable .p-treetable-tfoot > tr > td,
    .p-treetable-resizable .p-treetable-tbody > tr > td {
        overflow: hidden;
    }
    .p-treetable-resizable .p-resizable-column {
        background-clip: padding-box;
        position: relative;
    }
    .p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
        display: none;
    }
    .p-treetable .p-column-resizer {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        width: 0.5rem;
        height: 100%;
        padding: 0px;
        cursor: col-resize;
        border: 1px solid transparent;
    }
    .p-treetable .p-column-resizer-helper {
        width: 1px;
        position: absolute;
        z-index: 10;
        display: none;
    }
    /* Scrollable */
    .p-treetable-scrollable-wrapper {
        position: relative;
    }
    .p-treetable-scrollable-header,
    .p-treetable-scrollable-footer {
        overflow: hidden;
        border: 0 none;
    }
    .p-treetable-scrollable-body {
        overflow: auto;
        position: relative;
    }
    .p-treetable-virtual-table {
        position: absolute;
    }
    /* Frozen Columns */
    .p-treetable-frozen-view .p-treetable-scrollable-body {
        overflow: hidden;
    }
    .p-treetable-unfrozen-view {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    /* Reorder */
    .p-treetable-reorder-indicator-up,
    .p-treetable-reorder-indicator-down {
        position: absolute;
        display: none;
    }
    /* Loader */
    .p-treetable .p-treetable-loading-overlay {
        position: absolute;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        z-index: 2;
    }
    /* Alignment */
    .p-treetable .p-treetable-thead > tr > th.p-align-left > .p-column-header-content,
    .p-treetable .p-treetable-tbody > tr > td.p-align-left,
    .p-treetable .p-treetable-tfoot > tr > td.p-align-left {
        text-align: left;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    .p-treetable .p-treetable-thead > tr > th.p-align-right > .p-column-header-content,
    .p-treetable .p-treetable-tbody > tr > td.p-align-right,
    .p-treetable .p-treetable-tfoot > tr > td.p-align-right {
        text-align: right;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
    .p-treetable .p-treetable-thead > tr > th.p-align-center > .p-column-header-content,
    .p-treetable .p-treetable-tbody > tr > td.p-align-center,
    .p-treetable .p-treetable-tfoot > tr > td.p-align-center {
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .p-treetable .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-treetable .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .p-treetable .p-treetable-header {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 1px 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
    }
    .p-treetable .p-treetable-footer {
        background: #f9fafb;
        color: #374151;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        font-weight: 700;
    }
    .p-treetable .p-treetable-thead > tr > th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        font-weight: 700;
        color: #374151;
        background: #f9fafb;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-treetable .p-treetable-tfoot > tr > td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        font-weight: 700;
        color: #374151;
        background: #f9fafb;
    }
    .p-treetable .p-sortable-column {
        outline-color: #bfdbfe;
    }
    .p-treetable .p-sortable-column .p-sortable-column-icon {
        color: #374151;
        margin-left: 0.5rem;
    }
    .p-treetable .p-sortable-column .p-sortable-column-badge {
        border-radius: 50%;
        height: 1.143rem;
        min-width: 1.143rem;
        line-height: 1.143rem;
        color: #259da9;
        background: #eff6ff;
        margin-left: 0.5rem;
    }
    .p-treetable .p-sortable-column:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #374151;
    }
    .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
        color: #374151;
    }
    .p-treetable .p-sortable-column.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: #259da9;
    }
    .p-treetable .p-treetable-tbody > tr {
        background: #ffffff;
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-treetable .p-treetable-tbody > tr > td {
        text-align: left;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin-right: 0.5rem;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
        margin-right: 0.5rem;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
        color: #404040;
    }
    .p-treetable .p-treetable-tbody > tr:focus-visible {
        outline: 0.15rem solid #bfdbfe;
        outline-offset: -0.15rem;
    }
    .p-treetable .p-treetable-tbody > tr.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
        color: #259da9;
    }
    .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
        color: #259da9;
    }
    .p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
        color: #404040;
    }
    .p-treetable .p-column-resizer-helper {
        background: #26aebc;
    }
    .p-treetable .p-treetable-scrollable-header,
    .p-treetable .p-treetable-scrollable-footer {
        background: #f9fafb;
    }
    .p-treetable .p-treetable-loading-icon {
        font-size: 2rem;
    }
    .p-treetable .p-treetable-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-header {
        border-width: 1px 1px 0 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-footer {
        border-width: 0 1px 1px 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-top {
        border-width: 0 1px 0 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-bottom {
        border-width: 0 1px 1px 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
        border-width: 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
        border-width: 1px;
    }
    .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
        border-width: 1px;
    }
    .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd {
        background: #f8f8fa;
    }
    .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight {
        background: #eff6ff;
        color: #259da9;
    }
    .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
        color: #259da9;
    }
    .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
        color: #259da9;
    }
    .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd + .p-row-expanded {
        background: #f8f8fa;
    }
    .p-treetable.p-treetable-sm .p-treetable-header {
        padding: 0.875rem 0.875rem;
    }
    .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
        padding: 0.5rem 0.5rem;
    }
    .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
        padding: 0.5rem 0.5rem;
    }
    .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
        padding: 0.5rem 0.5rem;
    }
    .p-treetable.p-treetable-sm .p-treetable-footer {
        padding: 0.5rem 0.5rem;
    }
    .p-treetable.p-treetable-lg .p-treetable-header {
        padding: 1.25rem 1.25rem;
    }
    .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
        padding: 1.25rem 1.25rem;
    }
    .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
        padding: 1.25rem 1.25rem;
    }
    .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
        padding: 1.25rem 1.25rem;
    }
    .p-treetable.p-treetable-lg .p-treetable-footer {
        padding: 1.25rem 1.25rem;
    }
    .p-timeline .p-timeline-event-marker {
        border: 2px solid #26aebc;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        background-color: #ffffff;
    }
    .p-timeline .p-timeline-event-connector {
        background-color: #e5e7eb;
    }
    .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
    .p-timeline.p-timeline-vertical .p-timeline-event-content {
        padding: 0 1rem;
    }
    .p-timeline.p-timeline-vertical .p-timeline-event-connector {
        width: 2px;
    }
    .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
    .p-timeline.p-timeline-horizontal .p-timeline-event-content {
        padding: 1rem 0;
    }
    .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
        height: 2px;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 1.25rem;
        border: 1px solid #e5e7eb;
        color: #6b7280;
        background: #f9fafb;
        font-weight: 700;
        border-radius: 4px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        margin-right: 0.5rem;
    }
    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        background: #e9f7f8;
        border-color: #e5e7eb;
        color: #374151;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: #f9fafb;
        border-color: #e5e7eb;
        color: #374151;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        border-color: #e5e7eb;
        background: #e9f7f8;
        color: #374151;
    }
    .p-accordion .p-accordion-content {
        padding: 1.25rem;
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-accordion .p-accordion-tab {
        margin-bottom: 4px;
    }
    .p-card {
        background: #ffffff;
        color: #404040;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }
    .p-card .p-card-body {
        padding: 0 1.25rem;
    }
    .p-card .p-card-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .p-card .p-card-subtitle {
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: #6b7280;
    }
    .p-card .p-card-content {
        padding: 1.25rem 0;
    }
    .p-card .p-card-footer {
        padding: 1.25rem 0 0 0;
    }
    .p-fieldset {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        border-radius: 4px;
    }
    .p-fieldset .p-fieldset-legend {
        padding: 1.25rem;
        border: 1px solid #e5e7eb;
        color: #374151;
        background: #f9fafb;
        font-weight: 700;
        border-radius: 4px;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
        padding: 0;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        padding: 1.25rem;
        color: #374151;
        border-radius: 4px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
        margin-right: 0.5rem;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
        background: #e9f7f8;
        border-color: #e5e7eb;
        color: #374151;
    }
    .p-fieldset .p-fieldset-content {
        padding: 1.25rem;
    }
    .p-divider .p-divider-content {
        background-color: #ffffff;
    }
    .p-divider.p-divider-horizontal {
        margin: 1.25rem 0;
        padding: 0 1.25rem;
    }
    .p-divider.p-divider-horizontal:before {
        border-top: 1px #e5e7eb;
    }
    .p-divider.p-divider-horizontal .p-divider-content {
        padding: 0 0.5rem;
    }
    .p-divider.p-divider-vertical {
        margin: 0 1.25rem;
        padding: 1.25rem 0;
    }
    .p-divider.p-divider-vertical:before {
        border-left: 1px #e5e7eb;
    }
    .p-divider.p-divider-vertical .p-divider-content {
        padding: 0.5rem 0;
    }
    .p-panel .p-panel-header {
        border: 1px solid #e5e7eb;
        padding: 1.25rem;
        background: #f9fafb;
        color: #374151;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-panel .p-panel-header .p-panel-title {
        font-weight: 700;
    }
    .p-panel .p-panel-header .p-panel-header-icon {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-panel.p-panel-toggleable .p-panel-header {
        padding: 0.75rem 1.25rem;
    }
    .p-panel .p-panel-content {
        padding: 1.25rem;
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 0 none;
    }
    .p-panel .p-panel-footer {
        padding: 0.75rem 1.25rem;
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        border-top: 0 none;
    }
    .p-splitter {
        border: 1px solid #e5e7eb;
        background: #ffffff;
        border-radius: 4px;
        color: #404040;
    }
    .p-splitter .p-splitter-gutter {
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        background: #f9fafb;
    }
    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
        background: #e5e7eb;
    }
    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-splitter .p-splitter-gutter-resizing {
        background: #e5e7eb;
    }
    .p-stepper .p-stepper-nav {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .p-stepper .p-stepper-header {
        padding: 0.5rem;
    }
    .p-stepper .p-stepper-header .p-stepper-action {
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        background: #ffffff;
        outline-color: transparent;
    }
    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
        color: #404040;
        border: 1px solid #e9f7f8;
        border-width: 2px;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
        margin-left: 0.5rem;
        color: #6b7280;
        font-weight: 700;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
        background: #eff6ff;
        color: #259da9;
    }
    .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
        color: #404040;
    }
    .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
        background-color: #26aebc;
    }
    .p-stepper .p-stepper-panels {
        background: #ffffff;
        padding: 1.25rem;
        color: #404040;
    }
    .p-stepper .p-stepper-separator {
        background-color: #e5e7eb;
        width: 100%;
        height: 2px;
        -webkit-margin-start: 1rem;
                margin-inline-start: 1rem;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-stepper.p-stepper-vertical {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
        background: #ffffff;
        color: #404040;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex: initial;
                flex: initial;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
        -webkit-flex: initial;
                flex: initial;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
        width: 100%;
        padding-left: 1rem;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        width: 2px;
        height: auto;
        -webkit-margin-start: calc(1.75rem + 2px);
                margin-inline-start: calc(1.75rem + 2px);
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
        background-color: #26aebc;
    }
    .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
        padding-left: 3rem;
    }
    .p-scrollpanel .p-scrollpanel-bar {
        background: #f9fafb;
        border: 0 none;
    }
    .p-scrollpanel .p-scrollpanel-bar:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-tabview-nav-container {
        position: relative;
    }
    .p-tabview-scrollable .p-tabview-nav-container {
        overflow: hidden;
    }
    .p-tabview-nav-content {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-scroll-chaining: contain auto;
            overscroll-behavior: contain auto;
        position: relative;
    }
    .p-tabview-nav {
        display: -webkit-flex;
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
    }
    .p-tabview-nav-link {
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
        text-decoration: none;
        overflow: hidden;
    }
    .p-tabview-ink-bar {
        display: none;
        z-index: 1;
    }
    .p-tabview-nav-link:focus {
        z-index: 1;
    }
    .p-tabview-close {
        z-index: 1;
    }
    .p-tabview-title {
        line-height: 1;
        white-space: nowrap;
    }
    .p-tabview-nav-btn {
        position: absolute;
        top: 0;
        z-index: 2;
        height: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .p-tabview-nav-prev {
        left: 0;
    }
    .p-tabview-nav-next {
        right: 0;
    }
    .p-tabview-nav-content::-webkit-scrollbar {
        display: none;
    }
    .p-tabview .p-tabview-nav {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-width: 0 0 2px 0;
    }
    .p-tabview .p-tabview-nav li {
        margin-right: 0;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border: solid #e5e7eb;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #e5e7eb transparent;
        background: #ffffff;
        color: #6b7280;
        padding: 1.25rem;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin: 0 0 -2px 0;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.2rem #bfdbfe;
                box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: #ffffff;
        border-color: #9ca3af;
        color: #6b7280;
    }
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: #26aebc;
        color: #26aebc;
    }
    .p-tabview .p-tabview-close {
        margin-left: 0.5rem;
    }
    .p-tabview .p-tabview-nav-btn.p-link {
        background: #ffffff;
        color: #26aebc;
        width: 3rem;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0;
    }
    .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.2rem #bfdbfe;
                box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
    .p-tabview .p-tabview-panels {
        background: #ffffff;
        padding: 1.25rem;
        border: 0 none;
        color: #404040;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-toolbar {
        background: #f9fafb;
        border: 1px solid #e5e7eb;
        padding: 1.25rem;
        border-radius: 4px;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .p-toolbar .p-toolbar-separator {
        margin: 0 0.5rem;
    }
    .p-confirm-popup {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    .p-confirm-popup .p-confirm-popup-content {
        padding: 1.25rem;
    }
    .p-confirm-popup .p-confirm-popup-footer {
        text-align: right;
        padding: 0 1.25rem 1.25rem 1.25rem;
    }
    .p-confirm-popup .p-confirm-popup-footer button {
        margin: 0 0.5rem 0 0;
        width: auto;
    }
    .p-confirm-popup .p-confirm-popup-footer button:last-child {
        margin: 0;
    }
    .p-confirm-popup:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }
    .p-confirm-popup:before {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #f2f2f2;
    }
    .p-confirm-popup.p-confirm-popup-flipped:after {
        border-top-color: #ffffff;
    }
    .p-confirm-popup.p-confirm-popup-flipped:before {
        border-top-color: #ffffff;
    }
    .p-confirm-popup .p-confirm-popup-icon {
        font-size: 1.5rem;
    }
    .p-confirm-popup .p-confirm-popup-icon.p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-confirm-popup .p-confirm-popup-message {
        margin-left: 1rem;
    }
    .p-dialog {
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border: 0 none;
    }
    .p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #ffffff;
        color: #374151;
        padding: 1.5rem;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-weight: 700;
        font-size: 1.25rem;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin-right: 0.5rem;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
        margin-right: 0;
    }
    .p-dialog .p-dialog-content {
        background: #ffffff;
        color: #404040;
        padding: 0 1.5rem 2rem 1.5rem;
    }
    .p-dialog .p-dialog-content:last-of-type {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-dialog .p-dialog-footer {
        border-top: 0 none;
        background: #ffffff;
        color: #404040;
        padding: 0 1.5rem 1.5rem 1.5rem;
        text-align: right;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-dialog .p-dialog-footer button {
        margin: 0 0.5rem 0 0;
        width: auto;
    }
    .p-dialog.p-dialog-maximized .p-dialog-header, .p-dialog.p-dialog-maximized .p-dialog-content:last-of-type {
        border-radius: 0;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
        font-size: 2rem;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 1rem;
    }
    .p-overlaypanel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    .p-overlaypanel .p-overlaypanel-content {
        padding: 1.25rem;
    }
    .p-overlaypanel .p-overlaypanel-close {
        background: #26aebc;
        color: #ffffff;
        width: 2rem;
        height: 2rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
        position: absolute;
        top: -1rem;
        right: -1rem;
    }
    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
        background: #259da9;
        color: #ffffff;
    }
    .p-overlaypanel:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }
    .p-overlaypanel:before {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #f2f2f2;
    }
    .p-overlaypanel.p-overlaypanel-flipped:after {
        border-top-color: #ffffff;
    }
    .p-overlaypanel.p-overlaypanel-flipped:before {
        border-top-color: #ffffff;
    }
    .p-sidebar {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    .p-sidebar .p-sidebar-header {
        padding: 1.25rem;
    }
    .p-sidebar .p-sidebar-header .p-sidebar-close,
    .p-sidebar .p-sidebar-header .p-sidebar-icon {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
        color: #374151;
        border-color: transparent;
        background: #e9f7f8;
    }
    .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-sidebar .p-sidebar-header + .p-sidebar-content {
        padding-top: 0;
    }
    .p-sidebar .p-sidebar-content {
        padding: 1.25rem;
    }
    .p-tooltip .p-tooltip-text {
        background: #404040;
        color: #ffffff;
        padding: 0.75rem 0.75rem;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .p-tooltip.p-tooltip-right .p-tooltip-arrow {
        border-right-color: #404040;
    }
    .p-tooltip.p-tooltip-left .p-tooltip-arrow {
        border-left-color: #404040;
    }
    .p-tooltip.p-tooltip-top .p-tooltip-arrow {
        border-top-color: #404040;
    }
    .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
        border-bottom-color: #404040;
    }
    .p-fileupload .p-fileupload-buttonbar {
        background: #f9fafb;
        padding: 1.25rem;
        border: 1px solid #e5e7eb;
        color: #374151;
        border-bottom: 0 none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .p-fileupload .p-fileupload-buttonbar .p-button {
        margin-right: 0.5rem;
    }
    .p-fileupload .p-fileupload-content {
        background: #ffffff;
        padding: 2rem 1rem;
        border: 1px solid #e5e7eb;
        color: #404040;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-fileupload .p-progressbar {
        height: 0.25rem;
    }
    .p-fileupload .p-fileupload-row > div {
        padding: 1rem 1rem;
    }
    .p-fileupload.p-fileupload-advanced .p-message {
        margin-top: 0;
    }
    .p-breadcrumb {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        padding: 1rem;
    }
    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
    }
    .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
        margin: 0 0.5rem 0 0.5rem;
        color: #404040;
    }
    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
        color: #404040;
    }
    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
        color: #6b7280;
    }
    .p-contextmenu {
        padding: 0.5rem 0;
        background: #ffffff;
        color: #404040;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 12.5rem;
    }
    .p-contextmenu .p-contextmenu-root-list {
        outline: 0 none;
    }
    .p-contextmenu .p-submenu-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .p-contextmenu .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-contextmenu .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-contextmenu .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-contextmenu .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }
    .p-dock .p-dock-list-container {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
    }
    .p-dock .p-dock-list-container .p-dock-list {
        outline: 0 none;
    }
    .p-dock .p-dock-item {
        padding: 0.5rem;
        border-radius: 4px;
    }
    .p-dock .p-dock-item.p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.15rem #bfdbfe;
                box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
    .p-dock .p-dock-action {
        width: 4rem;
        height: 4rem;
    }
    .p-dock.p-dock-top .p-dock-item-second-prev,
    .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
    .p-dock.p-dock-bottom .p-dock-item-second-next {
        margin: 0 0.9rem;
    }
    .p-dock.p-dock-top .p-dock-item-prev,
    .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
    .p-dock.p-dock-bottom .p-dock-item-next {
        margin: 0 1.3rem;
    }
    .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
        margin: 0 1.5rem;
    }
    .p-dock.p-dock-left .p-dock-item-second-prev,
    .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
    .p-dock.p-dock-right .p-dock-item-second-next {
        margin: 0.9rem 0;
    }
    .p-dock.p-dock-left .p-dock-item-prev,
    .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
    .p-dock.p-dock-right .p-dock-item-next {
        margin: 1.3rem 0;
    }
    .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
        margin: 1.5rem 0;
    }
    .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container {
        overflow-x: auto;
        width: 100%;
    }
    .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container .p-dock-list {
        margin: 0 auto;
    }
    .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container {
        overflow-y: auto;
        height: 100%;
    }
    .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container .p-dock-list {
        margin: auto 0;
    }
    .p-dock.p-dock-mobile .p-dock-list .p-dock-item {
        -webkit-transform: none;
                transform: none;
        margin: 0;
    }
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-second-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-second-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-second-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-second-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-prev,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-next,
    .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-current {
        -webkit-transform: none;
                transform: none;
        margin: 0;
    }
    .p-megamenu {
        padding: 0.5rem;
        background: #f9fafb;
        color: #404040;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
    }
    .p-megamenu .p-megamenu-root-list {
        outline: 0 none;
    }
    .p-megamenu .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-megamenu .p-megamenu-panel {
        background: #ffffff;
        color: #404040;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-megamenu .p-submenu-header {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .p-megamenu .p-submenu-list {
        padding: 0.5rem 0;
        width: 12.5rem;
    }
    .p-megamenu .p-submenu-list .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-megamenu.p-megamenu-vertical {
        width: 12.5rem;
        padding: 0.5rem 0;
    }
    .p-megamenu .p-megamenu-button {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-megamenu .p-megamenu-button:hover {
        color: #6b7280;
        background: #e9f7f8;
    }
    .p-megamenu .p-megamenu-button:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
        margin-left: 0.5rem;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem {
        width: 100%;
        position: static;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
        margin-left: auto;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
        width: 100%;
        position: static;
        -webkit-box-shadow: none;
                box-shadow: none;
        border: 0 none;
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
        width: 100%;
        position: static;
    }
    .p-menu {
        padding: 0.5rem 0;
        background: #ffffff;
        color: #404040;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        width: 12.5rem;
    }
    .p-menu .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-menu.p-menu-overlay {
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-menu .p-submenu-header {
        margin: 0;
        padding: 0.75rem 1.25rem;
        color: #374151;
        background: #ffffff;
        font-weight: 700;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    .p-menu .p-menu-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-menubar {
        padding: 0.5rem;
        background: #f9fafb;
        color: #404040;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
    }
    .p-menubar .p-menubar-root-list {
        outline: 0 none;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
        margin-left: 0.5rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-menubar .p-menuitem a {
        color: #404040 !important;
    }
    .p-menubar .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
        color: #404040;
        background: transparent;
    }
    .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: transparent;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-menubar .p-submenu-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 12.5rem;
    }
    .p-menubar .p-submenu-list .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-menubar .p-submenu-list .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-button {
        width: 2rem;
        height: 2rem;
        color: #6b7280;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-menubar.p-menubar-mobile .p-menubar-button:hover {
        color: #6b7280;
        background: #e9f7f8;
    }
    .p-menubar.p-menubar-mobile .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        margin-left: auto;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }
    .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }
    @media screen and (max-width: 960px) {
        .p-menubar {
            position: relative;
        }
        .p-menubar .p-menubar-button {
            display: -webkit-flex;
            display: flex;
            width: 2rem;
            height: 2rem;
            color: #6b7280;
            border-radius: 50%;
            -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
            transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        }
        .p-menubar .p-menubar-button:hover {
            color: #6b7280;
            background: #e9f7f8;
        }
        .p-menubar .p-menubar-button:focus {
            outline: 0 none;
            outline-offset: 0;
            -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                    box-shadow: 0 0 0 0.2rem #bfdbfe;
        }
        .p-menubar .p-menubar-root-list {
            position: absolute;
            display: none;
            padding: 0.5rem 0;
            background: #ffffff;
            border: 0 none;
            -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            width: 100%;
        }
        .p-menubar .p-menubar-root-list .p-menu-separator {
            border-top: 1px solid #e5e7eb;
            margin: 0.25rem 0;
        }
        .p-menubar .p-menubar-root-list .p-submenu-icon {
            font-size: 0.875rem;
        }
        .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
            width: 0.875rem;
            height: 0.875rem;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem {
            width: 100%;
            position: static;
        }
        .p-menubar .p-menubar-root-list .p-menuitem-link {
            padding: .75rem 1.25rem;
            color: #404040 !important;
            border-radius: 4px;
            transition: box-shadow .2s,-webkit-box-shadow .2s;
            -webkit-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content {
            color: #404040;
            -webkit-transition: -webkit-box-shadow 0.2s;
            transition: -webkit-box-shadow 0.2s;
            transition: box-shadow 0.2s;
            transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
            border-radius: 0;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
            color: #404040;
            padding: 0.75rem 1.25rem;
            -webkit-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
            color: #404040;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
            color: #6b7280;
            margin-right: 0.5rem;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
            color: #6b7280;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content {
            color: #259da9;
            background: #eff6ff;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
            color: #259da9;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
            color: #259da9;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
            background: #7dced7;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
            color: #404040;
            background: #e5e7eb;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
            color: #404040;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
            color: #404040;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
            color: #404040;
            background: #e9f7f8;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
            color: #404040;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
            color: #6b7280;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
            margin-left: auto;
            -webkit-transition: -webkit-transform 0.2s;
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
            transition: transform 0.2s, -webkit-transform 0.2s;
        }
        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
            -webkit-transform: rotate(-180deg);
                    transform: rotate(-180deg);
        }
        .p-menubar .p-menubar-root-list .p-submenu-list {
            width: 100%;
            position: static;
            -webkit-box-shadow: none;
                    box-shadow: none;
            border: 0 none;
        }
        .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
            -webkit-transition: -webkit-transform 0.2s;
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
            transition: transform 0.2s, -webkit-transform 0.2s;
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
        }
        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
        }
        .p-menubar .p-menubar-root-list .p-menuitem {
            width: 100%;
            position: static;
        }
        .p-menubar .p-menubar-root-list ul li a {
            padding-left: 2.25rem;
        }
        .p-menubar .p-menubar-root-list ul li ul li a {
            padding-left: 3.75rem;
        }
        .p-menubar .p-menubar-root-list ul li ul li ul li a {
            padding-left: 5.25rem;
        }
        .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
            padding-left: 6.75rem;
        }
        .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
            padding-left: 8.25rem;
        }
        .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            top: 100%;
            left: 0;
            z-index: 1;
        }
    }
    .p-panelmenu .p-panelmenu-header {
        outline: 0 none;
    }
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
        border: 1px solid #e5e7eb;
        color: #6b7280;
        background: #f9fafb;
        border-radius: 4px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link {
        color: #6b7280;
        padding: 1.25rem;
        font-weight: 700;
    }
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link .p-submenu-icon {
        margin-right: 0.5rem;
    }
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link .p-menuitem-icon {
        margin-right: 0.5rem;
    }
    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.2rem #bfdbfe;
                box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
    .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
        background: #e9f7f8;
        border-color: #e5e7eb;
        color: #374151;
    }
    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
        background: #f9fafb;
        border-color: #e5e7eb;
        color: #374151;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
    }
    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
        border-color: #e5e7eb;
        background: #e9f7f8;
        color: #374151;
    }
    .p-panelmenu .p-panelmenu-content {
        padding: 0.5rem 0;
        border: 1px solid #e5e7eb;
        background: #ffffff;
        color: #404040;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
        outline: 0 none;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        margin-right: 0.5rem;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
        padding: 0 0 0 1rem;
    }
    .p-panelmenu .p-panelmenu-panel {
        margin-bottom: 4px;
    }
    .p-slidemenu {
        padding: 0.5rem 0;
        background: #ffffff;
        color: #404040;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        width: 12.5rem;
    }
    .p-slidemenu .p-menuitem-link > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-slidemenu .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-slidemenu.p-slidemenu-overlay {
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-slidemenu .p-slidemenu-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
        background: #eff6ff;
    }
    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
        color: #259da9;
    }
    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
        color: #259da9;
    }
    .p-slidemenu .p-slidemenu-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-slidemenu .p-slidemenu-icon {
        font-size: 0.875rem;
    }
    .p-slidemenu .p-slidemenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }
    .p-slidemenu .p-slidemenu-backward {
        padding: 0.75rem 1.25rem;
        color: #404040;
    }
    .p-steps .p-steps-item .p-menuitem-link {
        background: transparent;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        background: #ffffff;
    }
    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: #404040;
        border: 1px solid #e9f7f8;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
    }
    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        margin-top: 0.5rem;
        color: #6b7280;
    }
    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: #eff6ff;
        color: #259da9;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-title {
        font-weight: 700;
        color: #404040;
    }
    .p-steps .p-steps-item:before {
        content: " ";
        border-top: 1px solid #e5e7eb;
        width: 100%;
        top: 50%;
        left: 0;
        display: block;
        position: absolute;
        margin-top: -1rem;
    }
    .p-tabmenu .p-tabmenu-nav {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-width: 0 0 2px 0;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
        margin-right: 0;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        border: solid #e5e7eb;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #e5e7eb transparent;
        background: #ffffff;
        color: #6b7280;
        padding: 1.25rem;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin: 0 0 -2px 0;
        height: calc(100% + 2px);
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
        margin-right: 0.5rem;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.2rem #bfdbfe;
                box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: #ffffff;
        border-color: #9ca3af;
        color: #6b7280;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        background: #ffffff;
        border-color: #26aebc;
        color: #26aebc;
    }
    .p-tieredmenu {
        padding: 0.5rem 0;
        background: #ffffff;
        color: #404040;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        width: 12.5rem;
    }
    .p-tieredmenu.p-tieredmenu-overlay {
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-tieredmenu .p-tieredmenu-root-list {
        outline: 0 none;
    }
    .p-tieredmenu .p-submenu-list {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .p-tieredmenu .p-menuitem > .p-menuitem-content {
        color: #404040;
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 0;
    }
    .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
        color: #404040;
        padding: 0.75rem 1.25rem;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }
    .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
        color: #259da9;
        background: #eff6ff;
    }
    .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #259da9;
    }
    .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #259da9;
    }
    .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
        background: #7dced7;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
        color: #404040;
        background: #e5e7eb;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #404040;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
        color: #404040;
        background: #e9f7f8;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #404040;
    }
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }
    .p-tieredmenu .p-menuitem-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }
    .p-tieredmenu .p-submenu-icon {
        font-size: 0.875rem;
    }
    .p-tieredmenu .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }
    .p-inline-message {
        padding: 0.75rem 0.75rem;
        margin: 0;
        border-radius: 4px;
    }
    .p-inline-message.p-inline-message-info {
        background: rgba(219, 234, 254, 0.7);
        border: solid #26aebc;
        border-width: 0px;
        color: #26aebc;
    }
    .p-inline-message.p-inline-message-info .p-inline-message-icon {
        color: #26aebc;
    }
    .p-inline-message.p-inline-message-success {
        background: rgba(228, 248, 240, 0.7);
        border: solid #1ea97c;
        border-width: 0px;
        color: #1ea97c;
    }
    .p-inline-message.p-inline-message-success .p-inline-message-icon {
        color: #1ea97c;
    }
    .p-inline-message.p-inline-message-warn {
        background: rgba(255, 242, 226, 0.7);
        border: solid #cc8925;
        border-width: 0px;
        color: #cc8925;
    }
    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
        color: #cc8925;
    }
    .p-inline-message.p-inline-message-error {
        background: rgba(255, 231, 230, 0.7);
        border: solid #ff5757;
        border-width: 0px;
        color: #ff5757;
    }
    .p-inline-message.p-inline-message-error .p-inline-message-icon {
        color: #ff5757;
    }
    .p-inline-message .p-inline-message-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }
    .p-inline-message .p-inline-message-icon.p-icon {
        width: 1rem;
        height: 1rem;
    }
    .p-inline-message .p-inline-message-text {
        font-size: 1rem;
    }
    .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
        margin-right: 0;
    }
    .p-message {
        margin: 1rem 0;
        border-radius: 4px;
    }
    .p-message .p-message-wrapper {
        padding: 1.25rem 1.75rem;
    }
    .p-message .p-message-close {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: transparent;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-message .p-message-close:hover {
        background: rgba(255, 255, 255, 0.5);
    }
    .p-message .p-message-close:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-message.p-message-info {
        background: rgba(219, 234, 254, 0.7);
        border: solid #26aebc;
        border-width: 0 0 0 6px;
        color: #26aebc;
    }
    .p-message.p-message-info .p-message-icon {
        color: #26aebc;
    }
    .p-message.p-message-info .p-message-close {
        color: #26aebc;
    }
    .p-message.p-message-success {
        background: rgba(228, 248, 240, 0.7);
        border: solid #1ea97c;
        border-width: 0 0 0 6px;
        color: #1ea97c;
    }
    .p-message.p-message-success .p-message-icon {
        color: #1ea97c;
    }
    .p-message.p-message-success .p-message-close {
        color: #1ea97c;
    }
    .p-message.p-message-warn {
        background: rgba(255, 242, 226, 0.7);
        border: solid #cc8925;
        border-width: 0 0 0 6px;
        color: #cc8925;
    }
    .p-message.p-message-warn .p-message-icon {
        color: #cc8925;
    }
    .p-message.p-message-warn .p-message-close {
        color: #cc8925;
    }
    .p-message.p-message-error {
        background: rgba(255, 231, 230, 0.7);
        border: solid #ff5757;
        border-width: 0 0 0 6px;
        color: #ff5757;
    }
    .p-message.p-message-error .p-message-icon {
        color: #ff5757;
    }
    .p-message.p-message-error .p-message-close {
        color: #ff5757;
    }
    .p-message .p-message-text {
        font-size: 1rem;
        font-weight: 500;
    }
    .p-message .p-message-icon {
        font-size: 1.5rem;
        margin-right: 0.5rem;
    }
    .p-message .p-message-icon.p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-message .p-message-summary {
        font-weight: 700;
    }
    .p-message .p-message-detail {
        margin-left: 0.5rem;
    }
    .p-toast {
        opacity: 1;
    }
    .p-toast .p-toast-message {
        margin: 0 0 1rem 0;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .p-toast .p-toast-message .p-toast-message-content {
        padding: 1rem;
        border-width: 0 0 0 6px;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
        margin: 0 0 0 1rem;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
        font-size: 2rem;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
        font-weight: 700;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        margin: 0.5rem 0 0 0;
    }
    .p-toast .p-toast-message .p-toast-icon-close {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: transparent;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-toast .p-toast-message .p-toast-icon-close:hover {
        background: rgba(255, 255, 255, 0.5);
    }
    .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-toast .p-toast-message.p-toast-message-info {
        background: rgba(219, 234, 254, 0.7);
        border: solid #26aebc;
        border-width: 0 0 0 6px;
        color: #404040;
    }
    .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: #26aebc;
    }
    .p-toast .p-toast-message.p-toast-message-success {
        background: rgba(228, 248, 240, 0.7);
        border: solid #1ea97c;
        border-width: 0 0 0 6px;
        color: #1ea97c;
    }
    .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: #1ea97c;
    }
    .p-toast .p-toast-message.p-toast-message-warn {
        background: rgba(255, 242, 226, 0.7);
        border: solid #cc8925;
        border-width: 0 0 0 6px;
        color: #cc8925;
    }
    .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
        color: #cc8925;
    }
    .p-toast .p-toast-message.p-toast-message-error {
        background: rgba(255, 231, 230, 0.7);
        border: solid #ff5757;
        border-width: 0 0 0 6px;
        color: #ff5757;
    }
    .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
        color: #ff5757;
    }
    .p-galleria .p-galleria-close {
        margin: 0.5rem;
        background: transparent;
        color: #f9fafb;
        width: 4rem;
        height: 4rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
    }
    .p-galleria .p-galleria-close .p-galleria-close-icon {
        font-size: 2rem;
    }
    .p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-galleria .p-galleria-close:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f9fafb;
    }
    .p-galleria .p-galleria-item-nav {
        background: transparent;
        color: #f9fafb;
        width: 4rem;
        height: 4rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 4px;
        margin: 0 0.5rem;
    }
    .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
    .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
        font-size: 2rem;
    }
    .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon,
    .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }
    .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f9fafb;
    }
    .p-galleria .p-galleria-caption {
        background: rgba(0, 0, 0, 0.5);
        color: #f9fafb;
        padding: 1rem;
    }
    .p-galleria .p-galleria-indicators {
        padding: 1rem;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator button {
        background-color: #d1d5db;
        width: 1rem;
        height: 1rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
        background: #9ca3af;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #eff6ff;
        color: #259da9;
    }
    .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
        margin-right: 0.5rem;
    }
    .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
        margin-bottom: 0.5rem;
    }
    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
        background: rgba(0, 0, 0, 0.5);
    }
    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
        background: rgba(255, 255, 255, 0.4);
    }
    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
        background: rgba(255, 255, 255, 0.6);
    }
    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #eff6ff;
        color: #259da9;
    }
    .p-galleria .p-galleria-thumbnail-container {
        background: rgba(0, 0, 0, 0.9);
        padding: 1rem 0.25rem;
    }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
        margin: 0.5rem;
        background-color: transparent;
        color: #f9fafb;
        width: 2rem;
        height: 2rem;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        border-radius: 50%;
    }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f9fafb;
    }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
        -webkit-transition: -webkit-box-shadow 0.2s;
        transition: -webkit-box-shadow 0.2s;
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-galleria-mask {
        --maskbg: rgba(0, 0, 0, 0.9);
    }
    .p-image-mask {
        --maskbg: rgba(0, 0, 0, 0.9);
    }
    .p-image-preview-indicator {
        background-color: transparent;
        color: #f8f9fa;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-image-preview-indicator .p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-image-preview-container:hover > .p-image-preview-indicator {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .p-image-toolbar {
        padding: 1rem;
    }
    .p-image-action.p-link {
        color: #f8f9fa;
        background-color: transparent;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin-right: 0.5rem;
    }
    .p-image-action.p-link:last-child {
        margin-right: 0;
    }
    .p-image-action.p-link:hover {
        color: #f8f9fa;
        background-color: rgba(255, 255, 255, 0.1);
    }
    .p-image-action.p-link span {
        font-size: 1.5rem;
    }
    .p-image-action.p-link .p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-avatar {
        background-color: #e5e7eb;
        border-radius: 4px;
    }
    .p-avatar.p-avatar-lg {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
    }
    .p-avatar.p-avatar-lg .p-avatar-icon {
        font-size: 1.5rem;
    }
    .p-avatar.p-avatar-xl {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
    }
    .p-avatar.p-avatar-xl .p-avatar-icon {
        font-size: 2rem;
    }
    .p-avatar-circle {
        border-radius: 50%;
    }
    .p-avatar-group .p-avatar {
        border: 2px solid #ffffff;
    }
    .p-chip {
        background-color: #e5e7eb;
        color: #404040;
        border-radius: 16px;
        padding: 0 0.75rem;
    }
    .p-chip .p-chip-text {
        line-height: 1.5;
        margin-top: 0.375rem;
        margin-bottom: 0.375rem;
    }
    .p-chip .p-chip-icon {
        margin-right: 0.5rem;
    }
    .p-chip img {
        width: 2.25rem;
        height: 2.25rem;
        margin-left: -0.75rem;
        margin-right: 0.5rem;
    }
    .p-chip .p-chip-remove-icon {
        border-radius: 4px;
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
        margin-left: 0.5rem;
    }
    .p-chip .p-chip-remove-icon:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-chip .p-chip-remove-icon:focus {
        outline: 0 none;
    }
    .p-scrolltop {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-scrolltop.p-link {
        background: rgba(0, 0, 0, 0.7);
    }
    .p-scrolltop.p-link:hover {
        background: rgba(0, 0, 0, 0.8);
    }
    .p-scrolltop .p-scrolltop-icon {
        font-size: 1.5rem;
        color: #f9fafb;
    }
    .p-scrolltop .p-scrolltop-icon.p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-skeleton {
        background-color: #e5e7eb;
        border-radius: 4px;
    }
    .p-skeleton:after {
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0)));
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }
    .p-tag {
        background: #26aebc;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        padding: 0.25rem 0.4rem;
        border-radius: 4px;
    }
    .p-tag.p-tag-success {
        background-color: #22c55e;
        color: #ffffff;
    }
    .p-tag.p-tag-info {
        background-color: #0ea5e9;
        color: #ffffff;
    }
    .p-tag.p-tag-warning {
        background-color: #f97316;
        color: #ffffff;
    }
    .p-tag.p-tag-danger {
        background-color: #ef4444;
        color: #ffffff;
    }
    .p-tag .p-tag-icon {
        margin-right: 0.25rem;
        font-size: 0.75rem;
    }
    .p-tag .p-tag-icon.p-icon {
        width: 0.75rem;
        height: 0.75rem;
    }
    .p-inplace .p-inplace-display {
        padding: 0.75rem 0.75rem;
        border-radius: 4px;
        -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-inplace .p-inplace-display:not(.p-disabled):hover {
        background: #e9f7f8;
        color: #404040;
    }
    .p-inplace .p-inplace-display:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2rem #bfdbfe;
                box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
    .p-metergroup .p-metergroup-meter-container {
        background: #e5e7eb;
        border-radius: 4px;
    }
    .p-metergroup .p-metergroup-meter {
        border: 0 none;
        background: #26aebc;
    }
    .p-metergroup .p-metergroup-label-list .p-metergroup-label-list-item {
        line-height: 1.5rem;
    }
    .p-metergroup .p-metergroup-label-list .p-metergroup-label-type {
        background: #26aebc;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        margin-right: 0.5rem;
    }
    .p-metergroup .p-metergroup-label-list .p-metergroup-label {
        margin-right: 1rem;
    }
    .p-metergroup .p-metergroup-label-list .p-metergroup-label-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter-container {
        height: 0.5rem;
    }
    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-metergroup.p-metergroup-horizontal .p-metergroup-label-list-start {
        margin-bottom: 1rem;
    }
    .p-metergroup.p-metergroup-horizontal .p-metergroup-label-list-end {
        margin-top: 1rem;
    }
    .p-metergroup.p-metergroup-vertical .p-metergroup-meter-container {
        width: 0.5rem;
        height: 100%;
    }
    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .p-metergroup.p-metergroup-vertical .p-metergroup-label-list:not(.p-metergroup-label-list-start) {
        margin-left: 1rem;
    }
    .p-progressbar {
        border: 0 none;
        height: 1.5rem;
        background: #e5e7eb;
        border-radius: 4px;
    }
    .p-progressbar .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: #26aebc;
    }
    .p-progressbar .p-progressbar-label {
        color: #ffffff;
        line-height: 1.5rem;
    }
    .p-terminal {
        background: #ffffff;
        color: #404040;
        border: 1px solid #e5e7eb;
        padding: 1.25rem;
    }
    .p-terminal .p-terminal-input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
    }
    .p-badge {
        background: #26aebc;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
    }
    .p-badge.p-badge-secondary {
        background-color: #64748b;
        color: #ffffff;
    }
    .p-badge.p-badge-success {
        background-color: #22c55e;
        color: #ffffff;
    }
    .p-badge.p-badge-info {
        background-color: #0ea5e9;
        color: #ffffff;
    }
    .p-badge.p-badge-warning {
        background-color: #f97316;
        color: #ffffff;
    }
    .p-badge.p-badge-danger {
        background-color: #ef4444;
        color: #ffffff;
    }
    .p-badge.p-badge-lg {
        font-size: 1.125rem;
        min-width: 2.25rem;
        height: 2.25rem;
        line-height: 2.25rem;
    }
    .p-badge.p-badge-xl {
        font-size: 1.5rem;
        min-width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }
    .p-tag {
        background: #26aebc;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        padding: 0.25rem 0.4rem;
        border-radius: 4px;
    }
    .p-tag.p-tag-success {
        background-color: #22c55e;
        color: #ffffff;
    }
    .p-tag.p-tag-info {
        background-color: #0ea5e9;
        color: #ffffff;
    }
    .p-tag.p-tag-warning {
        background-color: #f97316;
        color: #ffffff;
    }
    .p-tag.p-tag-danger {
        background-color: #ef4444;
        color: #ffffff;
    }
}
@layer primereact {
    .p-button-label {
        font-weight: 400;
    }
    .p-selectbutton > .p-button,
    .p-togglebutton.p-button {
        -webkit-transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
        -webkit-transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        -webkit-transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        -webkit-transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: #26aebc;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background-color: #26aebc;
    }
    .p-button:focus {
        -webkit-box-shadow: none;
                box-shadow: none;
    }
    .p-button.p-button-secondary:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-button.p-button-success:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-button.p-button-info:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #83d3f8, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #83d3f8, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-button.p-button-warning:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #fcb98b, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #fcb98b, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-button.p-button-help:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d4aafb, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d4aafb, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-button.p-button-danger:enabled:focus {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
        -webkit-box-shadow: inset 0 2px 0 0 #26aebc;
                box-shadow: inset 0 2px 0 0 #26aebc;
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
        -webkit-box-shadow: inset 0 -2px 0 0 #26aebc;
                box-shadow: inset 0 -2px 0 0 #26aebc;
    }
    .p-speeddial-item.p-focus > .p-speeddial-action {
        -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 rgb(0, 0, 0);
                box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-toast-message {
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
    }
    .p-inline-message-text {
        font-weight: 500;
    }
    .p-picklist-buttons .p-button,
    .p-orderlist-controls .p-button {
        -webkit-transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
        transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: #26aebc;
        color: #ffffff;
    }
    .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
        background: #26aebc;
        color: #ffffff;
    }
    .p-input-icon-left > .p-input-prefix, .p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type {
        left: .75rem;
        color: #656565;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2.5rem;
    }
}

